import { omitBy, isEmpty } from 'lodash'

import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'

export function useFeedQuery<T>(fundId?: string, filters = {}) {
  return useJsonApiQuery<T>([
    'impact_feeds',
    omitBy(
      { 'filter[fund_id]': fundId, ...filters, 'stats[total]': 'count' },
      isEmpty
    ),
  ])
}

export function useFeedHighlightsQuery<T>(fundId?: string, filters = {}) {
  return useJsonApiQuery<T>([
    'impact_feeds/highlights',
    omitBy({ 'filter[fund_id]': fundId }, isEmpty),
  ])
}
