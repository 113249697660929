import Layout from 'app/funds/[id]/layout'
import NotFoundPage from '../pages/not-found.page'

const NotFoundContainer = () => (
  <Layout>
    <NotFoundPage />
  </Layout>
)

export default NotFoundContainer
