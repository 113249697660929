import { TAllocationType, FundApplication } from 'features/funds/types'
import { StripePaymentIntent } from './use-pay-with-stripe'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useStripeSecretMutation } from '@features/funds/hooks/use-stripe-secret-mutation'

export function useStripePayApplication(
  application: FundApplication,
  allocationType: TAllocationType
) {
  const stripeSecretsMutation = useStripeSecretMutation()
  const [stripePaymentIntent, setStripePaymentIntent] =
    useState<null | StripePaymentIntent>(null)

  const mutate = useCallback(() => {
    // Get the payment intent secret
    stripeSecretsMutation.mutate(
      {
        fund_id: application.fund.id as string,
        location_id: application.location.id as string,
        allocation_type: allocationType,
      },
      {
        onSuccess: ({
          data: {
            attributes: { clientSecret, customerId, paymentMethod, publicKey },
          },
        }: any) => {
          setStripePaymentIntent({
            clientSecret,
            customerId,
            paymentMethod,
            publicKey,
          })
        },
        onError: () => {
          toast.error('There was an error setting up payment with stripe')
        },
      }
    )
  }, [
    application.fund.id,
    application.location.id,
    allocationType,
    stripeSecretsMutation,
  ])

  return {
    mutate,
    isLoading: stripeSecretsMutation.isLoading,
    stripePaymentIntent,
    setStripePaymentIntent,
  }
}
