import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'
import { useFund } from '../../../shared/providers'
import { DateRangeFilters, TMapFilters } from '../types'
import { utils } from '../utils'

/**
 * Queries the backend for the location maps based on the url parameter state
 */
export function useLocationMapQuery(filters: TMapFilters) {
  const fund = useFund()

  let filterObject = {
    fund_id: fund.id,
  }
  const { date_range, state, solution_id } = filters

  if (
    date_range !== undefined &&
    date_range !== null &&
    date_range !== 'since_launch'
  ) {
    const dateRangeFilters: DateRangeFilters =
      utils.getDateRangeFilters(date_range)
    filterObject['start_date'] = dateRangeFilters.start_date
    filterObject['end_date'] = dateRangeFilters.end_date
  }

  if (state !== undefined && state !== '') {
    filterObject['state'] = state
  }

  switch (solution_id) {
    case 'replication':
      filterObject['solution_id][not_eq'] = 99
      break
    case 'open_grant':
      filterObject['solution_id'] = 99
      break
  }

  return useJsonApiQuery<any>([
    'location_maps',
    {
      filter: filterObject,
    },
  ])
}
