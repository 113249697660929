import { Text } from '@changex/design-system'
import { useApplicationsListCount } from '@features/funds/hooks/use-applications-list-count'

export function ApplicationsCount() {
  const applicationsListCount = useApplicationsListCount()
  if (applicationsListCount !== null) {
    return (
      <Text variant="light">
        {applicationsListCount} application
        {applicationsListCount === 1 ? '' : 's'} found
      </Text>
    )
  }
  return null
}
