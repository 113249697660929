import { ApplicationApprovalStageProps } from './application-approval.types'
import { ApplicationApprovalRejectButton } from './components/application-approval-reject-button'
import { ApplicationApprovalAcceptButton } from './components/application-approval-accept-button'
import { useRejectApplication } from './hooks/use-reject-application'
import { useApplicationApproveMutation } from '@features/funds/hooks/use-application-approve-mutation'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import {
  APPLICATION_APPROVAL_REJECT_TOOLTIP,
  APPLICATION_APPROVAL_TOAST_DURATION,
} from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { ApplicationPaymentAccountsStatus } from './components/ApplicationPaymentAccountsStatus'
import { ConfirmationModal } from 'components/confirmation-modal'

export function ApplicationApprovalStageSucceeded({
  application,
}: ApplicationApprovalStageProps) {
  const [open, setOpen] = useState(false)
  const reject = useRejectApplication(application)
  const approveMutation = useApplicationApproveMutation()

  const clickHandler = () => {
    setOpen(!open)
  }

  const handleApprove = useCallback(() => {
    approveMutation.mutate(
      {
        application_id: application.id,
        fund_id: application.fund.id!,
      },
      {
        onSuccess: () => {
          toast.success('Application successfully approved', {
            duration: APPLICATION_APPROVAL_TOAST_DURATION,
          })
        },
        onError: () => {
          toast.error('There was an error approving this application', {
            duration: APPLICATION_APPROVAL_TOAST_DURATION,
          })
        },
      }
    )
  }, [application.fund.id, application.id, approveMutation])

  return (
    <>
      <div className="flex items-center gap-x-3">
        <ApplicationPaymentAccountsStatus
          paymentAccounts={
            application?.location?.payments?.location.paymentAccounts
          }
        />
        <ApplicationApprovalRejectButton
          tooltip={APPLICATION_APPROVAL_REJECT_TOOLTIP}
          onClick={reject.mutate}
          isLoading={reject.isLoading}
        />
      </div>
      <div className="flex items-center gap-x-3">
        <ApplicationApprovalAcceptButton
          isLoading={approveMutation.isLoading}
          disabled={reject.isLoading}
          acceptActionText={'Approve'}
          amount={
            application?.location?.payments?.location.budgets.succeeded
              .balance ?? 0
          }
          onClick={clickHandler}
        />
      </div>
      <ConfirmationModal
        open={open}
        onClick={handleApprove}
        setOpen={setOpen}
        text="Are you sure you want to approve this amount? This action can't be undone."
        okText="Yes, approve this amount"
      />
    </>
  )
}
