import { Badge } from '@changex/design-system'
import { utils } from 'features/funds/utils'
import { TAllocationType } from 'features/funds/types'

type TProps = {
  type: TAllocationType
}

const AllocationType: React.FC<TProps> = ({ type }) => {
  const allocationTypeLabel = utils.titleize(type)
  switch (type) {
    case TAllocationType.Solution:
      return (
        <Badge variant="info" className="text-base">
          SI
        </Badge>
      )
    // TODO: refunds
    // case TAllocationType.refund:
    //   return (
    //     <Badge variant="open_grant" className="text-base">
    //       {allocationTypeLabel}
    //     </Badge>
    //   )
    default:
      return <span className="text-gray-600">{allocationTypeLabel}</span>
  }
}

export default AllocationType
