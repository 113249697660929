import { FundApplicationOpenGrant } from '../../../types'
import { ApplicationInfoDefinitionList } from '@features/funds/components/applications-list/application/application-info-definition-list'
import { ApplicationInfoStatusBadge } from '@features/funds/components/applications-list/application/application-info-status-badge'
import { useCurrencyFormatter } from '../../../../../shared/currency/hooks/use-currency-formatter'
import { utils } from '@features/funds/utils'

type Props = {
  data: FundApplicationOpenGrant
}

function ApplicationInfoOpenGrant({ data }: Props) {
  const formatCurrency = useCurrencyFormatter()
  const isPreallocated = data.applicationStatus === 'pre_allocated'
  return (
    <ApplicationInfoDefinitionList
      definitions={[
        {
          term: 'Status',
          definition: (
            <ApplicationInfoStatusBadge status={data.applicationStatus} />
          ),
          testId: 'status',
        },
        {
          term: 'Project',
          definition: data?.location?.name,
          link: utils.createLinkToPage(data),
          testId: 'solution',
        },
        isPreallocated
          ? {
              term: 'Requested',
              definition: formatCurrency(data.requestedBudget),
              testId: 'requested-amount',
            }
          : {
              term: 'Allocated',
              definition: formatCurrency(data.budget),
              testId: 'allocated-amount',
            },
      ]}
    />
  )
}

export default ApplicationInfoOpenGrant
