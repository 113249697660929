import { FundApplication } from '../../../types'
import ApplicationOwner from './application-owner'
import ApplicationInfoOpenGrant from './application-info-open-grant'
import ApplicationInfoReplication from '@features/funds/components/applications-list/application/application-info-replication'
import TextOverflow from '@features/funds/components/text-overflow/index'
import ApplicationProgress from '@features/funds/components/applications-list/application/application-progress'
import { ApplicationApproval } from '@features/funds/components/applications-list/application/approval'
import { ApplicationApprovalStage } from '@features/funds/components/applications-list/application/approval/application-approval.types'
import { When } from 'react-if'
import { utils } from '@features/funds/utils'

type Props = {
  application: FundApplication
  onClick: (application: FundApplication) => void
}

function applicationStatusToApprovalStage(
  applicationStatus
): ApplicationApprovalStage {
  switch (applicationStatus) {
    case 'pre_allocated':
      // Exceptional case for pre_allocated
      return 'inbox'
    default:
      // Convert stage to camelCase if needed
      return applicationStatus
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase())
  }
}

function Application({ application, onClick }: Props) {
  const stage = applicationStatusToApprovalStage(application.applicationStatus)

  const handleApplicationClick = (event) => {
    if (!event.target.href) {
      onClick(application)
    }
  }

  const description = utils.isOpenGrantApplication(application)
    ? application.tagline
    : application.message

  return (
    <div className="mb-4 rounded-md border border-gray-200 shadow-sm">
      <div
        className="px-6 py-7 hover:cursor-pointer hover:bg-gray-50"
        onClick={handleApplicationClick}
      >
        <div className="flex gap-10">
          <ApplicationOwner data={application} />
          {application.type === 'solution_applications' ? (
            <ApplicationInfoReplication data={application} />
          ) : (
            <ApplicationInfoOpenGrant data={application} />
          )}
          <ApplicationProgress data={application} />
        </div>
        <When condition={description}>
          <div className="mt-6 ml-14 max-w-xl border-l-4 px-4 text-sm text-gray-500">
            <TextOverflow length={140}>{description}</TextOverflow>
          </div>
        </When>
      </div>
      <div className="border-b border-gray-200 bg-zinc-300/20 p-4">
        {stage && (
          <ApplicationApproval application={application} stage={stage} />
        )}
      </div>
    </div>
  )
}

export default Application
