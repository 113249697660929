import { omitBy, isEmpty } from 'lodash'

import { useJsonApiQuery } from './json-api/use-json-api-query'

export function useSettingQuery<T>(
  settingableId?: string,
  settingableType?: string,
  filters = {}
) {
  return useJsonApiQuery<T>([
    'settings',
    omitBy(
      {
        'filter[settingable_id]': settingableId,
        'filter[settingable_type]': settingableType,
        ...filters,
      },
      isEmpty
    ),
  ])
}
