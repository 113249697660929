import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useJsonApiConfig } from './json-api-provider'
import { JSON_API_QUERY_KEY_ROOT } from './json-api.constants'

type DefaultAttribs = Record<string, string>

type JsonApiMutationEntity<TAttribs> = {
  id?: string
  type?: string
} & TAttribs

export function useJsonApiMutation<TAttribs = DefaultAttribs>(
  rootType: string,
  shouldInvalidateQueriesOnSuccess = true
) {
  const { create, update, serializer } = useJsonApiConfig()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      type = rootType,
      ...data
    }: JsonApiMutationEntity<TAttribs>) => {
      const serialised = serializer.serialize(type, data)

      // TODO should deserialize here
      if (data.id) {
        return update(`${rootType}/${data.id}`, serialised)
      } else {
        return create(rootType, serialised)
      }
    },
    // For now this just invalidates all json api queries by default.
    // This can cause performance issues by triggering large re-renders &
    // kicking off multiple other queries to re-fetch. In that case, you can
    // disable it by passing `false` for `shouldInvalidateQueriesOnSuccess`.
    onSuccess: () => {
      if (shouldInvalidateQueriesOnSuccess) {
        queryClient.invalidateQueries([JSON_API_QUERY_KEY_ROOT])
      }
    },
  })
}
