import { Pagination } from '@changex/design-system'

import { Feed } from '@features/funds/types'

import FeedListItem from './feed-list-item/feed-list-item'
import { Solution, User } from '@types'
import { useState } from 'react'
import FeedSidebar from './sidebar/feed-sidebar'

type TProps = {
  currentUser: User
  data: Feed[]
  pagination: {
    currentPage: string
    meta?: {
      stats?: {
        total?: {
          count?: number
        }
      }
    }
    onFilter: (pagination: {
      [key: string]: string | boolean | number | null
    }) => void
  }
  solutions: Solution[]
}

export default function FeedList({
  currentUser,
  data,
  pagination,
  solutions,
}: TProps) {
  const [posts, setPosts] = useState<Feed[]>(data)
  const [meta, setMeta] = useState({
    stats: {
      total: {
        count: pagination?.meta?.stats?.total?.count || 0,
      },
    },
  })

  const indexedSolutions = solutions.reduce(
    (indexed, solution) => ({
      ...indexed,
      [solution.id]: {
        name: solution.name,
        slug: solution.slug,
        header: solution.headerImageUrl,
      },
    }),
    {}
  )

  const handleRemovePost = (postId: string) => {
    setPosts((oldPosts) => oldPosts.filter((post) => post.id !== postId))
    setMeta((oldMeta) => ({
      stats: {
        total: {
          count: oldMeta.stats.total.count - 1,
        },
      },
    }))
  }

  return (
    <>
      <span className="text-gray-700">
        {meta.stats.total.count} items found
      </span>
      <div>
        <div className="flex items-start gap-x-16">
          <div className="m-auto flex flex-1 flex-col">
            {posts.map((post, position) => (
              <FeedListItem
                currentUser={currentUser}
                idea={
                  indexedSolutions[post.solutionId] || {
                    name: 'n/a',
                    slug: 'n-a',
                  }
                }
                item={post}
                key={post.id}
                onRemove={handleRemovePost}
              />
            ))}
          </div>
          <div className="shrink-0 grow-0 basis-96">
            <FeedSidebar ideas={indexedSolutions} />
          </div>
        </div>
        <Pagination
          currentPage={parseInt(pagination.currentPage)}
          meta={meta}
          onFiltering={pagination.onFilter}
        />
      </div>
    </>
  )
}
