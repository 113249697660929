import { Button, Icon, LoadingIcon, Text } from '@changex/design-system'
import classNames from 'classnames'
import React, { Fragment, useCallback } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useMarkApplicationStepComplete } from '@features/funds/hooks/use-mark-application-step-as-complete'
import toast from 'react-hot-toast'
import { useFund } from './../../../../../../src/shared/providers'
import { UserFundRole } from '@features/funds/types'

type LockedProps = {
  name: string
  onMarkStepAsComplete: () => void
}

function Locked({ name, onMarkStepAsComplete }: LockedProps) {
  if (useFund().userRole !== UserFundRole.Admin) {
    return (
      <Text className="mt-1.5 capitalize" size="sm" variant="light">
        {name}
      </Text>
    )
  }

  return (
    <Popover className="relative">
      <Popover.Button className="mt-1.5 inline-flex items-center gap-x-1 text-sm leading-6">
        <Text className="capitalize" size="sm" variant="light">
          {name}
        </Text>
        <span className="rounded-full bg-gray-200">
          <ChevronDownIcon
            className="h-4 w-4 fill-gray-900"
            aria-hidden="true"
          />
        </span>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/2 px-4">
          <Popover.Button
            as={Button}
            weight="tertiary"
            className="block w-48 shadow-lg ring-1 ring-gray-900/5"
            onClick={onMarkStepAsComplete}
          >
            Mark step as completed
          </Popover.Button>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

type LockedStepIconProps = {
  isLoading: boolean
}
function LockedStepIcon({ isLoading }: LockedStepIconProps) {
  return isLoading ? (
    <LoadingIcon />
  ) : (
    <Icon
      icon="LockClosed"
      size="sm"
      variant="solid"
      className="text-gray-400"
    />
  )
}

export type TProps = {
  completed?: boolean
  name: string
  position: number
  locationId: string
  stepId: string
}

function Step({ completed, name, position, locationId, stepId }: TProps) {
  const markStepAsCompleteMutation = useMarkApplicationStepComplete()
  const handleMarkStepAsComplete = useCallback(() => {
    markStepAsCompleteMutation.mutate(
      {
        onboardingStepId: stepId,
        locationId: locationId,
      },
      {
        onSuccess: () => {
          toast.success(`You have marked step ${position} as complete`)
        },
        onError: () => {
          toast.error('There was an error updating the step')
        },
      }
    )
  }, [locationId, markStepAsCompleteMutation, position, stepId])

  return (
    <div className="flex flex-col items-center">
      <div
        className={classNames(
          'flex h-11 w-11 items-center justify-center rounded-full',
          {
            'bg-green-500': completed,
            'bg-gray-200': !completed,
          }
        )}
      >
        {completed ? (
          <small className="text-lg text-white">{position}</small>
        ) : (
          <LockedStepIcon isLoading={markStepAsCompleteMutation.isLoading} />
        )}
      </div>
      {completed ? (
        <Text className="mt-1.5 capitalize" size="sm" variant="light">
          {name}
        </Text>
      ) : (
        <Locked name={name} onMarkStepAsComplete={handleMarkStepAsComplete} />
      )}
    </div>
  )
}

export default Step
