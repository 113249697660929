import { StackRank } from '@changex/design-system'
import { TApplicationSources } from 'shared/types/application-analytics.type'

type TProps = {
  data: TApplicationSources
}

const SourcesOfApplications: React.FC<TProps> = ({ data }) => {
  return (
    <div className="flex flex-col rounded bg-white p-4">
      <h3 className="text-lg text-gray-800">Sources of applications</h3>
      <div className="grow">
        <StackRank
          labelTitle="UTM"
          valueTitle="Applications"
          pageSize={5}
          data={Object.entries(data).map(([label, value]) => ({
            label,
            value,
          }))}
        />
      </div>
    </div>
  )
}

export default SourcesOfApplications
