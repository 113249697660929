import { useCurrencyFormatter } from '../hooks/use-currency-formatter'
import { useMemo } from 'react'

type Props = {
  value: number
}

/**
 * @param value - amount to format
 * @constructor
 */
export function CurrencyFormatter({ value }: Props) {
  const formatter = useCurrencyFormatter()
  const formattedValue = useMemo(() => formatter(value), [formatter, value])
  return <>{formattedValue}</>
}
