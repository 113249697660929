import SideNavigation from '@features/funds/components/settings/side-navigation'
import Layout from 'app/funds/[id]/layout'
import {
  useMembershipsQuery,
  TMemberableType,
  TMembership,
} from '@features/funds/hooks/use-membership-query'
import { useFund } from 'shared/providers'
import ReadOnlyPageBanner from '@features/funds/components/settings/read-only-page-banner'
import TeamMembers, {
  TTeamMember,
} from '@features/funds/components/team-members/team-members'
import { Text } from '@changex/design-system'
import { UserGroupIcon } from '@heroicons/react/outline'
import { Skeleton } from '@changex/design-system'

const reduceMembershipsToUniqueTeamMembers = (
  memberableType: TMemberableType,
  initialValue: { [key: number]: TTeamMember },
  memberships: TMembership[]
): { [key: number]: TTeamMember } => {
  return memberships.reduce((accumulator, membership) => {
    const userId = membership.userId
    const memberableTypeRoles = membership.roles.map((role) => ({
      role: role,
      type: memberableType,
    }))
    if (!(userId in accumulator)) {
      accumulator[userId] = {
        avatarUrl: membership.user.avatarUrl,
        fullName: membership.user.fullName,
        email: membership.user.email,
        currentSignInAt: membership.user.currentSignInAt,
        userRoles: memberableTypeRoles,
      }
    } else {
      accumulator[userId]['userRoles'] = [
        ...accumulator[userId]['userRoles'],
        ...memberableTypeRoles,
      ]
    }
    return accumulator
  }, initialValue)
}

const LoadingSkeleton = () => {
  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex gap-4">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <hr />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  )
}

const EmptyState = () => {
  return (
    <div className="mt-10 flex flex-col items-center">
      <UserGroupIcon className="h-12 w-12 text-gray-400" />
      <Text weight="semibold" variant="dark" size="lg" className="mt-2">
        No team members
      </Text>
      <Text variant="light" className="mt-1">
        No team members have been added to this fund yet. Only the ChangeX team
        can manage team members.
      </Text>
    </div>
  )
}

const TeamMembersWrapper = () => {
  const fund = useFund()
  const { isLoading: isFundMembershipsLoading, data: fundMembershipsData } =
    useMembershipsQuery(TMemberableType.Fund, Number(fund.id))
  const { isLoading: isOrgMembershipsLoading, data: orgMembershipsData } =
    useMembershipsQuery(TMemberableType.Organisation, fund.organisationId)

  if (isFundMembershipsLoading || isOrgMembershipsLoading) {
    return <LoadingSkeleton />
  }
  const fundMemberships = fundMembershipsData?.results || []
  const orgMemberships = orgMembershipsData?.results || []

  if (fundMemberships.length + orgMemberships.length === 0) {
    return <EmptyState />
  }

  let teamMembers = reduceMembershipsToUniqueTeamMembers(
    TMemberableType.Fund,
    {},
    fundMemberships
  )
  teamMembers = reduceMembershipsToUniqueTeamMembers(
    TMemberableType.Organisation,
    teamMembers,
    orgMemberships
  )
  return <TeamMembers members={Object.values(teamMembers)} />
}

export default function TeamMembersPage() {
  return (
    <Layout>
      <div className="flex min-h-screen">
        <SideNavigation />
        <main className="flex flex-grow flex-col gap-6 p-6">
          <h1 className="text-2xl">Team members</h1>
          <ReadOnlyPageBanner />
          <TeamMembersWrapper />
        </main>
      </div>
    </Layout>
  )
}
