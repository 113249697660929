import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'
import { User } from '../../../shared/types'

export enum TMemberableType {
  Fund = 'Fund',
  Organisation = 'Organisation',
}

export enum TMembershipRole {
  Admin = 'admin',
  Regular = 'regular',
}

export type TMembership = {
  userId: number
  roles: TMembershipRole[]
  user: User
}

export function useMembershipsQuery(
  memberableType: TMemberableType,
  memberableId: number
) {
  return useJsonApiQuery<TMembership[]>([
    'memberships',
    {
      filter: { memberable_id: memberableId, memberable_type: memberableType },
      'extra_fields[users]': ['current_sign_in_at'],
      include: 'user',
    },
  ])
}
