import { ExternalLink } from '@changex/design-system'
import { FundApplication } from '@features/funds/types'

type Props = {
  data?: FundApplication
}

export function ApplicationDetailsMoreInfoTab({ data }: Props) {
  return (
    <div className="flex flex-col gap-y-6 pt-8">
      <h3 className="text-lg">External links</h3>
      <ul className="flex flex-col gap-y-2">
        {data?.owner.email && (
          <li>
            <ExternalLink
              href={`https://app.fullstory.com/ui/NDFB/segments/everyone/people:search:((NOW%2FDAY-29DAY:NOW%2FDAY%2B1DAY):((UserEmail:==:"${data?.owner.email}")):():():():)/0`}
            >
              View Fullstory Session
            </ExternalLink>
          </li>
        )}
        {data?.id && (
          <li>
            <ExternalLink
              href={`https://www.changex.org/admin/solution_applications/${data.id}?region_slug=ie`}
            >
              View in ActiveAdmin
            </ExternalLink>
          </li>
        )}
        {data?.owner.email && (
          <li>
            <ExternalLink
              href={`https://mandrillapp.com/activity?q=${data.owner.email}`}
            >
              View emails log
            </ExternalLink>
          </li>
        )}
      </ul>
    </div>
  )
}
