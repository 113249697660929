import { useRef, useCallback } from 'react'

import { useFormContext, useController } from 'react-hook-form'
import { XIcon } from '@heroicons/react/solid'

import { getPointFromMarker, toLatLng } from 'shared/utils/map-helpers'

import {
  DrawingManager,
  GoogleMapsWrapper,
  InputWithTrailingButton,
  Label,
  Map,
  Marker,
} from '@changex/design-system'

const DRAWING_MODES: any[] = ['marker'] // TODO:
const CLOSER_ZOOM = 5

const KeywordEditor = () => {
  const { control } = useFormContext()
  const {
    field: { onChange: onRegionsChange, value: regionsValue },
  } = useController({
    name: 'options.locationMatch.options.regions',
    control: control,
    defaultValue: [],
  })

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label>Keywords</Label>
        <p className="text-sm text-gray-500">
          You can add multiple keywords at once by using a comma-separated list.
          Using '*' as a keyword will match every address.
        </p>
      </div>
      {regionsValue.length === 0 ? (
        <div className="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 py-4 text-sm text-gray-600">
          No keywords yet
        </div>
      ) : (
        <div className="flex flex-wrap gap-2">
          {regionsValue.map((region: string, index: number) => (
            <div
              key={index}
              className="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800"
            >
              {region}
              <XIcon
                className="-mr-1 ml-2 h-5 w-5 text-gray-400"
                onClick={() => {
                  regionsValue.splice(index, 1)
                  onRegionsChange(regionsValue)
                }}
              />
            </div>
          ))}
        </div>
      )}
      <div className="w-3/5">
        <InputWithTrailingButton
          placeholder="Type a keyword and click 'Add'"
          buttonLabel="Add"
          buttonIcon="plus"
          onButtonClick={(inputRef) => {
            if (inputRef.current) {
              const newKeywords = inputRef.current.value
                .split(',')
                .map((keyword: string) => keyword.trim())
                .filter((keyword: string) => keyword && keyword.length > 0)
              onRegionsChange(regionsValue.concat(newKeywords))
              inputRef.current.value = ''
            }
          }}
        />
      </div>
    </>
  )
}

const CenterEditor = () => {
  const drawingManager = useRef(null)
  const { control } = useFormContext()
  const {
    field: { onChange: onCentreChange, value: centreValue },
  } = useController({
    name: 'options.locationMatch.options.centre',
    control: control,
  })
  const centerLatLng = toLatLng(centreValue)

  const handleOverlayComplete = useCallback(
    (overlay) => {
      if (overlay.type === window.google.maps.drawing.OverlayType.MARKER) {
        const markerOverlay = overlay.overlay
        const newCenter = getPointFromMarker(markerOverlay)
        onCentreChange(newCenter)

        markerOverlay.setMap(null)
        // @ts-ignore
        drawingManager.current.setDrawingMode(null)
      }
    },
    [onCentreChange]
  )

  const setDrawingManagerRef = useCallback(
    (ref) => (drawingManager.current = ref),
    [drawingManager]
  )

  return (
    <>
      <Label>Map centre</Label>
      <p className="text-sm text-gray-500">
        This is used to position the map that is shown on the fund's landing
        page.
      </p>
      <div className="flex flex-col gap-4">
        <div className="h-[32rem] w-full">
          <GoogleMapsWrapper>
            <Map
              center={centerLatLng}
              zoom={centerLatLng ? CLOSER_ZOOM : undefined}
            >
              <DrawingManager
                setRef={setDrawingManagerRef}
                onOverlayComplete={handleOverlayComplete}
                drawingModes={DRAWING_MODES}
              />
              {centerLatLng && <Marker position={centerLatLng} />}
            </Map>
          </GoogleMapsWrapper>
        </div>
      </div>
    </>
  )
}

const KeywordTypeEditor = () => {
  return (
    <div className="flex flex-col gap-4">
      <KeywordEditor />
      <CenterEditor />
    </div>
  )
}

export default KeywordTypeEditor
