import { Avatar, WithTooltip } from '@changex/design-system'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import RoleBadge from '@features/funds/components/team-members/role-badge'
import { User } from '@types'
import {
  TMemberableType,
  TMembershipRole,
} from '@features/funds/hooks/use-membership-query'

dayjs.extend(RelativeTime)

type TUserRole = {
  type: TMemberableType
  role: TMembershipRole
}

export type TTeamMember = {
  avatarUrl: User['avatarUrl']
  fullName: User['fullName']
  email: User['email']
  currentSignInAt: User['currentSignInAt']
  userRoles: TUserRole[]
}

const RoleBadgeTooltip = ({ role, type }: TUserRole) => {
  const tooltip = (() => {
    switch (type) {
      case TMemberableType.Fund: {
        switch (role) {
          case TMembershipRole.Admin: {
            return 'This user can view fund details and make changes to applicant status. They can also view the photo gallery, impact feed, dashboard and settings for the fund.'
          }
          case TMembershipRole.Regular: {
            return "This user can view fund details but can't make changes to applicant status. They can also view the photo gallery, impact feed, dashboard and settings for the fund."
          }
        }
        break
      }
      case TMemberableType.Organisation: {
        switch (role) {
          case TMembershipRole.Admin: {
            return 'This user can view details for all funds owned by the organisation and make changes to applicant status. They can also view the photo gallery, impact feed, dashboard and settings for all organisation funds.'
          }
          case TMembershipRole.Regular: {
            return "This user can view details for all funds owned by the organisation but can't make changes to applicant status. They can also view the photo gallery, impact feed, dashboard and settings for all organisation funds."
          }
        }
      }
    }
  })()

  return (
    <WithTooltip placement="top" tooltip={tooltip}>
      <span>
        <RoleBadge role={role} type={type} />
      </span>
    </WithTooltip>
  )
}

const TeamMember = ({
  avatarUrl,
  fullName,
  email,
  currentSignInAt,
  userRoles,
}: TTeamMember) => {
  return (
    <tr>
      <td className="flex items-center gap-4 py-4 font-medium">
        <Avatar sourceUrl={avatarUrl} className="inline-block" />
        {fullName}
      </td>
      <td className="py-4 text-gray-800">{email}</td>
      <td className="py-4">
        <span className="flex gap-1">
          {userRoles.map(({ role, type }) => (
            <RoleBadgeTooltip key={`${role}-${type}`} role={role} type={type} />
          ))}
        </span>
      </td>
      <td className="py-4 text-gray-800">{dayjs(currentSignInAt).fromNow()}</td>
    </tr>
  )
}

type TProps = {
  members: TTeamMember[]
}

const TeamMembers: React.FC<TProps> = ({ members }) => {
  return (
    <table className="divide-y divide-gray-300">
      <thead>
        <tr className="text-left">
          <th className="py-3">Name</th>
          <th className="py-3">Email</th>
          <th className="py-3">Role</th>
          <th className="py-3">Last sign-in</th>
        </tr>
      </thead>
      <tbody>
        {members.map((member) => (
          <TeamMember key={member.email} {...member} />
        ))}
      </tbody>
    </table>
  )
}

export default TeamMembers
