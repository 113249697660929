import { Icon, Selector } from '@changex/design-system'
import { SocialCategory } from '@features/funds/types'
import { Nullable, Solution } from '@types'
import styled from 'styled-components'

import { constants } from '../../constants'

type TProps = {
  filters: Record<string, Nullable<string>>
  onFilter: (name: string, value: string) => void
  onReset: () => void
  socialCategories: SocialCategory[]
  solutions: Solution[]
}

const StyledSelector = styled(Selector)`
  & > div {
    margin-top: 0;
  }
`

export function FeedFilters({
  filters,
  onFilter,
  onReset,
  socialCategories,
  solutions,
}: TProps) {
  return (
    <div
      className="flex flex-wrap items-center gap-x-6"
      data-testId="feed-filters"
    >
      <StyledSelector
        onChange={(value) => onFilter('filter[entry_type]', value)}
        options={constants.contents}
        value={filters['filter[entry_type]'] ?? ''}
      />
      <StyledSelector
        onChange={(value) => onFilter('filter[state]', value)}
        options={constants.states}
        value={filters['filter[state]'] ?? ''}
      />
      <StyledSelector
        filterOptions={{
          enabled: true,
          placeholder: 'Filter ideas by name',
        }}
        onChange={(value) => onFilter('filter[solution_id]', value)}
        options={[
          { id: '', label: 'Idea' },
          ...solutions.map((solution) => ({
            id: String(solution.id),
            label: solution.name,
          })),
        ]}
        value={filters['filter[solution_id]'] ?? ''}
      />
      {Object.entries(filters).filter(
        ([key, value]) => Boolean(value) && key !== 'page[number]'
      ).length > 0 ? (
        <button
          className="flex items-center text-sm font-medium text-red-600"
          onClick={onReset}
        >
          <Icon icon="XIcon" size="xs" />
          Clear filters
        </button>
      ) : null}
    </div>
  )
}
