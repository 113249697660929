import { useRef, useCallback } from 'react'

import { useFormContext, useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import {
  Circle,
  DrawingManager,
  GoogleMapsWrapper,
  Label,
  Map,
} from '@changex/design-system'

import {
  METRES_PER_KM,
  toLatLng,
  getCenterPointFromCircle,
  getRadiusFromCircle,
} from 'shared/utils/map-helpers'

const DRAWING_MODES: any[] = ['circle'] // TODO:
const ZOOM_CHANGE_THRESHOLD = 50 * METRES_PER_KM
const FURTHER_ZOOM = 6
const CLOSER_ZOOM = 8

function CircleTypeEditor() {
  const drawingManager = useRef(null)
  const { control } = useFormContext()
  const {
    field: { onChange: onRadiusChange, value: radius },
  } = useController({
    name: 'options.locationMatch.options.radius',
    control: control,
  })
  const {
    field: { onChange: onCentreChange, value: centerValue },
  } = useController({
    name: 'options.locationMatch.options.centre',
    control: control,
  })
  const centerLatLng = toLatLng(centerValue)

  const handleOverlayComplete = useCallback(
    (overlayWrapper) => {
      const overlay = overlayWrapper.overlay
      if (
        overlayWrapper.type === window.google.maps.drawing.OverlayType.CIRCLE
      ) {
        const newRadius = Math.round(getRadiusFromCircle(overlay))
        onRadiusChange(newRadius)
        onCentreChange(getCenterPointFromCircle(overlay))

        overlay.setMap(null)
        // @ts-ignore
        drawingManager.current.setDrawingMode(null)
      }
    },
    [onRadiusChange, onCentreChange]
  )

  const setDrawingManagerRef = useCallback(
    (ref) => (drawingManager.current = ref),
    [drawingManager]
  )

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm text-gray-500">
        Draw the catchment area. You can tweak the exact radius after drawing
        the circle.
      </p>
      <div className="h-[32rem] w-full">
        <GoogleMapsWrapper>
          <Map
            zoom={radius < ZOOM_CHANGE_THRESHOLD ? CLOSER_ZOOM : FURTHER_ZOOM}
            center={centerLatLng}
          >
            <DrawingManager
              setRef={setDrawingManagerRef}
              onOverlayComplete={handleOverlayComplete}
              drawingModes={DRAWING_MODES}
            />
            {centerLatLng && <Circle center={centerLatLng} radius={radius} />}
          </Map>
        </GoogleMapsWrapper>
      </div>
      <div className="w-1/5">
        <Label htmlFor="options.locationMatch.options.radius">Radius</Label>
        <NumberFormat
          value={radius ? radius / METRES_PER_KM : 0}
          onValueChange={(value) => {
            onRadiusChange(
              value.floatValue ? value.floatValue * METRES_PER_KM : 0
            )
          }}
          thousandSeparator={true}
          suffix=" km"
          decimalScale={3}
          className="block w-full rounded-md border-gray-300 sm:text-sm"
        />
      </div>
    </div>
  )
}

export default CircleTypeEditor
