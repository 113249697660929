import { utils } from '@features/funds/utils'
import { Solution } from '@types'

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

type Props = {
  highlightType: string
  userPhotoUrl: string
  userFirstName: string
  userLastName: string
  locationAddress: string
  locationName: string
  locationSlug: string
  id: string
  postId: string
  idea: Solution
}

export function ListItem({
  highlightType,
  userPhotoUrl,
  userFirstName,
  userLastName,
  locationAddress,
  locationName,
  locationSlug,
  id,
  postId,
  idea,
}: Props) {
  const fullName = [userFirstName, userLastName].join(' ')
  const projectLink = [CHANGEX_ADDRESS, idea.slug, locationSlug].join('/')
  const projectLinkElement = (
    <a
      href={projectLink}
      className="hover:underline"
      target="_blank"
      rel="noreferrer"
    >
      {locationName}
    </a>
  )
  const truncatedLocationAddress = utils.truncateAddress(locationAddress)

  return (
    <li className="flex gap-x-4 py-4">
      <img
        className="h-12 w-12 flex-none rounded-full bg-gray-50"
        src={userPhotoUrl}
        alt=""
      />
      <div className="min-w-0">
        <p>{fullName}</p>
        <p className="mt-1 text-sm leading-5 text-gray-600">
          {highlightType === 'impact_report' ? (
            <>
              'shared impact' on{' '}
              <a
                href={[CHANGEX_ADDRESS, 'posts', id].join('/')}
                className="hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                {locationName}
              </a>
            </>
          ) : null}

          {highlightType === 'comment' ? (
            <>
              'commented' on{' '}
              <a
                href={[CHANGEX_ADDRESS, 'posts', postId].join('/')}
                className="hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                {locationName}
              </a>
            </>
          ) : null}

          {highlightType.includes('applicant') ? (
            <span>
              {projectLinkElement}, {truncatedLocationAddress}
            </span>
          ) : null}
        </p>
      </div>
    </li>
  )
}
