import SideNavigation from 'features/funds/components/settings/side-navigation'
import Layout from 'app/funds/[id]/layout'
import { BookOpenIcon } from '@heroicons/react/outline'
import { useCurrentUser, useFund } from 'shared/providers'
import { ErrorPage } from '@features/not-found/pages/error.page'
import { useJsonApiQuery } from 'shared/hooks/json-api/use-json-api-query'
import { Fund, FundAudit } from '@types'
import { Skeleton } from '@changex/design-system'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function useFundAuditsQuery(fundId?: string) {
  return useJsonApiQuery<Fund & { audits: Array<FundAudit> }>([
    'funds',
    fundId,
    { extra_fields: { funds: 'audits' } },
  ])
}

const EmptyState = () => {
  return (
    <div className="mt-32 flex flex-col items-center">
      <BookOpenIcon className="h-12 w-12 text-gray-400" />
      <p className="mt-2 text-lg font-semibold text-gray-900">No changes yet</p>
      <p className="mt-1 text-gray-700 opacity-70">
        There is no history of changes for this fund
      </p>
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex gap-4">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <hr />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  )
}

const OnlyCxAdmin = ({ children }) => {
  const user = useCurrentUser()

  if (user.userType !== 'cx_admin') {
    return <ErrorPage />
  }

  return children
}

const LogItem = ({ diffAmount, auditedChanges, user, createdAt, currency }) => {
  return (
    <tr>
      <td className="px-3 py-5 text-base text-gray-500">
        {dayjs(createdAt).utc().format('DD MMM YYYY, h:mma')}
      </td>
      <td className="px-3 py-5 text-base">{user}</td>
      <td className="px-3 py-5 text-right text-base text-gray-500">
        <div>
          {new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'narrowSymbol',
          }).format(auditedChanges.amountUsd)}
        </div>
      </td>
      <td className="px-3 py-5 text-right text-base text-gray-500">
        <div>{auditedChanges.exchangeRateUsd}</div>
      </td>
      <td className="px-3 py-5 text-right text-base text-gray-500">
        <div>
          {new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
          }).format(auditedChanges.amountEur)}
        </div>
      </td>
      <td className="px-3 py-5 text-right text-base text-gray-500">
        <div>{auditedChanges.exchangeRateEur}</div>
      </td>
      <td className="px-3 py-5 pr-4 text-right text-base text-gray-500">
        {diffAmount &&
          new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'narrowSymbol',
          }).format(diffAmount)}
      </td>
      <td className="px-3 py-5 pr-4 text-right text-base font-bold">
        {new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency,
          currencyDisplay: 'narrowSymbol',
        }).format(auditedChanges.amount)}
      </td>
    </tr>
  )
}

const Changelog = () => {
  const fund = useFund()
  const { isLoading, data } = useFundAuditsQuery(fund.id)

  if (isLoading) {
    return <LoadingSkeleton />
  }

  if (!data?.results || data.results.audits.length === 0) {
    return <EmptyState />
  }

  const auditItems = data.results.audits.sort((a, b) =>
    dayjs(b.createdAt).diff(dayjs(a.createdAt))
  )

  return (
    <table className="w-full divide-y divide-gray-300">
      <thead>
        <tr className="border-t border-gray-100 bg-gray-50">
          <th className="px-3 py-4 text-left text-base font-medium">
            Date (UTC)
          </th>
          <th className="px-3 py-4 text-left text-base font-medium">
            Changed by
          </th>
          <th className="px-3 py-4 text-right text-base font-medium">USD</th>
          <th className="px-3 py-4 text-right text-base font-medium">Rate</th>
          <th className="px-3 py-4 text-right text-base font-medium">EUR</th>
          <th className="px-3 py-4 text-right text-base font-medium">Rate</th>
          <th className="px-3 py-4 text-right text-base font-medium">Change</th>
          <th className="px-3 py-4 text-right text-base font-medium">
            Total ({fund.options.currency})
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {auditItems.map((item, index) => (
          <LogItem
            key={item.id}
            currency={fund.options.currency}
            diffAmount={
              index < auditItems.length - 1
                ? item.auditedChanges.amount -
                  auditItems[index + 1].auditedChanges.amount
                : undefined
            }
            {...item}
          />
        ))}
      </tbody>
    </table>
  )
}

export default function ChangelogPage() {
  return (
    <Layout>
      <OnlyCxAdmin>
        <div className="flex min-h-screen">
          <SideNavigation />
          <main className="flex flex-grow flex-col gap-6 p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl">Changelog</h1>
            </div>
            <Changelog />
          </main>
        </div>
      </OnlyCxAdmin>
    </Layout>
  )
}
