import { groupBy } from 'lodash'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import { useFeedHighlightsQuery } from '@features/funds/hooks/use-feed-query'
import { useParams } from 'react-router-dom'
import { Solution } from '@types'
import { ListItem } from './feed-item'
import { StarIcon } from './start-icon'

const ACCORDION_TITLES = {
  applicant: 'New applicants',
  approved_applicant: 'Approved applicants',
  comment: 'Comments',
  impact_report: 'Impact reports',
}

type Props = {
  ideas: { [key: string]: Solution }
}

export default function FeedSidebar({ ideas }: Props) {
  const { fundId } = useParams<{ fundId: string }>()
  const feedQuery = useFeedHighlightsQuery<any>(fundId)
  const accordionGroups = groupBy(feedQuery?.data?.results, 'highlightType')

  return (
    <div className="overflow-hidden rounded-lg border border-gray-200">
      <div
        className="flex items-center gap-3 bg-teal-800 p-9 py-5 text-xl text-teal-800"
        style={{ backgroundColor: '#F2F7F7' }}
      >
        <StarIcon /> Recent highlights
      </div>
      <div className="p-6">
        <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
          {Object.keys(ACCORDION_TITLES).map((group, i) => (
            <Disclosure key={i} defaultOpen={i === 0}>
              {({ open }) => (
                <div className="border-b border-gray-200 last:border-b-0">
                  <Disclosure.Button className="flex w-full justify-between rounded-lg py-5 px-3 text-left font-medium text-teal-800 hover:bg-gray-50">
                    <span>{ACCORDION_TITLES[group]}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-teal-800`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-2 pb-6">
                    <ul className="divide-y divide-gray-100">
                      {accordionGroups[group] ? (
                        accordionGroups[group]?.map((item) => (
                          <ListItem
                            key={item.id}
                            {...item}
                            idea={ideas[item.solutionId]}
                          />
                        ))
                      ) : !feedQuery?.isLoading ? (
                        <div className="mt-2 pl-1 text-gray-700">
                          Come back soon for activity updates.
                        </div>
                      ) : null}
                    </ul>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  )
}
