import { httpClient } from '@api'

type TInput = {
  likeable_id: string
  type: 'posts'
}

export function useLikePostMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.post('/likes', { data: { attributes: input } })
    },
  }
}
