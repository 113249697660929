import { useMutation } from '@tanstack/react-query'
import { httpClient } from '@api'

type Options = {
  allApplications?: boolean
  fundId: number | string
}

export function useApplicationDownload() {
  return useMutation({
    mutationFn: (opts: Options) => {
      let params = `filter[fund_id]=${opts.fundId}`
      if (opts.allApplications) {
        params += '&all_applications=true'
      }

      return httpClient.get(`/funds/export?${params}`)
    },
  })
}
