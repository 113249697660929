import { Button, Icon, WithTooltip } from '@changex/design-system'
import { InlineTextLoadingIndicator } from '@components'
import classNames from 'classnames'
import { GREEN_BUTTON_CLASSES } from '@features/funds/components/applications-list/application/application.constants'
import { CurrencyFormatter } from '../../../../../../../shared/currency/components/currency-formatter'
import { UserFundRole } from '@features/funds/types'
import { useFund } from 'shared/providers'

type Props = {
  onClick?: () => void
  disabled?: boolean
  isLoading?: boolean
  isLocked?: boolean
  acceptActionText: string
  amount?: number
  tooltip?: string
}

export function ApplicationApprovalAcceptButton({
  onClick,
  isLoading,
  disabled,
  amount,
  acceptActionText,
  isLocked,
  tooltip,
}: Props) {
  const fund = useFund()
  const ButtonComp = () => (
    <Button
      className={classNames(GREEN_BUTTON_CLASSES)}
      disabled={disabled || isLoading || isLocked}
      onClick={onClick}
    >
      <InlineTextLoadingIndicator isLoading={Boolean(isLoading)}>
        <span className="inline-flex items-center gap-x-2">
          {acceptActionText}{' '}
          {amount ? (
            <>
              (<CurrencyFormatter value={amount} />)
            </>
          ) : null}
          {isLocked && <Icon icon="LockClosed" variant="solid" size="xs" />}
        </span>
      </InlineTextLoadingIndicator>
    </Button>
  )

  if (fund.userRole !== UserFundRole.Admin) return null

  if (tooltip) {
    return (
      <WithTooltip tooltip={tooltip}>
        <span>
          <ButtonComp />
        </span>
      </WithTooltip>
    )
  }
  return <ButtonComp />
}
