import { ApiClient, ApiProvider } from 'jsonapi-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { httpClient, jsonApiSchema } from '@api'
import { JsonApiProvider } from '../shared/hooks/json-api/json-api-provider'
import { JsonApiSerializer } from '../shared/hooks/json-api/json-api-serializer'

type Props = {
  children: React.ReactNode | React.ReactNode[]
  queryClient?: QueryClient
}

export const jsonApiClient = new ApiClient({
  url: process.env.REACT_APP_API_ADDRESS,
  fetchOptions: {
    credentials: 'include',
  },
  schema: jsonApiSchema,
  cacheTime: 5 * 60,
  staleTime: 60,
})

export const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const jsonApiSerializer = new JsonApiSerializer({
  schema: jsonApiSchema,
})

const jsonApiMethods = {
  query: (url) => httpClient.get(url).then((res) => res.data),
  create: (url, attribs) =>
    httpClient.post(url, attribs).then((res) => res.data),
  update: (url, attribs) =>
    httpClient.put(url, attribs).then((res) => res.data),
}

const QueryInfrastructure: React.FC<Props> = ({
  children,
  queryClient = defaultQueryClient,
}) => (
  <JsonApiProvider
    query={jsonApiMethods.query}
    create={jsonApiMethods.create}
    update={jsonApiMethods.update}
    serializer={jsonApiSerializer}
  >
    <QueryClientProvider client={queryClient}>
      <ApiProvider client={jsonApiClient}>{children}</ApiProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </JsonApiProvider>
)

export default QueryInfrastructure
