import { ApplicationApprovalStageProps } from './application-approval.types'
import { ApplicationApprovalRejectButton } from './components/application-approval-reject-button'
import { ApplicationApprovalAcceptButton } from './components/application-approval-accept-button'
import { useRejectApplication } from './hooks/use-reject-application'
import { APPLICATION_APPROVAL_REJECT_TOOLTIP } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { ApplicationPaymentAccountsStatus } from './components/ApplicationPaymentAccountsStatus'

export function ApplicationApprovalStageAllocated({
  application,
}: ApplicationApprovalStageProps) {
  const reject = useRejectApplication(application)

  return (
    <>
      <div className="flex items-center gap-x-3">
        <ApplicationPaymentAccountsStatus
          paymentAccounts={
            application?.location?.payments?.location?.paymentAccounts
          }
        />
        <ApplicationApprovalRejectButton
          onClick={reject.mutate}
          isLoading={reject.isLoading}
          tooltip={APPLICATION_APPROVAL_REJECT_TOOLTIP}
        />
      </div>
      <div className="flex items-center gap-x-3">
        <ApplicationApprovalAcceptButton
          disabled={true}
          isLocked={true}
          acceptActionText={'Approve'}
          amount={
            application?.location?.payments?.location?.budgets?.allocated
              .balance
          }
        />
      </div>
    </>
  )
}
