import { ApplicationApprovalStageProps } from './application-approval.types'
import { ApplicationApprovalRejectButton } from './components/application-approval-reject-button'
import { ApplicationApprovalStageSuccessTick } from './components/application-approval-stage-success-tick'
import { CurrencyFormatter } from '../../../../../../shared/currency/components/currency-formatter'
import { useRejectAndRefundApplication } from './hooks/use-reject-and-refund-application'
import { APPLICATION_APPROVAL_REJECT_AND_REFUND_TOOLTIP } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { ApplicationPaymentAccountsStatus } from './components/ApplicationPaymentAccountsStatus'
import { ApplicationPayment } from './application-payment'
import { ApplicationApprovalStageSuccessTickSolution } from './components/application-approval-stage-success-tick-solution'
import { TAllocationType } from 'features/funds/types'

export function ApplicationApprovalStagePaidImpact({
  application,
}: ApplicationApprovalStageProps) {
  const rejectAndRefund = useRejectAndRefundApplication(application)
  return (
    <>
      <div className="flex items-center gap-x-3">
        <ApplicationPaymentAccountsStatus
          paymentAccounts={
            application?.location?.payments?.location.paymentAccounts
          }
        />
        <ApplicationApprovalRejectButton
          rejectActionText="Reject and refund"
          tooltip={APPLICATION_APPROVAL_REJECT_AND_REFUND_TOOLTIP}
          onClick={rejectAndRefund.mutate}
          isLoading={rejectAndRefund.isLoading}
        />
      </div>
      <div className="flex items-center gap-x-3">
        <ApplicationApprovalStageSuccessTick>
          Seed paid (
          <CurrencyFormatter
            value={
              application.location.payments?.location.budgets.paid
                .allocationBalances.seed!
            }
          />
          )
        </ApplicationApprovalStageSuccessTick>
        <ApplicationApprovalStageSuccessTickSolution
          application={application}
        />
        <ApplicationApprovalStageSuccessTick>
          Impact paid (
          <CurrencyFormatter
            value={
              application.location.payments?.location.budgets.paid
                .allocationBalances.impact ?? 0
            }
          />
          )
        </ApplicationApprovalStageSuccessTick>
        <ApplicationPayment
          allocationType={TAllocationType.Solution}
          amount={
            application?.location?.payments?.solution?.budgets.approved.balance
          }
          application={application}
        />
      </div>
    </>
  )
}
