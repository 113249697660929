import { APPLICATIONS_SORT_CRITERIA, APPLICATIONS_TAB_LIST } from '../constants'
import { useApplicationsQueryParams } from './use-applications-query-params'
import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'
import { useFund } from '../../../shared/providers'
import { APPLICATIONS_PAGE_SIZE } from '@features/funds/constants'

/**
 * Queries the backend for the list of applications based on the url parameter state
 */
export function useApplicationList() {
  const fund = useFund()
  const [applicationsQueryParams] = useApplicationsQueryParams()

  const tab = APPLICATIONS_TAB_LIST(fund.options.type)[
    applicationsQueryParams.tab ?? 0
  ]

  return useJsonApiQuery<any>([
    'applications',
    {
      filter: {
        fund_id: fund.id,
        ...(applicationsQueryParams.q
          ? { keywords: applicationsQueryParams.q }
          : {}),
        status: tab.statusId,
        type: tab.type,
        solution_id: applicationsQueryParams.solutionId,
      },
      page: {
        size: APPLICATIONS_PAGE_SIZE,
        number: applicationsQueryParams.page,
      },
      stats: {
        total: 'count',
      },
      include: [
        'fund',
        'owner',
        'location',
        'solution',
        'onboarding_steps',
        'completed_onboarding_steps',
        'members',
      ],
      'extra_fields[locations]': ['payments'],
      'extra_fields[solutions]': ['templates'],
      sort: applicationsQueryParams.sort ?? APPLICATIONS_SORT_CRITERIA[0].id,
    },
  ])
}
