import { useFund } from '../../../shared/providers'
import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'

export function useSolutionsQuery<T>(fundId?: string) {
  const fund = useFund()
  return useJsonApiQuery<T>([
    'funds',
    fundId ? fundId : fund.id,
    { include: 'solutions' },
  ])
}

export function useSolutionQuery<T>(solution_id: number) {
  return useJsonApiQuery<T>(['solutions', solution_id])
}
