import { createContext, PropsWithChildren, useContext } from 'react'
import { Setting } from '../types'

const SettingContext = createContext<Setting[] | null>(null)

export function SettingsProvider({
  children,
  settings,
}: PropsWithChildren<{ settings: Setting[] }>) {
  return (
    <SettingContext.Provider value={settings}>
      {children}
    </SettingContext.Provider>
  )
}

export function useSettings() {
  const settings = useContext(SettingContext)
  if (!settings) {
    throw new Error('Please provide a settings provider to use settings data')
  }

  return settings
}
