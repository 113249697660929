import { Label, PeriodInput, Tooltip, Icon } from '@changex/design-system'
import { PERIOD_OPTIONS } from '@changex/design-system'
import { useFund } from 'shared/providers'
import { ImpactWaitingPeriodSetting } from '@types'
import useSetting from 'shared/hooks/use-setting'

const TOOLTIP = `Impact steps will be locked until the minimum time has passed
since the applicant has reached paid seed status. We don't enforce
the maximum deadline, but it will be used to communicate the
expected timeline to applicants.`

const TimeRangeEditor = () => {
  const fundId = useFund().id

  const defaultSetting: ImpactWaitingPeriodSetting = {
    key: 'impact_waiting_period',
    settingableId: fundId,
    settingableType: 'Fund',
    settingType: 'object',
    value: {
      minUnit: 'months',
      maxUnit: 'months',
      minTimeDays: 56,
      maxTimeDays: 168,
    },
  }

  const [settingValue, setSettingValue] = useSetting(defaultSetting)

  const { minUnit, maxUnit, minTimeDays, maxTimeDays } = settingValue

  const setMinUnit = (unit) => {
    setSettingValue({ ...settingValue, minUnit: unit })
  }

  const setMaxUnit = (unit) => {
    setSettingValue({ ...settingValue, maxUnit: unit })
  }

  const setMinDays = (days) => {
    setSettingValue({ ...settingValue, minTimeDays: days })
  }

  const setMaxDays = (days) => {
    setSettingValue({ ...settingValue, maxTimeDays: days })
  }

  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <Label>Impact reporting timeline</Label>
        <div>
          <Tooltip>
            <Tooltip.Trigger>
              <div className="mt-1">
                <Icon
                  icon="QuestionMarkCircle"
                  size="xs"
                  className="text-gray-400"
                />
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content>{TOOLTIP}</Tooltip.Content>
          </Tooltip>
        </div>
      </div>
      <div className="mt-2 flex">
        <div className="flex basis-3/5 gap-4">
          <PeriodInput
            label="Minimum"
            id="min-time"
            onDaysChange={(values) =>
              setMinDays(values * PERIOD_OPTIONS[minUnit])
            }
            onUnitChange={(unit) => setMinUnit(unit)}
            values={{
              days: minTimeDays / PERIOD_OPTIONS[minUnit],
              unit: minUnit,
            }}
          />
          <PeriodInput
            label="Maximum"
            id="max-time"
            onDaysChange={(values) =>
              setMaxDays(values * PERIOD_OPTIONS[maxUnit])
            }
            onUnitChange={(unit) => setMaxUnit(unit)}
            values={{
              days: maxTimeDays / PERIOD_OPTIONS[maxUnit],
              unit: maxUnit,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TimeRangeEditor
