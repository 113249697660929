import { PropsWithChildren } from 'react'
import { LoadingIcon } from '@changex/design-system'

export function InlineTextLoadingIndicator({
  isLoading,
  children,
}: PropsWithChildren<{ isLoading: boolean }>) {
  if (isLoading) {
    return (
      <span className="inline-flex items-center gap-2">
        <LoadingIcon />
        Loading
      </span>
    )
  }

  return <>{children}</>
}
