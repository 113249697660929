import { TPaymentStatus } from '@features/funds/types'
import { WithTooltip } from '@changex/design-system'
import { CheckIcon, ClockIcon, XIcon } from '@heroicons/react/outline'
import classnames from 'classnames'

const STATUS_INFO = {
  [TPaymentStatus.succeeded]: {
    label: 'Paid out',
    iconClassName: 'bg-green-100 text-green-800',
    IconComponent: CheckIcon,
  },
  [TPaymentStatus.failed]: {
    label: 'Failed',
    iconClassName: 'bg-red-100 text-red-800',
    IconComponent: XIcon,
  },
  [TPaymentStatus.incomplete]: {
    label: 'Pending',
    iconClassName: 'bg-gray-100 text-gray-800',
    IconComponent: ClockIcon,
  },
  [TPaymentStatus.processing]: {
    label: 'Pending',
    iconClassName: 'bg-gray-100 text-gray-800',
    IconComponent: ClockIcon,
  },
  [TPaymentStatus.authorising]: {
    label: 'Authorising',
    iconClassName: 'bg-orange-100 text-gray-800',
    IconComponent: ClockIcon,
  },
}

const PaymentStatus = ({ status }: { status: TPaymentStatus }) => {
  const { label, IconComponent, iconClassName } = STATUS_INFO[status]
  return (
    <WithTooltip tooltip={label} placement="top" data-testid="tooltip">
      <span>
        <IconComponent
          className={classnames(
            'h-6 w-6 rounded-full px-1 py-1',
            iconClassName
          )}
          aria-labelledby="paymentStatusSrLabel"
        />
        <p className="sr-only" id="paymentStatusSrLabel">
          {label}
        </p>
      </span>
    </WithTooltip>
  )
}

export default PaymentStatus
