import { InformationCircleIcon } from '@heroicons/react/outline'

const ReadOnlyPageBanner = () => {
  return (
    <div className="flex w-full items-center gap-4 rounded-md bg-blue-50 py-3 px-5 text-blue-900">
      <InformationCircleIcon className="h-6 w-6 text-blue-900" />
      <span>
        <strong>You can't make changes on this page.</strong> Reach out for
        support if something looks incorrect
      </span>
    </div>
  )
}

export default ReadOnlyPageBanner
