import { Post } from '@changex/design-system'
import { FeedContentApplication } from '@features/funds/types'
import { useUserQuery } from '@features/funds/hooks/use-user-query'
import { utils } from '@features/funds/utils'
import dayjs from 'dayjs'

import { TFeedListItemProps } from './types'

type TProps = TFeedListItemProps<FeedContentApplication>

export default function FeedListItemApplication({ idea, item }: TProps) {
  const userQuery = useUserQuery(item.content.userId)
  const badge = utils.getBadgeStatus(utils.getStatus(item.state.toLowerCase()))
  const isOpenProject = String(item.solutionId) === '99'

  return (
    <Post
      post={{
        content: item.content.motivation || 'n/a',
        country: item.content.countryName,
        date: dayjs(item.createdAt).format('MMM DD[,] YYYY [at] h:mm A'),
        event: 'has started',
        header: idea.header,
        location: {
          link: utils.createLinkToPage({
            location: { slug: item.content.locationSlug },
            solution: { slug: idea.slug },
          }),
          name: item.content.locationName,
        },
        status: {
          name: item.state,
          variant: badge === item.state || badge === '_' ? 'basic' : badge,
        },
        tag: {
          prefix: isOpenProject ? 'Open grant project' : 'Idea',
          link: isOpenProject
            ? undefined
            : utils.createLinkToIdea({ solution: { slug: idea.slug } }),
          name: isOpenProject ? undefined : idea.name,
        },
        user: {
          name: `${item.content.userFirstName} ${item.content.userLastName}`,
          picture: userQuery.data?.results.avatarUrl || '',
        },
      }}
      type="motivation"
    />
  )
}
