import { Button, Label, MoneyInput } from '@changex/design-system'
import { useCallback, useState } from 'react'
import { GREEN_BUTTON_CLASSES } from '@features/funds/components/applications-list/application/application.constants'
import toast from 'react-hot-toast'
import { CurrencyFormatter } from '../../../../../../../shared/currency/components/currency-formatter'
import { useFundCurrency } from '../../../../../../../shared/currency/hooks/use-fund-currency'

type Props = {
  onAllocate: (amount: number) => void
  onCancel: () => void
  max: number
  min: number
}

export function ApplicationApprovalAllocateCustom({
  onAllocate,
  onCancel,
  min,
  max,
}: Props) {
  const currency = useFundCurrency()
  const [customAmount, setCustomAmount] = useState<number | undefined>()

  const handleAllocate = useCallback(() => {
    if (!customAmount) {
      toast.error('Please enter an allocation amount')
      return
    }
    if (customAmount < min) {
      toast.error(
        <>
          Please enter an allocation amount above the minimum of{' '}
          <CurrencyFormatter value={min} />
        </>
      )
      return
    }
    if (customAmount > max) {
      toast.error(
        <>
          Please enter an allocation amount below the maximum of{' '}
          <CurrencyFormatter value={max} />
        </>
      )
      return
    }
    onAllocate(customAmount)
  }, [max, min, onAllocate, customAmount])

  return (
    <div className="flex items-center gap-x-2">
      <Label htmlFor="amount" srOnly>
        Amount
      </Label>
      <MoneyInput
        amount={customAmount}
        amountElementId="amount"
        onAmountChange={setCustomAmount}
        currency={currency}
        canChangeCurrency={false}
        autoFocus
      />
      <Button
        type="submit"
        className={GREEN_BUTTON_CLASSES}
        onClick={handleAllocate}
      >
        Allocate
      </Button>
      <Button weight="tertiary" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}
