import { Badge } from '@changex/design-system'
import { PaymentsLabelAndValue } from '@features/funds/components/application-details/payments/payments-label-and-value'
import { PaymentAccountStatus } from '@features/funds/components/application-details/payments/payment-account-status'
import { CurrencyFormatter } from '../../../../../shared/currency/components/currency-formatter'
import { PaymentAccount } from '@features/funds/types'

type StageDetailsProps = {
  title: string
  subtitle?: string
  paymentAccounts?: PaymentAccount[]
  totalPayment: number
  children: React.ReactNode
}

type StageStatusProps = {
  title: string
  amount?: number
  isPaid?: boolean
  awaitingPayment: boolean
}

type StageBadgeProps = {
  isPaid: boolean
  awaitingPayment: boolean
}

const StatusBadge = ({ isPaid, awaitingPayment }: StageBadgeProps) => (
  <>
    {isPaid ? (
      <Badge className="ml-2" variant="success">
        Paid
      </Badge>
    ) : (
      <Badge>{awaitingPayment ? 'Waiting payment' : '--'}</Badge>
    )}
  </>
)

const StageStatus = ({
  title,
  amount = 0,
  isPaid = false,
  awaitingPayment = true,
}: StageStatusProps) => (
  <>
    <PaymentsLabelAndValue label={title}>
      <h4 className="text-sm text-gray-700 opacity-70">
        <CurrencyFormatter value={amount} />
      </h4>
      <StatusBadge isPaid={isPaid} awaitingPayment={awaitingPayment} />
    </PaymentsLabelAndValue>
  </>
)

const PaymentStageDetailsRoot = ({
  title,
  subtitle,
  paymentAccounts,
  totalPayment,
  children,
}: StageDetailsProps) => (
  <div className="flex flex-col gap-y-7">
    <div>
      <h2 className="text-lg font-medium">{title}</h2>
      {subtitle ? (
        <h3 className="text-sm text-gray-700 opacity-70">{subtitle}</h3>
      ) : null}
    </div>
    <div className="flex justify-between gap-8">
      <PaymentsLabelAndValue label="Payment account status">
        <PaymentAccountStatus paymentAccounts={paymentAccounts!} />
      </PaymentsLabelAndValue>
      {children}
      <PaymentsLabelAndValue label="Total paid" size="small">
        <h3 className="font-bold text-gray-900">
          <CurrencyFormatter value={totalPayment} />
        </h3>
      </PaymentsLabelAndValue>
    </div>
  </div>
)

export const PaymentStageDetails = Object.assign(PaymentStageDetailsRoot, {
  StageStatus,
})
export default PaymentStageDetails
