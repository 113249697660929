import {
  Skeleton,
  Stat,
  formatNumber,
  formatPercentage,
} from '@changex/design-system'
import { useImpactAnalyticsQuery } from 'features/funds/hooks/use-impact-analytics-query'
import { FundWithAggregates } from '@types'

const LoadingSkeleton = () => {
  return (
    <section className="bg-teal-800 py-8">
      <div className="max-w-changex relative mx-auto">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl text-white">Impact</h1>
          <Skeleton w="1170px" h="160px" />
        </div>
      </div>
    </section>
  )
}

type TProps = {
  fund: FundWithAggregates
}

const DashboardImpact: React.FC<TProps> = ({ fund }) => {
  const {
    isLoading,
    error,
    data: impactAnalyticsData,
  } = useImpactAnalyticsQuery(fund.id)
  const impactAnalytics = impactAnalyticsData?.results
  if (isLoading) {
    return <LoadingSkeleton />
  }

  if (!!error || !impactAnalytics || !fund.aggregates) {
    // TODO
    return <div>Error loading analytics</div>
  }

  const fundedTeams =
    (fund.aggregates.paidSeed ?? 0) +
    (fund.aggregates.impact ?? 0) +
    (fund.aggregates.paidImpact ?? 0)

  const {
    totalParticipants = 0,
    totalChildrenParticipants = 0,
    totalBeneficiaries = 0,
    totalChildrenBeneficiaries = 0,
  } = impactAnalytics.participantsData || {}

  const percentageOfSurveysCompleted = formatPercentage(
    impactAnalytics.completedSurveys / fundedTeams || 0
  )

  return (
    <section className="bg-teal-800 py-8">
      <div className="max-w-changex relative mx-auto">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl text-white">Impact</h1>
          <div className="grid grid-cols-4 divide-x divide-gray-100 rounded bg-white">
            <Stat
              title="Completed impact surveys"
              value={impactAnalytics.completedSurveys}
              secondaryText={
                fundedTeams === 1
                  ? `${percentageOfSurveysCompleted} of  1 funded team`
                  : `${percentageOfSurveysCompleted} of ${formatNumber(
                      fundedTeams
                    )} funded teams`
              }
            />
            <Stat
              title="Participants"
              value={totalParticipants}
              secondaryText={
                totalChildrenParticipants === 1
                  ? '1 child'
                  : `${formatNumber(totalChildrenParticipants)} children`
              }
            />
            <Stat
              title="People impacted"
              value={totalBeneficiaries}
              secondaryText={
                totalChildrenBeneficiaries === 1
                  ? '1 child'
                  : `${formatNumber(totalChildrenBeneficiaries)} children`
              }
            />
            <Stat title="Team members" value={impactAnalytics.teamSize} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DashboardImpact
