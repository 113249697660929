import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PropsWithChildren, useMemo } from 'react'

export function StripeProvider({
  children,
  clientSecret,
  publicKey,
}: PropsWithChildren<{ clientSecret: string; publicKey: string }>) {
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  }
  const stripePromise = useMemo(() => loadStripe(publicKey), [publicKey])

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  )
}
