import { useJsonApiMutation } from '../../../shared/hooks/json-api/use-json-api-mutation'
import { TAllocationType } from 'features/funds/types'

export function useApplicationCreateManualPaymentMutation() {
  return useJsonApiMutation<{
    allocation_type?: TAllocationType
    application_id?: number | string
    fund_id: number | string
    location_id?: number
  }>('manual_payments')
}

export function useApplicationCreatePaymentMutation() {
  return useJsonApiMutation<{
    allocation_type?: TAllocationType
    payment_account_id?: number
    reference_location_id?: number | null
  }>('payments')
}
