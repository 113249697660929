import { DropdownItem } from '@changex/design-system'
import { useCallback, useState } from 'react'
import { useRejectApplication } from './hooks/use-reject-application'
import { ApplicationApprovalAllocateCustom } from '@features/funds/components/applications-list/application/approval/inbox/application-approval-allocate-custom'
import toast from 'react-hot-toast'
import { useFund } from '../../../../../../shared/providers'
import { CurrencyFormatter } from '../../../../../../shared/currency/components/currency-formatter'
import { ApplicationApprovalStageProps } from '@features/funds/components/applications-list/application/approval/application-approval.types'
import { APPLICATION_APPROVAL_TOAST_DURATION } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { useApplicationAllocateMutation } from '@features/funds/hooks/use-application-allocate-mutation'
import { ApplicationApprovalAcceptButton } from '@features/funds/components/applications-list/application/approval/components/application-approval-accept-button'
import { ApplicationApprovalAcceptButtonWithDropdown } from '@features/funds/components/applications-list/application/approval/components/application-approval-accept-button-with-dropdown'
import { ApplicationApprovalRejectButton } from './components/application-approval-reject-button'
import { ApplicationPaymentAccountsStatus } from './components/ApplicationPaymentAccountsStatus'
import { APPLICATION_APPROVAL_REJECT_TOOLTIP } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { ConfirmationModal } from 'components/confirmation-modal'

export function ApplicationApprovalStageInbox({
  application,
}: ApplicationApprovalStageProps) {
  const [allocateFn, setAllocateFn] = useState<() => void>(() => {})
  const [open, setOpen] = useState(false)
  const reject = useRejectApplication(application)
  const [enterCustomAmount, setEnterCustomAmount] = useState(false)

  const allocationMutation = useApplicationAllocateMutation()

  const fund = useFund()

  const clickHandler = (callback) => {
    setOpen(!open)
    setAllocateFn(() => callback)
  }

  const handleAllocate = useCallback(
    (amount: number) => {
      setEnterCustomAmount(false)
      allocationMutation.mutate(
        {
          application_id: application.id,
          fund_id: application.fund.id!,
          amount,
        },
        {
          onSuccess: () => {
            toast.success(
              <>
                You have successfully allocated{' '}
                <CurrencyFormatter value={amount} /> to the application
              </>,
              { duration: APPLICATION_APPROVAL_TOAST_DURATION }
            )
          },
          onError: () => {
            toast.error(
              'There was an error allocating amount to the application',
              { duration: APPLICATION_APPROVAL_TOAST_DURATION }
            )
          },
        }
      )
    },
    [allocationMutation, application]
  )

  const RenderApplicationType = () => {
    switch (application.type) {
      case 'project_applications':
        const budget = fund.options.openGrants!.budget
        const budgetOptions = [
          application.requestedBudget,
          ...budget.options,
        ].filter((item) => item)
        const budgetOptionsUnique = [...new Set(budgetOptions)]
        const defaultAmount = budgetOptions[0]

        return enterCustomAmount ? (
          <>
            <div className="flex items-center gap-x-3">
              <ApplicationPaymentAccountsStatus
                paymentAccounts={
                  application?.location?.payments?.location.paymentAccounts
                }
              />
              <ApplicationApprovalRejectButton
                onClick={reject.mutate}
                isLoading={reject.isLoading}
                tooltip={APPLICATION_APPROVAL_REJECT_TOOLTIP}
              />
            </div>
            <div className="flex items-center gap-x-3">
              <ApplicationApprovalAllocateCustom
                onAllocate={(amount) => {
                  clickHandler(() => handleAllocate(amount))
                }}
                onCancel={() => setEnterCustomAmount(false)}
                min={budget.min}
                max={budget.max}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center gap-x-3">
              <ApplicationPaymentAccountsStatus
                paymentAccounts={
                  application?.location?.payments?.location.paymentAccounts
                }
              />
              <ApplicationApprovalRejectButton
                onClick={reject.mutate}
                isLoading={reject.isLoading}
                tooltip={APPLICATION_APPROVAL_REJECT_TOOLTIP}
              />
            </div>
            <div className="flex items-center gap-x-3">
              <ApplicationApprovalAcceptButtonWithDropdown
                acceptActionText="Allocate"
                amount={defaultAmount}
                isLoading={allocationMutation.isLoading}
                onClick={() => {
                  clickHandler(() => handleAllocate(defaultAmount))
                }}
                items={[
                  ...budgetOptionsUnique.map(
                    (amount): DropdownItem => ({
                      type: 'button',
                      id: `allocate-${amount}`,
                      onClick: () => clickHandler(() => handleAllocate(amount)),
                      title: (
                        <>
                          Allocate (<CurrencyFormatter value={amount} />)
                        </>
                      ),
                    })
                  ),
                  ...(budget.custom
                    ? [
                        {
                          type: 'button',
                          id: 'custom',
                          title: <>Custom amount</>,
                          onClick: () => setEnterCustomAmount(true),
                        } as const,
                      ]
                    : []),
                ]}
              />
            </div>
          </>
        )
      case 'solution_applications':
        return (
          <>
            <div className="flex items-center gap-x-3">
              <ApplicationPaymentAccountsStatus
                paymentAccounts={
                  application?.location?.payments?.location.paymentAccounts
                }
              />
              <ApplicationApprovalRejectButton
                onClick={reject.mutate}
                isLoading={reject.isLoading}
                tooltip={APPLICATION_APPROVAL_REJECT_TOOLTIP}
              />
            </div>
            <div className="flex items-center gap-x-3">
              <ApplicationApprovalAcceptButton
                amount={
                  application.location.payments?.location.budgets.preAllocated
                    .balance
                }
                onClick={() =>
                  clickHandler(() =>
                    handleAllocate(
                      application.location.payments?.location.budgets
                        .preAllocated.balance!
                    )
                  )
                }
                isLoading={allocationMutation.isLoading}
                acceptActionText="Allocate"
              />
            </div>
          </>
        )
    }
  }

  return (
    <>
      <RenderApplicationType />
      <ConfirmationModal
        open={open}
        onClick={allocateFn}
        setOpen={setOpen}
        text="Are you sure you want to allocate this amount? This action can't be undone."
        okText="Yes, allocate this amount"
      />
    </>
  )
}
