import { PaymentAccount } from '@features/funds/types'
import { PaymentsStatusDot } from '@features/funds/components/application-details/payments/payments-status-dot'
import { Text, WithTooltip } from '@changex/design-system'
import { utils } from '@features/funds/utils'
import React from 'react'

type Props = {
  paymentAccounts?: PaymentAccount[]
}

export function PaymentAccountStatus({ paymentAccounts }: Props) {
  const hasPaymentAccounts = paymentAccounts && paymentAccounts.length > 0

  if (!hasPaymentAccounts) {
    return (
      <div className="flex items-center gap-x-3">
        <PaymentsStatusDot status="notConnected" />
        <Text variant="light">No payment accounts</Text>
      </div>
    )
  }

  const PaymentAccountComp = (paymentAccount) => (
    <div className="flex items-center gap-x-3 gap-y-6">
      {paymentAccount.accountType === 'stripe'
        ? StripeComp(paymentAccount)
        : DefaultComp(paymentAccount)}
    </div>
  )

  const DefaultComp = (paymentAccount) => (
    <>
      <PaymentsStatusDot
        status={paymentAccount.status.enabled ? 'success' : 'failure'}
      />
      <Text>
        {utils.titleize(paymentAccount.accountType)}{' '}
        <small>(Reference: {paymentAccount.reference})</small>
      </Text>
    </>
  )

  const StripeComp = (paymentAccount) => (
    <>
      <PaymentsStatusDot
        status={paymentAccount.status.enabled ? 'success' : 'failure'}
      />
      <Text>
        Stripe{' '}
        <small>
          (
          <a
            href={`https://dashboard.stripe.com/connect/accounts/${paymentAccount.accountIdentifier}`}
            target="_blank"
            rel="noreferrer"
          >
            View Stripe
          </a>
          )
        </small>
      </Text>
    </>
  )

  return (
    <div className="flex flex-col gap-y-3">
      {paymentAccounts.map((paymentAccount, index) => {
        if (paymentAccount.status.description) {
          return (
            <div key={index}>
              <WithTooltip
                tooltip={paymentAccount.status.description}
                placement="right"
              >
                <span>{PaymentAccountComp(paymentAccount)}</span>
              </WithTooltip>
            </div>
          )
        }

        return <div key={index}>{PaymentAccountComp(paymentAccount)}</div>
      })}
    </div>
  )
}
