import { Skeleton as SkeletonDS } from '@changex/design-system'

const Skeleton = () => (
  <div
    className="boder-gray-200 flex justify-between rounded border border-t-0 p-6 first:border-t"
    data-testid="skeleton"
  >
    <div className="flex gap-x-4">
      <SkeletonDS h="44px" w="44px" r="100%" />
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-2">
          <SkeletonDS h="20px" w="150px" />
          <SkeletonDS h="20px" w="200px" />
        </div>
        <div className="flex flex-col gap-y-2">
          <SkeletonDS h="20px" w="220px" />
          <SkeletonDS h="20px" w="175px" />
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-y-3">
      <div className="flex gap-x-2">
        <SkeletonDS h="20px" w="50px" />
        <SkeletonDS h="20px" w="100px" />
      </div>
      <div className="flex gap-x-2">
        <SkeletonDS h="20px" w="90px" />
        <SkeletonDS h="20px" w="150px" />
      </div>
      <div className="flex gap-x-2">
        <SkeletonDS h="20px" w="70px" />
        <SkeletonDS h="20px" w="120px" />
      </div>
    </div>
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <SkeletonDS h="20px" w="50px" />
        <SkeletonDS h="20px" w="150px" />
      </div>
      <div className="flex flex-col gap-y-4">
        <SkeletonDS h="20px" />
        <SkeletonDS h="20px" w="85px" />
      </div>
    </div>
  </div>
)

export default Skeleton
