export const APPLICATION_APPROVAL_TOAST_DURATION = 5_000

export const APPLICATION_APPROVAL_REJECT_TOOLTIP =
  'Release seed and impact allocations'

export const APPLICATION_APPROVAL_REJECT_AND_REFUND_TOOLTIP =
  'Release seed and impact allocations, including paid'

export const APPLICATION_APPROVAL_FAIL_TOOLTIP =
  'Release impact allocation only'
