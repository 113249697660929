import {
  Icon,
  PhotoCardDetail,
  PhotoCardImage,
  PhotoCardWrapper,
} from '@changex/design-system'
import { useLikePhotoMutation } from '@features/funds/hooks/use-like-photo-mutation'
import { useUnlikePhotoMutation } from '@features/funds/hooks/use-unlike-photo-mutation'
import { useSolutionQuery } from '@features/funds/hooks/use-solutions-query'
import { utils } from '@features/funds/utils'
import { useState } from 'react'
import toast from 'react-hot-toast'

type PhotoCardProps = {
  id: string
  photoUrl: string
  postUrl: string
  locationName: string
  locationAddress: string
  createdAt: string
  isFavourite: boolean
  solutionId: number
  handleImageOnClick: React.MouseEventHandler<HTMLImageElement>
  isGridView: boolean
}

const PhotoCard: React.FC<PhotoCardProps> = ({
  id,
  photoUrl,
  postUrl,
  locationName,
  locationAddress,
  createdAt,
  isFavourite,
  solutionId,
  handleImageOnClick,
  isGridView,
}) => {
  const [favourite, setFavourite] = useState(isFavourite)
  const solutionsQuery: any = useSolutionQuery(solutionId)
  const idea: string = solutionsQuery.data?.results.name
  const solutionSlug: string = solutionsQuery.data?.results.slug

  const likePhoto = useLikePhotoMutation()
  const unlikePhoto = useUnlikePhotoMutation()

  const handleFavouriteClick = async (favourite: boolean, id: string) => {
    if (favourite) {
      try {
        await unlikePhoto.mutate({ id })
        setFavourite(false)
      } catch (error) {
        toast.error((error as Error).message)
      }
    } else {
      try {
        await likePhoto.mutate({ id })
        setFavourite(true)
      } catch (error) {
        toast.error((error as Error).message)
      }
    }
  }

  const linkDetails = [
    {
      label: 'Project',
      href: postUrl,
      text: locationName,
    },
    {
      label: 'Idea',
      href: utils.createLinkToIdea({
        solution: { slug: solutionSlug },
      }),
      text: idea,
    },
  ]

  return (
    <PhotoCardWrapper isGridView={isGridView}>
      <PhotoCardImage
        photoUrl={photoUrl}
        handleImageOnClick={handleImageOnClick}
      >
        <button
          aria-label={
            favourite ? 'Remove from favourites' : 'Add to favourites'
          }
          onClick={() => handleFavouriteClick(favourite, id)}
          className="absolute top-3 right-3 z-20 h-10 w-10 rounded-full bg-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        >
          <Icon
            className="flex justify-center text-yellow-400"
            icon="Star"
            size="sm"
            variant={`${favourite ? 'solid' : 'outline'}`}
          />
        </button>
      </PhotoCardImage>
      <PhotoCardDetail
        links={linkDetails}
        lightTexts={[locationAddress, createdAt]}
      />
    </PhotoCardWrapper>
  )
}

export default PhotoCard
