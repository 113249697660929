import { useMemo, useState } from 'react'
import { LoadingIcon, SlideOver } from '@changex/design-system'
import Application from './application'
import ApplicationDetails from '../application-details'
import Skeleton from './skeleton'
import { useApplicationList } from '@features/funds/hooks/use-application-list'
import classNames from 'classnames'

function ApplicationsList() {
  const [selectedApplicationId, setSelectedApplicationId] = useState<
    string | null
  >(null)
  const [slideOpen, setSlideOpen] = useState<boolean>(false)

  const handleApplicationClick = (application) => {
    setSelectedApplicationId(application.id)
    setSlideOpen(true)
  }

  const handleCloseSlide = () => {
    setSelectedApplicationId(null)
    setSlideOpen(false)
  }

  const applicationsQuery = useApplicationList()

  const selectedApplication = useMemo(
    () =>
      applicationsQuery.data?.results.find(
        ({ id }) => id === selectedApplicationId
      ),
    [applicationsQuery.data?.results, selectedApplicationId]
  )

  return (
    <div
      data-testid="applications-list"
      className={classNames(
        'relative',
        applicationsQuery.isFetching && 'opacity-80'
      )}
    >
      {applicationsQuery.isLoading ? (
        <div data-testid="applications-list-loader">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <div className="mt-5">
          {applicationsQuery.data?.results.map((application) => (
            <Application
              application={application}
              key={application.id}
              onClick={handleApplicationClick}
            />
          ))}
        </div>
      )}
      <SlideOver
        children={<ApplicationDetails.Body data={selectedApplication} />}
        open={slideOpen}
        title={<ApplicationDetails.Title data={selectedApplication} />}
        onClose={handleCloseSlide}
        size="lg"
      />
      {applicationsQuery.isFetching && (
        <div
          data-testid="applications-list-refreshing"
          className="absolute bottom-2 left-2 flex gap-x-2 bg-white p-2 text-sm opacity-80"
        >
          <LoadingIcon /> Applications list refreshing...
        </div>
      )}
    </div>
  )
}

export default ApplicationsList
