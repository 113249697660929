import { createContext, PropsWithChildren, useContext } from 'react'
import { User } from '../types'

const CurrentUserContext = createContext<User | null>(null)

// Make user globally available in the app
// Inspired by: https://stackoverflow.com/questions/74667427/how-to-use-react-query-to-replace-context/74674946#74674946
export function CurrentUserProvider({
  children,
  user,
}: PropsWithChildren<{ user: User }>) {
  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export function useCurrentUser() {
  const currentUser = useContext(CurrentUserContext)
  if (!currentUser) {
    throw new Error(
      'Please provide a user provider to use the current user data'
    )
  }

  return currentUser
}
