import { HeardAboutUsOption } from '@features/funds/types'

export function formatHeardAboutUs(heardAboutUs: HeardAboutUsOption) {
  switch (heardAboutUs) {
    case 'social_media':
      return 'Social media'
    case 'word_of_mouth':
      return 'Word of mouth'
    case 'newspaper_tv_radio':
      return 'Newspaper, tv or radio'
    case 'changex_email':
      return 'ChangeX email'
    case 'changex_newsletter':
      return 'Changex newsletter'
    case 'another_org':
      return 'Another org'
    case 'partner':
      return 'Partner'
    case 'other':
      return 'Other'
    case 'not_sure':
      return 'Not sure'
  }
}
