import { Icon, Text, SlideOver, Button, Divider } from '@changex/design-system'
import { useState } from 'react'
import marker from '../../../../assets/marker.png'
import openGrantMarker from '../../../../assets/open-grant-marker.png'
import { ClusterCircleMarker } from '../map-markers/map-markers'

const MapLegend = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClose = () => setIsOpen(false)

  const handleOpen = () => setIsOpen(true)

  return (
    <div className="flex items-center">
      <div onClick={handleOpen}>
        <Button weight="tertiary" className="border-0 font-light shadow-none">
          Map legend
          <Icon className="ml-2 text-gray-400" icon="SidebarOpen" size="sm" />
        </Button>
      </div>
      <SlideOver
        title="Map legend"
        children={
          <div className="flex flex-col gap-y-5">
            <div className="flex items-center gap-x-4">
              <div className="w-7">
                <img
                  className="transition duration-200 hover:-translate-y-2 hover:scale-110"
                  src={marker}
                  alt="marker"
                  width={22}
                />
              </div>
              <Text>Projects replicating ideas</Text>
            </div>

            <div className="flex items-center gap-x-4">
              <div className="w-7">
                <img
                  className="transition duration-200 hover:-translate-y-2 hover:scale-110"
                  src={openGrantMarker}
                  alt="marker"
                  width={29}
                />
              </div>
              <Text>Grant projects</Text>
            </div>

            <Divider />

            <div className="flex items-center gap-x-4">
              <div className="w-7">
                <ClusterCircleMarker size={'small'} diameter={6} />
              </div>
              <Text>Under 10 projects in the region</Text>
            </div>

            <div className="flex items-center gap-x-4">
              <div className="w-7">
                <ClusterCircleMarker size={'medium'} diameter={6} />
              </div>
              <Text>Over 10 projects in the region</Text>
            </div>

            <div className="flex items-center gap-x-4">
              <div className="w-7">
                <ClusterCircleMarker size={'large'} diameter={6} />
              </div>
              <Text>Over 50 projects in the region</Text>
            </div>
          </div>
        }
        open={isOpen}
        onClose={handleClose}
      />
    </div>
  )
}

export default MapLegend
