import { createContext, PropsWithChildren, useContext } from 'react'
import { FundWithAggregates } from '../types'

const FundContext = createContext<FundWithAggregates | null>(null)

// Make fund more or less globally in the app to avoid too much null checking
// Inspired by: https://stackoverflow.com/questions/74667427/how-to-use-react-query-to-replace-context/74674946#74674946
export function FundProvider({
  children,
  fund,
}: PropsWithChildren<{ fund: FundWithAggregates }>) {
  return <FundContext.Provider value={fund}>{children}</FundContext.Provider>
}

export function useFund() {
  const fund = useContext(FundContext)
  if (!fund) {
    throw new Error('Please provide a fund provider to use the fund data')
  }

  return fund
}
