import { httpClient } from '@api'

type TInput = {
  id: string
}

export function useUnlikePhotoMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.delete(`/likes/${input.id}?type=assets`)
    },
  }
}
