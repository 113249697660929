import { PropsWithChildren } from 'react'
import { useCurrentUser } from '../providers/current-user-provider'

export function AdminOnly({ children }: PropsWithChildren<{}>) {
  const user = useCurrentUser()

  if (user.userType === 'cx_admin') {
    return <>{children}</>
  }
  return null
}
