import { httpClient } from '@api'

type TInput = {
  body: string
  commentable_id: string
  type: 'post'
}

export function useCreatePostCommentMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.post('/comments', { data: { attributes: input } })
    },
  }
}
