import { ButtonGroup } from '@changex/design-system'
import { Dispatch } from 'react'

type TProps = {
  heatmapActive: boolean
  setHeatmapActive: Dispatch<React.SetStateAction<boolean>>
}

export function MapTabs({ heatmapActive, setHeatmapActive }: TProps) {
  return (
    <ButtonGroup
      items={[
        { label: 'Projects', onClick: () => setHeatmapActive(false) },
        {
          label: 'People impacted',
          onClick: () => setHeatmapActive(true),
          tooltipText:
            'Participants plus members of the community who have been impacted by and benefitted from the project. This data is usually shared by the applicant 2-6 months after they’ve first received funds.',
        },
      ]}
      initalActiveIndex={heatmapActive ? 1 : 0}
    />
  )
}
