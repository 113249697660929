import { Post } from '@changex/design-system'
import { FeedContentEvent } from '@features/funds/types'
import { useUserQuery } from '@features/funds/hooks/use-user-query'
import { utils } from '@features/funds/utils'
import dayjs from 'dayjs'

import { TFeedListItemProps } from './types'

type TProps = TFeedListItemProps<FeedContentEvent>

export default function FeedListItemEvent({ idea, item }: TProps) {
  const userQuery = useUserQuery(item.content.userId)
  const badge = utils.getBadgeStatus(utils.getStatus(item.state.toLowerCase()))
  const isOpenProject = String(item.solutionId) === '99'

  return (
    <Post
      meeting={{
        date: `${dayjs(item.content.eventStartAt).format(
          'MMM DD[,] YYYY [at] h:mm A'
        )} - ${dayjs(item.content.eventEndAt).format(
          'MMM DD[,] YYYY [at] h:mm A'
        )}`,
        location: item.content.locationName,
        title: item.content.eventName,
        link: utils.createLinkToEvent({
          location: { slug: item.content.locationSlug },
          solution: { slug: idea.slug },
          event: { slug: item.content.eventSlug },
        }),
      }}
      post={{
        country: item.content.countryName,
        date: dayjs(item.createdAt).format('MMM DD[,] YYYY [at] h:mm A'),
        header: idea.header,
        event: 'created an event in',
        location: {
          link: utils.createLinkToPage({
            location: { slug: item.content.locationSlug },
            solution: { slug: idea.slug },
          }),
          name: item.content.locationName,
        },
        status: {
          name: item.state,
          variant: badge === item.state || badge === '_' ? 'basic' : badge,
        },
        tag: {
          prefix: isOpenProject ? 'Open grant project' : 'Idea',
          link: isOpenProject
            ? undefined
            : utils.createLinkToIdea({ solution: { slug: idea.slug } }),
          name: isOpenProject ? undefined : idea.name,
        },
        user: {
          name: `${item.content.userFirstName} ${item.content.userLastName}`,
          picture: userQuery.data?.results.avatarUrl || '',
        },
      }}
      type="meeting"
    />
  )
}
