import React from 'react'
import { FundApplicationReplication } from '../../../types'
import ApplicationDetailsCall from '../application-details-call'
import ApplicationDetailsInfo from '../application-details-info'
import ApplicationDetailsNotes from '../application-details-notes'
import ApplicationDetailsSteps from '../application-details-steps'

import Divider from '../../../../../components/divider'
import { formatDate, Text } from '@changex/design-system'
import { ApplicationDetailsOverviewAttribute } from '@features/funds/components/application-details/application-details-overview-attribute'
import { ApplicationDetailsMembers } from '@features/funds/components/application-details/application-details-members'
import { ApplicationDetailsHeardAboutUs } from '@features/funds/components/application-details/application-details-heard-about-us'
import { AdminOnly } from '../../../../../shared/components/admin-only'
import ApplicationDetailsMotivation from '@features/funds/components/application-details/application-details-motivation'
import DetailsTable from '../../../../../components/details-table'
import ApplicationDetailsHighValueIntroductionEmail from '../application-details-high-value-introduction-email'

type Props = {
  data: FundApplicationReplication
}

function additionalDataRows(data?: Object) {
  if (data === undefined || data === null) return []

  return Object.keys(data).map((key) => (
    <>
      <Divider className="pb-8" />
      <DetailsTable
        header={key.charAt(0).toUpperCase() + key.substr(1).toLowerCase()}
        rowData={data[key].map((item) => ({
          label: item['question'],
          value: item['answer'],
        }))}
      />
    </>
  ))
}

export function ApplicationDetailsOverviewReplication({ data }: Props) {
  return (
    <>
      <ApplicationDetailsInfo data={data} />
      <Divider className="pb-10" />
      <ApplicationDetailsSteps data={data} />
      <Divider className="pb-8" />
      <ApplicationDetailsMotivation data={data} />
      <ApplicationDetailsNotes data={data} />
      <div className="mt-12 flex flex-col gap-y-4">
        <ApplicationDetailsMembers data={data} />
        {data.kickOffMeetingAt && (
          <ApplicationDetailsOverviewAttribute title="Kick-off Meeting">
            <Text weight="medium">{formatDate(data.kickOffMeetingAt)}</Text>
          </ApplicationDetailsOverviewAttribute>
        )}
        <ApplicationDetailsCall data={data} />
        <ApplicationDetailsHighValueIntroductionEmail data={data} />
        <AdminOnly>
          <Divider className="pb-8" />
          <ApplicationDetailsHeardAboutUs application={data} />
        </AdminOnly>
      </div>
      <DetailsTable
        header="Organization Details"
        rowData={[
          {
            label: 'Organization Name',
            value: data.organisationName || '',
          },
          {
            label: 'Type',
            value: data.entityType || '',
          },
        ]}
      />
      {additionalDataRows(data.additionalData)}
    </>
  )
}
