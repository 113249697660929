import { useFund } from '../../../shared/providers'
import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'
import { omitBy, isEmpty } from 'lodash'

export function usePhotosQuery<T>(filters = {}) {
  const fund = useFund()
  return useJsonApiQuery<T>([
    'fund_photos',
    omitBy(
      { 'filter[fund_id]': fund.id, ...filters, 'stats[total]': 'count' },
      isEmpty
    ),
  ])
}
