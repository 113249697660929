import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import { useFund, useCurrentUser } from '../../shared/providers'
import { TopNavigation } from '@changex/design-system'
import { useJsonApiQuery } from '../hooks/json-api/use-json-api-query'
import _ from 'lodash'
import { Organisation } from '../types'

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

const Menu = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentUser = useCurrentUser()
  const pagePath = pathname.substr(pathname.lastIndexOf('/') + 1)
  const fund = useFund()
  const organisationQuery = useJsonApiQuery<Organisation>([
    'organisations',
    {
      filter: {
        id: fund?.organisationId,
      },
      include: 'funds',
    },
  ])
  const organisationData = organisationQuery?.data?.results
  const funds = _.orderBy(
    organisationData?.funds.map((fund) => ({
      id: Number(fund.id),
      label: fund.name,
      route: `/funds/${fund.id}/${pagePath}`,
      type: fund.options.type,
      active: fund.isLive,
    })),
    ['id'],
    ['desc']
  )

  const handleNavigationClick = (route) => {
    if (route.startsWith('http')) {
      window.location.href = route
      return null
    }
    navigate(route)
  }

  const fundSettingsItems = [
    {
      active: !!matchPath(`/funds/${fund.id}/settings/team`, pathname),
      route: `/funds/${fund.id}/settings/team`,
      label: 'Team members',
    },
    {
      active: !!matchPath(
        `/funds/${fund.id}/settings/payment-history`,
        pathname
      ),
      route: `/funds/${fund.id}/settings/payment-history`,
      label: 'Payment history',
    },
  ]

  if (currentUser.userType === 'cx_admin') {
    fundSettingsItems.unshift({
      active: !!matchPath(`/funds/${fund.id}/settings/general`, pathname),
      route: `/funds/${fund.id}/settings/general`,
      label: 'General',
    })
  }

  const items = [
    {
      active: !!matchPath(`/funds/${fund.id}/applications`, pathname),
      route: `/funds/${fund.id}/applications`,
      label: 'Applications',
    },
    {
      active: !!matchPath(`/funds/${fund.id}/feed`, pathname),
      route: `/funds/${fund.id}/feed`,
      label: 'Impact Feed',
    },
    {
      active: !!matchPath(`/funds/${fund.id}/photos`, pathname),
      route: `/funds/${fund.id}/photos`,
      label: 'Photos',
    },
    {
      active: !!matchPath(`/funds/${fund.id}/map`, pathname),
      route: `/funds/${fund.id}/map`,
      label: 'Map',
    },
    {
      active: !!matchPath(`/funds/${fund.id}/dashboard`, pathname),
      route: `/funds/${fund.id}/dashboard`,
      label: 'Dashboard',
    },
    {
      active: false,
      route: '#',
      label: 'Fund settings',
      items: fundSettingsItems,
    },
  ]

  const parent = {
    url: `${CHANGEX_ADDRESS}/organisations/${organisationData?.slug}`,
    label: organisationData?.name,
  }

  return (
    <TopNavigation
      title={fund.name}
      ariaLabel="Fund"
      items={items}
      parent={parent}
      dropdownItems={funds}
      onNavigationClick={handleNavigationClick}
      landingPageUrl={`${CHANGEX_ADDRESS}/funds/${fund.slug}`}
    />
  )
}

export default Menu
