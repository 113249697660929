import { TAllocationType, FundApplication } from 'features/funds/types'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { APPLICATION_APPROVAL_TOAST_DURATION } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { useApplicationCreateManualPaymentMutation } from '@features/funds/hooks/use-application-create-payment-mutation'

export function useManuallyPayApplication(
  application: FundApplication,
  allocationType: TAllocationType
) {
  const manualPaymentMutation = useApplicationCreateManualPaymentMutation()

  const mutate = useCallback(() => {
    manualPaymentMutation.mutate(
      {
        allocation_type: allocationType,
        location_id: Number(application.location.id),
        fund_id: Number(application.fund.id),
      },
      {
        onSuccess: () => {
          toast.success('Successfully marked as paid manually', {
            duration: APPLICATION_APPROVAL_TOAST_DURATION,
          })
        },
        onError: () => {
          toast.error('There was an error marking as paid manually', {
            duration: APPLICATION_APPROVAL_TOAST_DURATION,
          })
        },
      }
    )
  }, [
    application.fund.id,
    application.location.id,
    allocationType,
    manualPaymentMutation,
  ])

  return {
    mutate,
    isLoading: manualPaymentMutation.isLoading,
  }
}
