import React, { Fragment, useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { StripeProvider } from '../../../../../../shared/providers/stripe-provider'
import {
  StripePaymentIntent,
  usePayWithStripe,
} from '@features/funds/components/applications-list/application/approval/hooks/use-pay-with-stripe'
import toast from 'react-hot-toast'
import { APPLICATION_APPROVAL_TOAST_DURATION } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { Button, Icon } from '@changex/design-system'
import { ErrorBoundary } from 'react-error-boundary'

type PaymentStepProps = {
  onCancel: () => void
  onSuccess: () => void
  paymentIntent: StripePaymentIntent
}
function PaymentStep({ onCancel, onSuccess, paymentIntent }: PaymentStepProps) {
  const payWithStripe = usePayWithStripe()

  const handlePayWithStripe = useCallback(() => {
    payWithStripe.mutate(paymentIntent, {
      onSuccess: (paymentResult) => {
        switch (paymentResult.type) {
          case 'success':
            onSuccess()
            return
          case 'error':
            toast.error(
              paymentResult.message ?? 'There was an error making this payment',
              {
                duration: APPLICATION_APPROVAL_TOAST_DURATION,
              }
            )
        }
      },
      onError: () => {
        toast.error('There was an error making this payment', {
          duration: APPLICATION_APPROVAL_TOAST_DURATION,
        })
      },
    })
  }, [onSuccess, payWithStripe, paymentIntent])

  return (
    <>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Proceed with payment
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Would you like to proceed with the payment?
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <Button weight="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handlePayWithStripe}>Confirm payment</Button>
      </div>
    </>
  )
}

type ConfirmedStepProps = {
  onContinue: () => void
}
function ConfirmedStep({ onContinue }: ConfirmedStepProps) {
  return (
    <>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            <div className="flex items-center justify-center gap-x-2">
              <Icon icon="CheckCircle" variant="solid" size="md" />
              Payment successful
            </div>
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              You have successfully made the payment
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 flex items-center justify-end">
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </>
  )
}

function ErrorFallbackComponent() {
  return <div>There was an error connecting with the payment provider</div>
}

type Props = {
  onClose: () => void
  paymentIntent: StripePaymentIntent
}

export function ApplicationApprovalConfirmPayment({
  onClose,
  paymentIntent,
}: Props) {
  // TODO don't close dialog when clicking outside

  const [isSuccess, setIsSuccess] = useState(false)

  return (
    <Transition.Root show as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {isSuccess ? (
                  <ConfirmedStep onContinue={onClose} />
                ) : (
                  // @ts-ignore - Error boundary is giving false TS errors
                  <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                    <StripeProvider
                      clientSecret={paymentIntent.clientSecret}
                      publicKey={paymentIntent.publicKey}
                    >
                      <PaymentStep
                        paymentIntent={paymentIntent}
                        onCancel={onClose}
                        onSuccess={() => setIsSuccess(true)}
                      />
                    </StripeProvider>
                  </ErrorBoundary>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
