import { Menu } from '@changex/design-system'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import { useCurrentUser, useFund } from '../../../../shared/providers'
import {
  CogIcon,
  UserGroupIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline'

const SideNavigation: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const fund = useFund()
  const handleNavigationClick = (route) => {
    if (route.startsWith('http')) {
      window.location.href = route
      return null
    }
    navigate(route)
  }
  const user = useCurrentUser()

  const items = [
    {
      active: !!matchPath(`/funds/${fund.id}/settings/team`, pathname),
      route: `/funds/${fund.id}/settings/team`,
      label: 'Team members',
      icon: UserGroupIcon,
    },
    {
      active: !!matchPath(
        `/funds/${fund.id}/settings/payment-history`,
        pathname
      ),
      route: `/funds/${fund.id}/settings/payment-history`,
      label: 'Payment history',
      icon: DocumentTextIcon,
    },
  ]
  return (
    <Menu className="flex-none" ariaLabel="Fund settings">
      <Menu.Header>Fund settings</Menu.Header>
      <Menu.Items>
        {user.userType === 'cx_admin' && (
          <Menu.NestedItems
            label="General"
            Icon={CogIcon}
            defaultOpen={
              !!matchPath(`/funds/${fund.id}/settings/general`, pathname) ||
              !!matchPath(`/funds/${fund.id}/settings/log`, pathname)
            }
          >
            <Menu.NavItem
              key={`/funds/${fund.id}/settings/general`}
              active={
                !!matchPath(`/funds/${fund.id}/settings/general`, pathname)
              }
              route={`/funds/${fund.id}/settings/general`}
              onNavigationClick={handleNavigationClick}
              nested
            >
              Basic details
            </Menu.NavItem>
            <Menu.NavItem
              key={`/funds/${fund.id}/settings/log`}
              active={!!matchPath(`/funds/${fund.id}/settings/log`, pathname)}
              route={`/funds/${fund.id}/settings/log`}
              onNavigationClick={handleNavigationClick}
              nested
            >
              Changelog
            </Menu.NavItem>
          </Menu.NestedItems>
        )}
        {items.map((item) => (
          <Menu.NavItem
            key={item.route}
            active={item.active}
            route={item.route}
            onNavigationClick={handleNavigationClick}
            Icon={item.icon}
          >
            {item.label}
          </Menu.NavItem>
        ))}
      </Menu.Items>
    </Menu>
  )
}

export default SideNavigation
