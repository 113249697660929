import { Button, Text } from '@changex/design-system'
import { useApplicationSendHighValueIntroductionEmailMutation } from 'features/funds/hooks/use-application-send-high-value-introduction-email-mutation'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { ApplicationDetailsOverviewAttribute } from 'features/funds/components/application-details/application-details-overview-attribute'
import { ConfirmationModal } from 'components/confirmation-modal'
import { FundApplication } from 'features/funds/types'
import { TVariant } from '@types'
import { useFund } from 'shared/providers'

type Props = {
  data?: FundApplication | null
}

function ApplicationDetailsHighValueIntroductionEmail({ data }: Props) {
  const fund = useFund()
  const [introductionEmailSent, setIntroductionEmailSent] = useState<boolean>(
    data?.introductionEmailSent || false
  )
  const [open, setOpen] = useState(false)
  const clickHandler = () => {
    setOpen(!open)
  }

  const sendApplicationSendHighValueIntroductionEmail =
    useApplicationSendHighValueIntroductionEmailMutation()

  const handleSendIntroductionEmailButtonClick = () => {
    sendApplicationSendHighValueIntroductionEmail.mutate(
      {
        solutionApplicationId: data?.id as string,
        type: 'solution_application/introduction_emails',
      },
      {
        onError: () => {
          toast.error('There was an error sending the introduction email.')
        },
        onSuccess: () => {
          setIntroductionEmailSent(true)
          toast.success('The introduction email has been sent.')
        },
      }
    )
  }

  if (fund.options.variant !== TVariant.HIGH_VALUE) return null

  return (
    <>
      <ApplicationDetailsOverviewAttribute title="Introduction email sent">
        <div>
          <Text weight="medium">{introductionEmailSent ? 'Yes' : 'No'}</Text>
        </div>
        {!introductionEmailSent ? (
          <div className="mt-1.5">
            <Button
              className="!py-1.5 !px-2.5"
              weight="tertiary"
              onClick={clickHandler}
              disabled={sendApplicationSendHighValueIntroductionEmail.isLoading}
            >
              Send introduction email to Starter & SI
            </Button>
          </div>
        ) : null}
      </ApplicationDetailsOverviewAttribute>
      <ConfirmationModal
        open={open}
        onClick={handleSendIntroductionEmailButtonClick}
        setOpen={setOpen}
        text="Are you sure you want to send the introduction email to both Starter & SI? If you proceed the email will be sent in the next 60 seconds."
        okText="Yes"
      />
    </>
  )
}

export default ApplicationDetailsHighValueIntroductionEmail
