import { FundApplicationReplication } from '../../../types'
import { ApplicationInfoDefinitionList } from '@features/funds/components/applications-list/application/application-info-definition-list'
import { ApplicationInfoStatusBadge } from '@features/funds/components/applications-list/application/application-info-status-badge'
import { utils } from '@features/funds/utils'

type Props = {
  data: FundApplicationReplication
}

function ApplicationInfoReplication({ data }: Props) {
  return (
    <ApplicationInfoDefinitionList
      definitions={[
        {
          term: 'Status',
          definition: (
            <ApplicationInfoStatusBadge status={data.applicationStatus} />
          ),
          testId: 'status',
        },
        {
          term: 'Idea',
          definition: data.solution.name,
          link: utils.createLinkToIdea(data),
        },
        {
          term: 'Project',
          definition: data.location.name,
          testId: 'project',
          link: utils.createLinkToPage(data),
        },
      ]}
    />
  )
}

export default ApplicationInfoReplication
