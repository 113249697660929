import { useQuery } from '@tanstack/react-query'
import { parseQueryArg } from './json-api.utils'
import { StringMap } from 'jsonapi-react'
import { useJsonApiConfig } from './json-api-provider'
import { JSON_API_QUERY_KEY_ROOT } from './json-api.constants'

export type Result<TData = StringMap | StringMap[]> = {
  results: TData
  meta?: StringMap
}

type QueryArg<TQueryParams = any> = [
  type: string,
  queryParams?: TQueryParams,
  ...queryKeys: any[]
]

export function useJsonApiQuery<TData, TError = unknown>(queryArg: QueryArg) {
  const { serializer, query } = useJsonApiConfig()

  return useQuery<Result<TData>, TError>(
    [JSON_API_QUERY_KEY_ROOT, ...queryArg],
    async (): Promise<Result<TData>> => {
      const { url } = parseQueryArg(queryArg)
      if (!url) {
        throw new Error('Url required')
      }

      return query(url).then((data) => serializer.deserialize(data))
    }
  )
}
