export const StarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43}
    height={41}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M20.049 2.927c.3-.921 1.603-.921 1.902 0l3.09 9.51a1 1 0 0 0 .951.692h10c.97 0 1.372 1.24.588 1.809l-8.09 5.878a1 1 0 0 0-.363 1.118l3.09 9.51c.3.922-.755 1.688-1.539 1.118l-8.09-5.878a1 1 0 0 0-1.176 0l-8.09 5.878c-.784.57-1.838-.196-1.539-1.118l3.09-9.51a1 1 0 0 0-.363-1.118l-8.09-5.878c-.784-.57-.381-1.81.588-1.81h10a1 1 0 0 0 .95-.69l3.09-9.51Z"
    />
    <path
      fill="#DB2777"
      fillRule="evenodd"
      d="m26.719 11.129-2.866-8.82c-.898-2.764-4.808-2.764-5.706 0l-2.866 8.82H6.008c-2.907 0-4.115 3.719-1.764 5.427l7.503 5.45-2.866 8.82c-.898 2.764 2.265 5.063 4.617 3.354L21 28.73l7.503 5.45c2.35 1.709 5.514-.59 4.616-3.354l-2.866-8.82 7.503-5.45c2.35-1.709 1.143-5.427-1.764-5.427H26.72ZM21.95 2.927c-.3-.921-1.603-.921-1.902 0l-3.09 9.51a1 1 0 0 1-.951.692h-10c-.97 0-1.372 1.24-.588 1.809l8.09 5.878a1 1 0 0 1 .363 1.118l-3.09 9.51c-.3.922.755 1.688 1.539 1.118l8.09-5.878a1 1 0 0 1 1.176 0l8.09 5.878c.784.57 1.838-.196 1.539-1.118l-3.09-9.51a1 1 0 0 1 .363-1.118l8.09-5.878c.784-.57.381-1.81-.588-1.81h-10a1 1 0 0 1-.95-.69l-3.09-9.51Z"
      clipRule="evenodd"
    />
    <path
      stroke="#DB2777"
      strokeLinecap="round"
      strokeWidth={2}
      d="m32 4 2.828-2.828M9.828 4 7 1.172M37.459 24.76l3.594 1.754M21 36v4M5.054 24.76l-3.595 1.754"
    />
  </svg>
)
