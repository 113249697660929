import { useCallback } from 'react'
import { useFundCurrency } from './use-fund-currency'
import { formatCurrency } from '@changex/design-system'

export function useCurrencyFormatter() {
  const currency = useFundCurrency()
  return useCallback(
    (value: number) => formatCurrency(value, currency),
    [currency]
  )
}
