import { FundApplication } from 'features/funds/types'
import { ApplicationApprovalStageSuccessTick } from './application-approval-stage-success-tick'
import { CurrencyFormatter } from 'shared/currency/components/currency-formatter'
import { utils } from 'features/funds/utils'

export function ApplicationApprovalStageSuccessTickSolution({
  application,
}: {
  application: FundApplication
}) {
  if (utils.solutionAllocationPaid(application.location.payments?.solution)) {
    return (
      <ApplicationApprovalStageSuccessTick>
        Social innovator paid (
        <CurrencyFormatter
          value={
            application.location.payments?.solution?.budgets.paid.balance ?? 0
          }
        />
        )
      </ApplicationApprovalStageSuccessTick>
    )
  }
  return null
}
