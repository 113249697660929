import SideNavigation from 'features/funds/components/settings/side-navigation'
import Layout from 'app/funds/[id]/layout'
import toast from 'react-hot-toast'
import {
  ArrowNarrowDownIcon,
  DocumentTextIcon,
  EmojiSadIcon,
} from '@heroicons/react/outline'
import Payment from 'features/funds/components/payment-history/payment'
import { usePaymentsQuery } from 'features/funds/hooks/use-payments-query'
import { usePaymentDownload } from 'features/funds/hooks/use-payment-download'
import { useFund } from 'shared/providers'
import { Button, Skeleton, Pagination } from '@changex/design-system'
import { useCallback, useState } from 'react'

export const PAGE_SIZE = 15
const ERROR_DOWNLOADING_MESSAGE = 'There was an error requesting the download'

const LoadingSkeleton = () => {
  return (
    <div className="flex flex-col divide-y divide-gray-300">
      <Skeleton h="50px" />
      <div className="flex flex-col divide-y divide-gray-200">
        {[...Array(PAGE_SIZE)].map((_, index) => (
          <LoadingRow key={index} />
        ))}
      </div>
    </div>
  )
}

const LoadingRow = () => {
  return (
    <div className="grid grid-cols-8 gap-6">
      <Skeleton className="col-span-1 my-5" />
      <Skeleton className="col-span-5 my-5" />
      <Skeleton className="col-span-2 my-5" />
    </div>
  )
}

const EmptyState = () => {
  return (
    <div className="mt-32 flex flex-col items-center">
      <DocumentTextIcon className="h-12 w-12 text-gray-400" />
      <p className="mt-2 text-lg font-semibold text-gray-900">No payments</p>
      <p className="mt-1 text-gray-700 opacity-70">
        No payments have been made for this fund yet
      </p>
    </div>
  )
}

const ErrorState = () => {
  return (
    <div className="mt-32 flex flex-col items-center">
      <EmojiSadIcon className="h-12 w-12 text-gray-400" />
      <p className="mt-2 text-lg font-semibold text-gray-900">
        Something went wrong
      </p>
      <p className="mt-1 text-gray-700 opacity-70">
        There was an error while trying to fetch the payments
      </p>
    </div>
  )
}

const DownloadButton = () => {
  const fund = useFund()
  const paymentDownloadMutation = usePaymentDownload()

  const handleDownload = useCallback(() => {
    paymentDownloadMutation.mutate(
      {
        fundId: fund.id,
      },
      {
        onSuccess: () => {
          toast.success(
            'An export of payments will be emailed to you in the next few minutes.',
            { duration: 5000 }
          )
        },
        onError: () => {
          toast.error(ERROR_DOWNLOADING_MESSAGE, { duration: 5000 })
        },
      }
    )
  }, [paymentDownloadMutation, fund])

  return (
    <Button
      className="font-bold"
      disabled={paymentDownloadMutation.isLoading}
      onClick={handleDownload}
      weight="tertiary"
    >
      Download
    </Button>
  )
}

const PaymentHistory = () => {
  const fund = useFund()
  const [page, setPage] = useState(1)
  const {
    isLoading,
    error,
    data: queryData,
  } = usePaymentsQuery(fund.id, true, {
    size: PAGE_SIZE,
    number: page,
  })
  const totalPayments = queryData?.meta?.stats?.total?.count
  const hasMultiplePages = totalPayments && totalPayments > PAGE_SIZE

  if (isLoading) {
    return <LoadingSkeleton />
  }

  if (!!error) {
    return <ErrorState />
  }

  if (!queryData?.results || !(queryData.results.length > 0)) {
    return <EmptyState />
  }

  return (
    <div className="w-full">
      <table className="w-full divide-y divide-gray-300">
        <thead>
          <tr className="border-t border-gray-100 bg-gray-50">
            <th className="px-3 py-4 pl-4 text-left text-base font-medium">
              <span className="flex items-center gap-1">
                Date
                <ArrowNarrowDownIcon className="h-4 w-4 text-gray-400" />
              </span>
            </th>
            <th className="sr-only px-3 py-4 text-left text-base font-medium">
              Status
            </th>
            <th className="px-3 py-4 text-left text-base font-medium">
              Reference
            </th>
            <th className="px-3 py-4 text-left text-base font-medium">
              Project
            </th>
            <th className="px-3 py-4 text-left text-base font-medium">Idea</th>
            <th className="px-3 py-4 text-left text-base font-medium">
              Approvers
            </th>
            <th className="sr-only px-3 py-4 text-left text-base font-medium">
              Provider
            </th>
            <th className="px-3 py-4 text-left text-base font-medium">Type</th>
            <th className="px-3 py-4 pr-4 text-right text-base font-medium">
              Amount
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {queryData.results.map((payment) => (
            <Payment key={payment.id} {...payment} />
          ))}
        </tbody>
      </table>
      {hasMultiplePages && (
        <div className="mt-4">
          <Pagination
            onFiltering={(filter) => setPage(filter.page as number)}
            currentPage={page}
            meta={queryData?.meta}
            resultsPerPage={PAGE_SIZE}
          />
        </div>
      )}
    </div>
  )
}

export default function PaymentHistoryPage() {
  return (
    <Layout>
      <div className="flex min-h-screen">
        <SideNavigation />
        <main className="flex flex-grow flex-col gap-6 p-6">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl">Payment history</h1>
            <DownloadButton />
          </div>
          <PaymentHistory />
        </main>
      </div>
    </Layout>
  )
}
