import { useJsonApiQuery } from './json-api/use-json-api-query'
import { PaymentPlatformAccount } from '../types'

export function usePaymentPlatformAccountsQuery() {
  return useJsonApiQuery<
    PaymentPlatformAccount[],
    {
      response: { status: number }
    }
  >(['payment_platform_accounts'])
}
