import { Text } from '@changex/design-system'
import React from 'react'

type TProps = {
  header: string
  rowData: {
    label: string
    value: string
  }[]
}

const DetailsTable: React.FC<TProps> = ({ header, rowData }) => (
  <div className="mt-6 flex flex-col">
    <Text size="lg" variant="dark" className="bold mb-6 px-4 text-lg">
      {header}
    </Text>
    <div className="flex w-full flex-col">
      {rowData.map((row, index) => (
        <div
          key={index}
          className={`flex w-full py-4 ${
            index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
          }`}
        >
          <div className="w-1/4 py-2 px-4">
            <Text variant="light">{row.label}</Text>
          </div>
          <div className="w-3/4 py-2 px-4">
            <Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: row.value,
                }}
              />
            </Text>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default DetailsTable
