import { Checkbox, Icon, Selector, TreeSelector } from '@changex/design-system'
import { Nullable, Solution } from '@types'
import { constants } from '../../constants'

type TProps = {
  filters: Record<string, Nullable<string>>
  onFilter: (name: string, value: string) => void
  onReset: () => void
  solutions: Solution[]
}

export function PhotoFilters({
  filters,
  onFilter,
  onReset,
  solutions,
}: TProps) {
  return (
    <div className="flex flex-wrap items-center gap-x-6">
      <Selector
        filterOptions={{
          enabled: true,
          placeholder: 'Filter ideas by name',
        }}
        onChange={(value) => onFilter('filter[solution_id]', value)}
        options={[
          { id: '', label: 'Idea' },
          ...solutions.map((solution) => ({
            id: String(solution.id),
            label: solution.name,
          })),
        ]}
        value={filters['filter[solution_id]'] ?? ''}
      />
      <TreeSelector
        title={'Status'}
        options={constants.stateCategories}
        value={
          filters['filter[state]'] && filters['filter[state]'] !== ''
            ? filters['filter[state]']?.split(',')
            : []
        }
        onChange={(value) => {
          onFilter('filter[state]', value.join(','))
        }}
      />
      <Checkbox
        id="show-favourites"
        checked={filters['filter[is_favourite]'] === 'true'}
        label="Show favourites"
        onChange={() => {
          onFilter(
            'filter[is_favourite]',
            filters['filter[is_favourite]'] === 'true' ? '' : 'true'
          )
        }}
      />
      {Object.entries(filters).filter(
        ([key, value]) => Boolean(value) && key !== 'page[number]'
      ).length > 0 ? (
        <button
          className="flex items-center text-sm font-medium text-red-600"
          onClick={() => {
            onReset()
          }}
        >
          <Icon icon="XIcon" size="xs" />
          Clear filters
        </button>
      ) : null}
    </div>
  )
}
