import { Badge, Text } from '@changex/design-system'
import React from 'react'
import { FundApplication } from '../../types'
import Step from './step'

type TProps = {
  data?: FundApplication | null
}

const ApplicationDetailsSteps: React.FC<TProps> = ({ data }) => {
  if (!data) {
    return null
  }

  return (
    <div data-testid="application-detail-steps">
      <div className="flex justify-between">
        <Text size="sm" variant="light" upper>
          Steps
        </Text>
        <Badge variant="success">
          <Text size="xs" variant="light" upper>
            {data?.challengeDaysRemaining} days left
          </Text>
        </Badge>
      </div>
      <div className="mt-6 flex gap-8">
        {data?.onboardingSteps?.map((step, index) => (
          <Step
            completed={
              data?.completedOnboardingSteps?.find(
                (st) => st.onboardingStepId === Number(step.id)
              )?.completed
            }
            name={step.stepType}
            position={index + 1}
            key={step.id}
            locationId={data?.location.id!}
            stepId={`${step.id}`}
          />
        ))}
      </div>
    </div>
  )
}

export default ApplicationDetailsSteps
