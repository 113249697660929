import { Divider } from '@changex/design-system'
import { FundApplication } from '../../../types'
import PaymentsSection from './payments-section'
import { utils } from '@features/funds/utils'

type Props = {
  data?: FundApplication | null
}

function ApplicationDetailsPaymentsTab({ data }: Props) {
  const solutionAllocation = data?.location?.payments?.solution

  if (!data) {
    return null
  }

  return (
    <div className="pt-8">
      <PaymentsSection
        paymentData={data?.location?.payments}
        title="Applicant"
      />
      <Divider size={8} />
      {utils.isReplicationApplication(data) && solutionAllocation ? (
        <PaymentsSection
          paymentData={data?.location?.payments}
          title="Social innovator"
          isSocialInnovator
        />
      ) : null}
    </div>
  )
}

export default ApplicationDetailsPaymentsTab
