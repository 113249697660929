import { FundWithAggregates } from '../types'
import { useJsonApiQuery } from './json-api/use-json-api-query'

export function useFundQuery(fundId?: string) {
  return useJsonApiQuery<FundWithAggregates, { response: { status: number } }>([
    'funds',
    fundId,
    {
      'extra_fields[funds]': ['aggregates', 'templates'],
      include: 'solutions',
    },
  ])
}
