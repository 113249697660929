import { NumberParam, StringParam, useQueryParams } from 'use-query-params'

export const useApplicationsQueryParams = () =>
  useQueryParams({
    q: StringParam,
    sort: StringParam,
    page: NumberParam,
    tab: NumberParam,
    solutionId: StringParam,
  })
