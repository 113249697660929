import { PropsWithChildren } from 'react'

export function PaymentsLabelAndValue({
  label,
  size = 'normal',
  children,
}: PropsWithChildren<{ label: string; size?: string }>) {
  return (
    <div
      className={`flex flex-1 flex-col gap-y-2 ${
        size === 'small' ? 'flex-none' : ''
      }`}
    >
      <h3 className="text-base">{label}</h3>
      <div className="flex items-center gap-x-1">{children}</div>
    </div>
  )
}
