import { Alert, Button, LoadingIcon, Text } from '@changex/design-system'
import React, { useState } from 'react'
import TextOverflow from '../text-overflow'
import { FundApplication } from '../../types'
import { useApplicationUpdateMutation } from '@features/funds/hooks/use-application-update-mutation'
import toast from 'react-hot-toast'
import classNames from 'classnames'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import styled from 'styled-components'
import { useFund } from './../../../../../src/shared/providers'
import { UserFundRole } from '@features/funds/types'

const editorConfig = {
  toolbar: [
    'bold',
    'italic',
    '|',
    'numberedList',
    'bulletedList',
    '|',
    'undo',
    'redo',
    '|',
    'link',
  ],
}

const EditorWrapper = styled.div`
  ul,
  ol {
    margin: 0;
    padding-left: 35px;
    list-style-type: initial;
  }
  ol {
    list-style-type: decimal;
  }
  strong {
    font-weight: 500;
  }
`

type Props = {
  data?: FundApplication | null
}

function ApplicationDetailsNotes({ data }: Props) {
  const fund = useFund()
  const [editingChangexNotes, setEditingChangexNotes] = useState<boolean>(false)
  const [changexNotes, setChangexNotes] = useState<string>(
    data?.changexNotes || ''
  )

  const fundQuestions = fund?.templates?.adminQuestions
  const solutionQuestions = data?.solution?.templates?.adminQuestions?.solution
  const applicationNotes = [
    fundQuestions?.default,
    solutionQuestions,
    fundQuestions?.fund,
  ]
    .filter((n) => n)
    .join('')

  const updateApplicationMutation = useApplicationUpdateMutation()

  const handleNotesButtonClick = () => {
    setEditingChangexNotes((oldState) => !oldState)

    if (editingChangexNotes) {
      // Save to backend
      if (!data) {
        return
      }
      updateApplicationMutation.mutate(
        {
          id: data.id + '',
          type: data.type,
          changexNotes,
        },
        {
          onError: () => {
            toast.error('There was an error updating the note')
          },
          onSuccess: () => {
            toast.success('Note updated successfully')
          },
        }
      )
    }
  }

  return (
    <div className="mt-8">
      <Alert>
        <div>
          <div className="mb-2">
            <h4 className="text-xs uppercase text-gray-700 opacity-70">
              Social innovator notes
            </h4>
          </div>
          <div className="mb-3">
            <Text>
              <TextOverflow>{data?.teamNotes}</TextOverflow>
            </Text>
          </div>
        </div>
        <div className="mt-10">
          <div className="mb-2 flex items-center justify-between">
            <h4 className="text-xs uppercase text-gray-700 opacity-70">
              Changex notes
            </h4>
          </div>
          <EditorWrapper className="mb-3">
            <div className="bg-red relative mb-3">
              {editingChangexNotes ? (
                <CKEditor
                  editor={ClassicEditor}
                  data={changexNotes || applicationNotes}
                  config={editorConfig}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setChangexNotes(data)
                  }}
                  data-testid="note-editor"
                />
              ) : (
                <Text
                  className={classNames(
                    updateApplicationMutation.isLoading && 'opacity-50'
                  )}
                >
                  {changexNotes ? (
                    <TextOverflow>{changexNotes}</TextOverflow>
                  ) : (
                    <>No notes yet.</>
                  )}
                </Text>
              )}
            </div>
          </EditorWrapper>
          {fund.userRole === UserFundRole.Admin ? (
            <div>
              <Button
                className="!py-1.5 !px-2.5"
                weight="tertiary"
                onClick={handleNotesButtonClick}
                disabled={updateApplicationMutation.isLoading}
              >
                {(() => {
                  if (updateApplicationMutation.isLoading) {
                    return 'Saving...'
                  }
                  if (changexNotes && !editingChangexNotes) {
                    return 'Edit'
                  }
                  if (editingChangexNotes) {
                    return 'Save'
                  }
                  return 'Add'
                })()}{' '}
                note
                {updateApplicationMutation.isLoading && (
                  <span className="ml-4">
                    <LoadingIcon />
                  </span>
                )}
              </Button>
              {editingChangexNotes && (
                <Button
                  className="ml-2 !py-1.5 !px-2.5"
                  weight="tertiary"
                  onClick={() => {
                    setEditingChangexNotes(false)
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </Alert>
    </div>
  )
}

export default ApplicationDetailsNotes
