import { useEffect, useState } from 'react'

import { useUserMeQuery } from './use-user-me-query'

export function useFeedback(dependencies) {
  const [feedback, setFeedback] = useState<string>('Loading...')
  const userQuery = useUserMeQuery()

  useEffect(() => {
    if (userQuery.error) {
      if (userQuery.error.response.status === 401) {
        setFeedback('You are being redirected to login page...')
        setTimeout(() => {
          const currentUrl = window.location.href
          window.location.href = `${process.env['REACT_APP_CHANGEX_ADDRESS']}/users/sign_in?return_to=${currentUrl}`
        }, 1500)
      }
    }
    // eslint-disable-next-line
  }, [userQuery.error, ...dependencies])

  return { feedback }
}
