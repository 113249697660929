import { Badge, Text } from '@changex/design-system'
import React from 'react'
import { FundApplication } from '../../types'
import { utils } from '../../utils'

type TProps = {
  data?: FundApplication | null
}

const ApplicationDetailsInfo: React.FC<TProps> = ({ data }) => {
  const status = data?.applicationStatus || 'n/a'
  return (
    <div className="mt-3.5 flex justify-between gap-8">
      <div className="flex flex-col">
        <Text size="xs" variant="light" upper>
          Status
        </Text>
        <div className="mt-3 flex items-center gap-x-1">
          <Badge variant={utils.getBadgeStatus(status)}>
            {utils.getStatusLabel(status)}
          </Badge>
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <Text size="xs" variant="light" upper>
          Idea
        </Text>
        <Text className="mt-3" size="sm">
          {data?.type === 'solution_applications' ? (
            <a
              href={utils.createLinkToIdea(data)}
              rel="noreferrer"
              target="_blank"
              className="hover:underline"
            >
              {data?.solution?.name}
            </a>
          ) : (
            'Open grant application'
          )}
        </Text>
      </div>
      <div className="flex flex-1 flex-col">
        <Text size="xs" variant="light" upper>
          Location
        </Text>
        <Text className="mt-3" size="sm">
          {data?.location.address}
        </Text>
      </div>
      <div className="flex flex-1 flex-col">
        <Text size="xs" variant="light" upper>
          Project
        </Text>
        <Text className="mt-3" size="sm">
          <a
            href={utils.createLinkToPage(data)}
            rel="noreferrer"
            target="_blank"
            className="hover:underline"
          >
            {data?.location.name}
          </a>
        </Text>
      </div>
    </div>
  )
}

export default ApplicationDetailsInfo
