import { TAllocationType, FundApplication } from 'features/funds/types'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { APPLICATION_APPROVAL_TOAST_DURATION } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { useApplicationCreatePaymentMutation } from '@features/funds/hooks/use-application-create-payment-mutation'

type ErrorResponse = {
  response: {
    data: {
      errors: string
    }
  }
}

export function usePayApplication(
  application: FundApplication,
  allocationType: TAllocationType
) {
  const paymentMutation = useApplicationCreatePaymentMutation()
  const solutionPayment = allocationType === TAllocationType.Solution

  const mutate = useCallback(
    (paymentAccount) => {
      paymentMutation.mutate(
        {
          allocation_type: allocationType,
          payment_account_id: Number(paymentAccount.id),
          reference_location_id: solutionPayment
            ? Number(application.location.id)
            : null,
        },
        {
          onSuccess: () => {
            toast.success(
              `Successfully paid application ${
                solutionPayment ? 'SI' : 'application'
              }`,
              { duration: APPLICATION_APPROVAL_TOAST_DURATION }
            )
          },
          onError: (error) => {
            toast.error(
              `There was an error paying this ${
                solutionPayment ? 'SI' : 'application'
              }: ${(error as ErrorResponse).response.data.errors}`,
              { duration: APPLICATION_APPROVAL_TOAST_DURATION }
            )
          },
        }
      )
    },
    [application.location.id, allocationType, solutionPayment, paymentMutation]
  )

  return {
    mutate,
    isLoading: paymentMutation.isLoading,
  }
}
