import { LineGraph } from '@changex/design-system'
import { TApplicationsOverTime } from 'shared/types/application-analytics.type'
import dayjs from 'dayjs'

type TProps = {
  data: TApplicationsOverTime
}

const formatLabels = (labels) => {
  return labels.map((label) => {
    return dayjs(label, 'YYYYMMDD').format('D MMM YY')
  })
}

const formatValues = (values) => {
  let runningTotal = 0
  return values.map((value) => {
    runningTotal += value
    return runningTotal
  })
}

const ApplicationsOverTime: React.FC<TProps> = ({ data }) => {
  const labels = formatLabels(Object.keys(data))
  const values = formatValues(Object.values(data))
  return (
    <div className="flex flex-col rounded bg-white p-4">
      <h3 className="text-lg text-gray-800">Applications over time</h3>

      <div className="mx-4 mb-8 mt-12">
        <LineGraph labels={labels} data={values} />
      </div>
    </div>
  )
}

export default ApplicationsOverTime
