import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Converts the input date time into the value in the specified time zone but
 * then removes the timezone data.
 * If the timezone is included, the date picker will display the wrong time since it will
 * convert the time to the local time zone. To get around this, we just ignore the local time zone when
 * converting the time back to the given timezone in adjustDateTimeForSubmission.
 */
export const adjustDateTimeForDatePicker: (
  datetime: string | undefined | null,
  timeZone: string
) => Date | null = (datetime, timeZone = 'Etc/UTC') => {
  if (!datetime) return null

  return new Date(
    dayjs.utc(datetime).tz(timeZone).format('YYYY-MM-DD HH:mm:ss.SSS')
  )
}

/**
 * Parses the input date time in the specified time zone and then converts to a
 * UTC string.
 * @returns The date time in the format of 'YYYY-MM-DDTHH:mm:ss.SSSZ' (ISO 8601).
 */
export const adjustDateTimeForSubmission: (
  datetime: Date | null,
  timeZone: string
) => string | null = (datetime, timeZone = 'Etc/UTC') => {
  if (!datetime) return ''

  return dayjs
    .tz(dayjs(datetime).format('YYYY-MM-DD HH:mm:ss.SSS'), timeZone) // We need to ignore the local time zone (and treat it as the given time zone) so we remove it using format
    .utc()
    .toISOString()
}
