import {
  Avatar,
  Divider,
  Icon,
  Text,
  formatCurrency,
} from '@changex/design-system'
import { LocationMapApplication } from '@features/funds/types'
import { Solution } from '@types'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { Badge } from '@changex/design-system'
import { utils } from '@features/funds/utils'

const dateFormat = 'MMM D, YYYY'

type MapCardProps = {
  application: LocationMapApplication
  solution: Solution
  open: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  lng?: number
  lat?: number
}

const MapCard: React.FC<MapCardProps> = ({
  application,
  solution,
  open,
  setIsOpen,
}) => {
  return open ? (
    <div
      className="absolute bottom-3 left-[calc(-50%-11rem)] z-10 w-96 rounded-lg bg-white shadow-lg
        after:absolute after:right-[186px] after:border-t-[11px] after:border-r-[11px] after:border-l-[11px] after:border-b-0
        after:border-l-transparent after:border-r-transparent after:border-t-white after:bg-transparent"
    >
      <div>
        <img
          className="w-full rounded-t-lg"
          src={solution.headerImageUrl}
          alt="headerImage"
        />

        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-3 right-3 z-20 h-10 w-10 rounded-full bg-white"
        >
          <Icon className="flex justify-center" icon={'XIcon'} size="sm" />
        </button>

        <div className="relative -mt-3 rounded-lg bg-white px-7 py-4">
          <a
            target="_blank"
            href={`https://changex.org/gl/${solution.slug}/${application.locationSlug}`}
            rel="noreferrer"
          >
            <Text variant="success-dark" weight="bold">
              {application.name}
            </Text>
          </a>

          <div className="-my-2">
            <Divider />
          </div>

          <div className="flex justify-between">
            <div className="flex flex-col">
              <Text variant="light" size="sm">
                Funding allocated
              </Text>
              <Text>
                {formatCurrency(
                  application.fundingCents / 100,
                  application.fundingCurrency
                )}
              </Text>
            </div>
            <div className="flex flex-col items-end">
              <div>
                <Badge variant={utils.getBadgeStatus(application.state)}>
                  {utils.getStatusLabel(application.state)}
                </Badge>
              </div>
              {application.impactData.beneficiaries && (
                <div className="mt-2 flex flex-col">
                  <Text size="sm">
                    {new Intl.NumberFormat().format(
                      application.impactData.beneficiaries
                    )}
                    <Text variant="light" size="sm">
                      {' '}
                      people impacted
                    </Text>
                  </Text>
                </div>
              )}
            </div>
          </div>

          <div className="-my-2">
            <Divider />
          </div>

          <div className="flex items-center">
            <Avatar sourceUrl={application.applicantPhotoUrl} />
            <div className="flex flex-col pl-4">
              <Text>{application.applicantName}</Text>
              <Text variant="light" size="sm">
                Applied {dayjs(application.submittedAt).format(dateFormat)}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default MapCard
