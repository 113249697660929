import { TPayment, TPaymentWithRelationships } from 'features/funds/types'
import {
  useJsonApiQuery,
  Result,
} from 'shared/hooks/json-api/use-json-api-query'
import { UseQueryResult } from '@tanstack/react-query'

type TPageFilter = {
  size: number
  number: number
}

export function usePaymentsQuery(
  fundId: string,
  includeRelationships?: false,
  page?: TPageFilter
): UseQueryResult<Result<TPayment[]>, unknown>
export function usePaymentsQuery(
  fundId: string,
  includeRelationships: true,
  page?: TPageFilter
): UseQueryResult<Result<TPaymentWithRelationships[]>, unknown>
export function usePaymentsQuery(
  fundId: string,
  includeRelationships?: boolean,
  page?: TPageFilter
) {
  return useJsonApiQuery<TPayment[] | TPaymentWithRelationships[]>([
    '/payments',
    {
      filter: {
        fund_id: fundId,
      },
      page,
      include: includeRelationships
        ? 'solution,location,user,approved_by'
        : undefined,
      stats: { total: 'count' },
      sort: '-created_at',
    },
  ])
}
