import { Button, Text, WithTooltip } from '@changex/design-system'
import { InlineTextLoadingIndicator } from '@components'
import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UserFundRole } from '@features/funds/types'
import { useFund } from 'shared/providers'

type Props = {
  onClick: () => void
  isLoading: boolean
  rejectActionText?: string
  tooltip?: string
}

const CLASS_NAMES =
  'py-1 font-normal border-red-600 text-orange-500 hover:border-red-800 hover:text-orange-700'

const CONFIRMATION_TEXT = {
  Reject:
    'Are you sure you want to reject this location? This action will release seed and impact allocations.',
  'Reject and refund':
    'Are you sure you want to reject and refund this location? This action will release SEED and IMPACT allocation, including paid.',
  Fail: 'Are you sure you want to fail this location? This action will release the impact allocation.',
}

export function ApplicationApprovalRejectButton({
  onClick,
  isLoading,
  rejectActionText = 'Reject',
  tooltip,
}: Props) {
  const fund = useFund()
  const [open, setOpen] = useState(false)
  const clickHandler = () => {
    setOpen(!open)
  }

  if (fund.userRole !== UserFundRole.Admin) {
    return null
  }

  return (
    <>
      {tooltip ? (
        <WithTooltip tooltip={tooltip}>
          <Button
            weight="tertiary"
            onClick={clickHandler}
            className={CLASS_NAMES}
            disabled={isLoading}
          >
            <InlineTextLoadingIndicator isLoading={isLoading}>
              {rejectActionText}
            </InlineTextLoadingIndicator>
          </Button>
        </WithTooltip>
      ) : (
        <Button
          weight="tertiary"
          onClick={clickHandler}
          className={CLASS_NAMES}
          disabled={isLoading}
        >
          <InlineTextLoadingIndicator isLoading={isLoading}>
            {rejectActionText}
          </InlineTextLoadingIndicator>
        </Button>
      )}
      <ConfirmationModal
        open={open}
        onClick={onClick}
        setOpen={setOpen}
        text={rejectActionText}
      />
    </>
  )
}

type ConfirmationModalProps = {
  setOpen: (boolean) => void
  onClick: () => void
  open: boolean
  text: string
}
export function ConfirmationModal({
  open,
  setOpen,
  onClick,
  text,
}: ConfirmationModalProps) {
  const cancelButtonRef = useRef(null)
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="mt-2 p-3">
                    <Text>{CONFIRMATION_TEXT[text]}</Text>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Button
                    weight="primary"
                    className=" bg-red-600  hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      setOpen(false)
                      onClick()
                    }}
                  >
                    Yes, {text} this location
                  </Button>
                  <Button
                    weight="tertiary"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    No
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
