import { PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

export function ApplicationDetailsOverviewAttribute({
  title,
  children,
  itemsCenter,
}: PropsWithChildren<{ title: ReactNode; itemsCenter?: boolean }>) {
  return (
    <div className={classNames('flex', itemsCenter && 'items-center')}>
      <div className="w-64">{title}</div>
      <div>{children}</div>
    </div>
  )
}
