import { PropsWithChildren } from 'react'
import { Icon, Text } from '@changex/design-system'

export function ApplicationApprovalStageSuccessTick({
  children,
}: PropsWithChildren<{}>) {
  return (
    <div className="flex items-center gap-x-1">
      <Icon
        icon="CheckCircle"
        size="xs"
        variant="solid"
        className="text-green-600"
      />
      <Text size="sm">{children}</Text>
    </div>
  )
}
