import { Navigate } from 'react-router-dom'

export default function NotFoundPage() {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env['REACT_APP_TEST_FUND_ID']
  ) {
    console.log(
      `Redirecting to test fund id ${process.env['REACT_APP_TEST_FUND_ID']} (dev environment only)`
    )
    return (
      <Navigate
        to={`funds/${process.env['REACT_APP_TEST_FUND_ID']}/applications`}
      ></Navigate>
    )
  }
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-gray-800">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn't find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href={`${process.env['REACT_APP_CHANGEX_ADDRESS']}`}
              className="rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </a>
          </div>
        </div>
      </main>
    </>
  )
}
