import { createContext, PropsWithChildren, useContext } from 'react'
import { JsonApiSerializer } from './json-api-serializer'

// TODO add types
type JsonApiConfig = {
  // TODO will need to support put, etc
  query: (url: string) => Promise<any>
  create: (url: string, attribs: any) => Promise<any>
  update: (url: string, attribs: any) => Promise<any>
  serializer: JsonApiSerializer
}

const JsonApiContext = createContext<JsonApiConfig | null>(null)

export function JsonApiProvider({
  children,
  query,
  create,
  update,
  serializer,
}: PropsWithChildren<JsonApiConfig>) {
  return (
    <JsonApiContext.Provider value={{ query, create, update, serializer }}>
      {children}
    </JsonApiContext.Provider>
  )
}

export const useJsonApiConfig = () => {
  const val = useContext(JsonApiContext)
  if (!val) {
    throw new Error('Provider needed for the JSON api provider')
  }

  return val
}
