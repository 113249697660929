import { PaymentStageDetails } from 'features/funds/components/application-details/payments/payment-stage-details'
import { PaymentData } from 'features/funds/types'
import { utils } from 'features/funds/utils'

type PaymentsSectionProps = {
  paymentData: PaymentData | undefined
  title: string
  subtitle?: string
  isSocialInnovator?: boolean
}

const PaymentsSection = ({
  paymentData,
  title,
  subtitle,
  isSocialInnovator = false,
}: PaymentsSectionProps) => {
  if (isSocialInnovator) {
    const solutionPaid = utils.solutionAllocationPaid(paymentData?.solution)

    return (
      <PaymentStageDetails
        title={title}
        subtitle={subtitle}
        paymentAccounts={paymentData?.solution?.paymentAccounts}
        totalPayment={paymentData?.solution?.budgets.paid.balance || 0}
      >
        <PaymentStageDetails.StageStatus
          title="Social innovator payment"
          amount={utils.firstBudgetAmount(paymentData?.solution?.budgets)}
          isPaid={solutionPaid}
          awaitingPayment={!solutionPaid}
        />
        <div className="flex flex-1 flex-col gap-y-2" />
      </PaymentStageDetails>
    )
  }

  const seedAmountOutstanding =
    paymentData?.location.budgets.approved.allocationBalances.seed || 0
  const seedAmount =
    seedAmountOutstanding ||
    paymentData?.location.budgets.paid.allocationBalances.seed
  const impactAmountOutstanding =
    paymentData?.location.budgets.approved.allocationBalances.impact || 0
  const impactAmount =
    impactAmountOutstanding ||
    paymentData?.location.budgets.paid.allocationBalances.impact

  return (
    <PaymentStageDetails
      title={title}
      subtitle={subtitle}
      paymentAccounts={paymentData?.location.paymentAccounts}
      totalPayment={paymentData?.location.budgets.paid.balance || 0}
    >
      <PaymentStageDetails.StageStatus
        title="Seed payment"
        amount={seedAmount}
        isPaid={seedAmountOutstanding === 0}
        awaitingPayment={seedAmountOutstanding! > 0}
      />
      <PaymentStageDetails.StageStatus
        title="Impact payment"
        amount={impactAmount}
        isPaid={impactAmountOutstanding === 0}
        awaitingPayment={impactAmountOutstanding! > 0}
      />
    </PaymentStageDetails>
  )
}

export default PaymentsSection
