import {
  Icon,
  Selector,
  TreeSelector,
  WithTooltip,
} from '@changex/design-system'
import { Solution } from '@types'
import { TMapFilters } from '../../types'
import { constants } from '../../constants'
import { Dispatch } from 'react'

type TProps = {
  filters: TMapFilters
  onFilter: (name: string, value: string) => void
  onReset: () => void
  solutions: Solution[]
  ideaId: string | number
  setIdeaId: Dispatch<React.SetStateAction<string>>
  isStateFilterDisabled?: boolean
}

export function MapFilters({
  filters,
  onFilter,
  onReset,
  solutions,
  ideaId,
  setIdeaId,
  isStateFilterDisabled,
}: TProps) {
  const StatusSelector = (
    <TreeSelector
      title={'Status'}
      options={constants.stateCategories}
      value={
        filters['state'] && filters['state'] !== ''
          ? filters['state']?.split(',')
          : []
      }
      onChange={(value) => {
        onFilter('state', value.join(','))
      }}
      disabled={isStateFilterDisabled}
    />
  )
  return (
    <div className="flex flex-wrap items-center gap-x-6">
      <Selector
        onChange={(value) => onFilter('solution_id', value)}
        options={constants.projectTypes}
        value={filters['solution_id'] ?? ''}
      />
      {isStateFilterDisabled ? (
        <WithTooltip
          tooltip={
            'Only locations who have reported a number of people impacted will be shown on this view. View the Projects map to see other Location types.'
          }
        >
          <div>{StatusSelector}</div>
        </WithTooltip>
      ) : (
        StatusSelector
      )}
      <Selector
        filterOptions={{
          enabled: true,
          placeholder: 'Filter ideas by name',
        }}
        onChange={(value) => setIdeaId(value)}
        options={[
          { id: '', label: 'Idea' },
          ...solutions.map((solution) => ({
            id: String(solution.id),
            label: solution.name,
          })),
        ]}
        value={`${ideaId ?? ''}`}
      />
      <Selector
        onChange={(value) => onFilter('date_range', value)}
        options={constants.dateRanges}
        value={filters['date_range'] ?? ''}
      />
      {Object.entries(filters).filter(([key, value]) => Boolean(value)).length >
        0 || ideaId !== '' ? (
        <button
          className="flex items-center text-sm font-medium text-red-600"
          onClick={() => {
            onReset()
          }}
        >
          <Icon icon="XIcon" size="xs" />
          Clear filters
        </button>
      ) : null}
    </div>
  )
}
