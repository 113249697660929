import { TApplicationAnalytics } from 'shared/types'
import { useJsonApiQuery } from 'shared/hooks/json-api/use-json-api-query'

export const useApplicationAnalyticsQuery = (id: string) => {
  return useJsonApiQuery<TApplicationAnalytics>([
    '/analytics/funds/applications',
    {
      filter: {
        id,
      },
    },
  ])
}
