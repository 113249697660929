import Layout from 'app/funds/[id]/layout'
import { useFund } from 'shared/providers'
import { DashboardOverview } from 'features/funds/components/dashboard-overview'
import { DashboardApplications } from 'features/funds/components/dashboard-applications'
import { DashboardImpact } from 'features/funds/components/dashboard-impact'

const DashboardContent = () => {
  const fund = useFund()
  return (
    <div className="bg-copper-50 h-screen">
      <DashboardOverview fund={fund} />
      <DashboardApplications fund={fund} />
      <DashboardImpact fund={fund} />
    </div>
  )
}

export default function DashboardPage() {
  return (
    <Layout>
      <DashboardContent />
    </Layout>
  )
}
