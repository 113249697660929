import { FundApplication } from '@features/funds/types'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { APPLICATION_APPROVAL_TOAST_DURATION } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { useApplicationRefundMutation } from '@features/funds/hooks/use-application-refund-mutation'

export function useRejectAndRefundApplication(application: FundApplication) {
  const refundApplicationMutation = useApplicationRefundMutation()

  const mutate = useCallback(() => {
    refundApplicationMutation.mutate(
      {
        application_id: application.id,
        fund_id: application.fund.id!,
      },
      {
        onSuccess: () => {
          toast.success('Application rejected and refunded successfully', {
            duration: APPLICATION_APPROVAL_TOAST_DURATION,
          })
        },
        onError: () => {
          toast.error(
            'There was an error rejecting and refunding this application',
            {
              duration: APPLICATION_APPROVAL_TOAST_DURATION,
            }
          )
        },
      }
    )
  }, [application.fund.id, application.id, refundApplicationMutation])

  return {
    mutate,
    isLoading: refundApplicationMutation.isLoading,
  }
}
