import { FundApplicationReplication } from '../../types'
import { ApplicationDetailsOverviewAttribute } from '@features/funds/components/application-details/application-details-overview-attribute'
import { AvatarStack } from '@changex/design-system'

type Props = {
  data: FundApplicationReplication
}

export function ApplicationDetailsMembers({ data }: Props) {
  const members = data?.members || []
  const memberCount = members.length

  return (
    <ApplicationDetailsOverviewAttribute
      title={`Project team (${memberCount})`}
    >
      {memberCount === 0 ? (
        'No team members'
      ) : (
        <AvatarStack>
          {data.members.map((member) => (
            <AvatarStack.Avatar
              key={member.avatarUrl}
              src={member.avatarUrl}
              alt={member.fullName}
            />
          ))}
        </AvatarStack>
      )}
    </ApplicationDetailsOverviewAttribute>
  )
}
