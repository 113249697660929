// Based on https://github.com/aribouius/jsonapi-react
import { stringify as qs } from 'qs'
import { isObject, isString } from 'lodash'

export function isId(n) {
  return !!(n && String(n).match(/^[0-9]+/))
}

export function isNumber(n) {
  return !isNaN(Number(n))
}

export function isUUID(v) {
  return (
    isString(v) &&
    v.match(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    )
  )
}

export function toArray(val) {
  return Array.isArray(val) ? val : [val]
}

export function stringify(params, options) {
  return qs(params, {
    arrayFormat: 'comma',
    encodeValuesOnly: true,
    ...options,
  })
}

export function parseQueryArg(arg: any, options: any = {}) {
  if (!arg) {
    return {}
  }

  let keys = toArray(arg).reduce((acc, val) => {
    return acc.concat(isString(val) ? val.split('/').filter(Boolean) : val)
  }, [])

  let id: any = null
  let params

  if (isObject(keys[keys.length - 1])) {
    params = keys.pop()
  }

  let url = `/${keys.join('/')}`

  if (params) {
    url += '?'
    if (typeof options.stringify === 'function') {
      url += options.stringify(params, stringify)
    } else {
      url += stringify(params, options.stringify)
    }
  } else {
    params = {}
  }

  const last = keys[keys.length - 1]
  if (isId(last) || isUUID(last)) {
    id = String(keys.pop())
  }

  keys = keys.filter((k) => !isId(k) && !isUUID(k))

  return {
    url,
    id,
    params,
    keys,
  }
}

export function coerceValue(val, type) {
  switch (type) {
    case 'string':
      return String(val || (val === 0 ? 0 : ''))
    case 'number':
      return val ? parseInt(val, 10) : val
    case 'float':
      return val ? parseFloat(val) : val
    case 'date':
      return val ? new Date(val) : val
    case 'boolean':
      return !!val
    default:
      return val
  }
}
