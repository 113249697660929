import { Button, Input, Selector } from '@changex/design-system'
import { formatHeardAboutUs } from '@features/funds/components/application-details/application-details.utils'
import { FundApplication, HeardAboutUsOption } from '@features/funds/types'
import { ApplicationDetailsOverviewAttribute } from '@features/funds/components/application-details/application-details-overview-attribute'
import { useApplicationUpdateMutation } from '@features/funds/hooks/use-application-update-mutation'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { InlineTextLoadingIndicator } from '@components'

const HEARD_ABOUT_US_OPTIONS: HeardAboutUsOption[] = [
  'social_media',
  'word_of_mouth',
  'newspaper_tv_radio',
  'changex_email',
  'changex_newsletter',
  'another_org',
  'partner',
  'other',
  'not_sure',
]

type Props = {
  application: FundApplication
}

export function ApplicationDetailsHeardAboutUs({ application }: Props) {
  // Set to editing initially if not completed yet, otherwise show readonly
  const [isEditing, setIsEditing] = useState(!Boolean(application.heardAboutUs))
  const applicationMutation = useApplicationUpdateMutation()
  const [heardAboutUs, setHeardAboutUs] = useState<HeardAboutUsOption | ''>(
    application.heardAboutUs ?? ''
  )
  const [heardAboutUsDetail, setHeardAboutUsDetail] = useState(
    application.heardAboutUsDetail
  )

  const handleSave = useCallback(() => {
    if (!heardAboutUs) {
      toast.error('Please select where you heard about us')
      return
    }
    applicationMutation.mutate(
      {
        type: application.type,
        id: application.id as string,
        heardAboutUs,
        heardAboutUsDetail,
      },
      {
        onError: () => toast.error('There was an error saving your response.'),
        onSuccess: () => {
          toast.success('Thank you for your feedback.')
          setIsEditing(false)
        },
      }
    )
  }, [
    application.id,
    application.type,
    applicationMutation,
    heardAboutUs,
    heardAboutUsDetail,
  ])

  return (
    <div className="flex flex-col gap-2" data-testid="heard-about-us">
      <ApplicationDetailsOverviewAttribute
        title={<label>Where did they hear about the fund?</label>}
        itemsCenter
      >
        {isEditing ? (
          <Selector
            className="w-64"
            value={heardAboutUs}
            onChange={(value) => {
              setHeardAboutUs(value as HeardAboutUsOption)
            }}
            options={[
              { id: '', label: 'Select' },
              ...HEARD_ABOUT_US_OPTIONS.map((id) => ({
                id,
                label: formatHeardAboutUs(id),
              })),
            ]}
          />
        ) : (
          <div>{formatHeardAboutUs(application.heardAboutUs)}</div>
        )}
      </ApplicationDetailsOverviewAttribute>
      <ApplicationDetailsOverviewAttribute
        title={
          <label htmlFor="heard-about-us-detail">Additional details</label>
        }
        itemsCenter
      >
        {isEditing ? (
          <Input
            id="heard-about-us-detail"
            value={heardAboutUsDetail}
            onChange={(event) => setHeardAboutUsDetail(event.target.value)}
            placeholder="Please specify"
            type="text"
            className="w-96"
          />
        ) : (
          <div>{application.heardAboutUsDetail}</div>
        )}
      </ApplicationDetailsOverviewAttribute>
      <ApplicationDetailsOverviewAttribute title={null}>
        <Button
          type="submit"
          weight="tertiary"
          className="mt-4"
          onClick={isEditing ? handleSave : () => setIsEditing(true)}
          disabled={applicationMutation.isLoading}
        >
          <InlineTextLoadingIndicator isLoading={applicationMutation.isLoading}>
            {isEditing ? 'Save' : 'Edit'}
          </InlineTextLoadingIndicator>
        </Button>
      </ApplicationDetailsOverviewAttribute>
    </div>
  )
}
