import { Badge } from '@changex/design-system'
import { APPLICATIONS_STATUS } from '@features/funds/constants'
import { utils } from '@features/funds/utils'

type Props = {
  status: keyof typeof APPLICATIONS_STATUS
}

export function ApplicationInfoStatusBadge({ status }: Props) {
  return (
    <Badge variant={utils.getBadgeStatus(status)}>
      {APPLICATIONS_STATUS[status].label}
    </Badge>
  )
}
