import { httpClient } from '@api'

type TInput = {
  id: string
}

export function useDeletePostCommentMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.delete(`/comments/${input.id}`)
    },
  }
}
