import { Button, formatDate, Text, Icon } from '@changex/design-system'
import { FundApplication } from '../../types'
import { useApplicationUpdateMutation } from '@features/funds/hooks/use-application-update-mutation'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { ApplicationDetailsOverviewAttribute } from '@features/funds/components/application-details/application-details-overview-attribute'
import { useFund } from './../../../../../src/shared/providers'
import { UserFundRole } from '@features/funds/types'

type Props = {
  data?: FundApplication | null
}

function ApplicationDetailsCall({ data }: Props) {
  const fund = useFund()
  const callStartTime = data?.callStartTime
  const [receivedStarterCallAt, setReceivedStarterCallAt] = useState<string>(
    data?.receivedStarterCallAt || ''
  )

  const callScheduleDateFormatted = formatDate(callStartTime!)
  const receivedCallScheduleDateFormatted = formatDate(receivedStarterCallAt!)

  const updateApplicationMutation = useApplicationUpdateMutation()

  const handleMarkCallReceivedButtonClick = () => {
    const callTime = new Date().toISOString()

    updateApplicationMutation.mutate(
      {
        id: data?.id + '',
        type: data?.type,
        receivedStarterCallAt: callTime,
      },
      {
        onError: () => {
          toast.error('There was an error marking the call as received.')
        },
        onSuccess: () => {
          setReceivedStarterCallAt(callTime)
          toast.success('The applicant call has been marked as received.')
        },
      }
    )
  }

  return (
    <ApplicationDetailsOverviewAttribute title="Call scheduled">
      <div>
        <Text weight="medium">
          {callStartTime ? callScheduleDateFormatted.toString() : 'No'}
        </Text>
      </div>
      {receivedStarterCallAt ? (
        <div className="mt-2 flex items-center gap-x-1">
          <Icon
            icon="CheckCircle"
            size="sm"
            variant="solid"
            className="text-green-500"
          />
          <div>
            <Text>Call received at: </Text>
            <Text weight="medium">{receivedCallScheduleDateFormatted}</Text>
          </div>
        </div>
      ) : (
        <>
          {fund.userRole === UserFundRole.Admin ? (
            <div className="mt-1.5">
              <Button
                className="!py-1.5 !px-2.5"
                weight="tertiary"
                onClick={handleMarkCallReceivedButtonClick}
                disabled={updateApplicationMutation.isLoading}
              >
                {(() => {
                  if (updateApplicationMutation.isLoading) {
                    return 'Saving...'
                  }
                  return 'Mark as call received (by ChangeX)'
                })()}{' '}
              </Button>
            </div>
          ) : null}
        </>
      )}
    </ApplicationDetailsOverviewAttribute>
  )
}

export default ApplicationDetailsCall
