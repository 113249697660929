import {
  ButtonGroup,
  CurrencyIso,
  CURRENCY_SYMBOLS,
} from '@changex/design-system'

const standarizedCurrencies: CurrencyIso[] = ['USD', 'EUR']

type TProps = {
  fundCurrency: CurrencyIso
  selectedCurrency: CurrencyIso
  setSelectedCurrency: (value: CurrencyIso) => void
}

const CurrencyFilter: React.FC<TProps> = ({
  fundCurrency,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const currenciesToDisplay = standarizedCurrencies.includes(fundCurrency)
    ? standarizedCurrencies
    : [fundCurrency, ...standarizedCurrencies]
  return (
    <ButtonGroup
      items={currenciesToDisplay.map((currencyIso) => ({
        label: `${CURRENCY_SYMBOLS[currencyIso]} ${currencyIso}`,
        onClick: () => setSelectedCurrency(currencyIso),
        active: selectedCurrency === currencyIso,
      }))}
    />
  )
}

export default CurrencyFilter
