import { useMutation } from '@tanstack/react-query'
import { httpClient } from '@api'

type Options = {
  fundId: number | string
}

export function usePaymentDownload() {
  return useMutation({
    mutationFn: (opts: Options) => {
      let params = `filter[fund_id]=${opts.fundId}`

      return httpClient.get(`/payments/export?${params}`)
    },
  })
}
