import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { QueryParamProvider } from 'use-query-params'
import { ReactNode } from 'react'

export function QueryParamInfrastructure({
  children,
}: {
  children: ReactNode
}) {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      {children}
    </QueryParamProvider>
  )
}
