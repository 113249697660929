import { useJsonApiMutation } from '../../../shared/hooks/json-api/use-json-api-mutation'
import { TAllocationType } from 'features/funds/types'

export function useStripeSecretMutation() {
  return useJsonApiMutation<{
    allocation_type: TAllocationType
    fund_id: string
    location_id: string
  }>('stripe_payment_secrets')
}
