import { useStripe } from '@stripe/react-stripe-js'
import { MutateOptions, useMutation } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Stripe } from '@stripe/stripe-js'

export type StripePaymentIntent = {
  clientSecret: string
  customerId: string
  paymentMethod: string
  publicKey: string
}

type PaymentResult = { type: 'success' } | { type: 'error'; message?: string }

async function makePayment(
  stripe: Stripe,
  { clientSecret, paymentMethod }: StripePaymentIntent
): Promise<PaymentResult> {
  if (paymentMethod.indexOf('pm_') > -1) {
    const result = await stripe.confirmSepaDebitPayment(clientSecret)
    if (result.error) {
      // Show error to your user (e.g., insufficient funds)
      return {
        type: 'error',
        message: result.error.message,
      }
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status) {
        // Show a success message to your user
        // We also listen for the payment_intent.succeeded webhook on the server to trigger critical post-payment actions
        return {
          type: 'success',
        }
      } else {
        return {
          type: 'error',
          message:
            'Something went wrong :( Reach out for support. SEPA payment without status.',
        }
      }
    }
  } else {
    const result = await stripe.confirmCardPayment(clientSecret)
    if (result.error) {
      // Show error to your user (e.g., insufficient funds)
      return {
        type: 'error',
        message: result.error.message,
      }
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your user
        // We also listen for the payment_intent.succeeded webhook on the server to trigger critical post-payment actions
        return {
          type: 'success',
        }
      } else {
        return {
          type: 'error',
        }
      }
    }
  }
}

/**
 * Make a payment with stripe
 *
 * (Should be used in a component that is contained in a Stripe Element provider)
 */
export function usePayWithStripe() {
  const stripe = useStripe()
  const mutation = useMutation<PaymentResult, unknown, StripePaymentIntent>({
    mutationFn: async (paymentIntent) => {
      if (!stripe) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        throw new Error('Stripe not ready. Ensure library is loaded.')
      }
      return makePayment(stripe, paymentIntent)
    },
  })

  return useMemo(
    () => ({
      mutate: (
        params: StripePaymentIntent,
        opts: MutateOptions<PaymentResult, unknown, StripePaymentIntent>
      ) => mutation.mutate(params, opts),
      isReady: Boolean(stripe),
      isProcessing: mutation.isLoading,
    }),
    [mutation, stripe]
  )
}
