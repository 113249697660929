import { useState } from 'react'
import { useSettings } from '../providers/setting-provider'
import { Setting } from '@types'

const useSetting = (defaultSetting: Setting) => {
  const settings = useSettings()
  const existingSetting = settings.find(
    (setting) => setting.key === defaultSetting.key
  )

  const setting = existingSetting || defaultSetting

  const [value, setValueState] = useState(setting.value)

  const setValue = (newValue) => {
    setValueState(newValue)

    setting.value = newValue
    if (!existingSetting) {
      settings.push(setting)
    }
  }

  return [value, setValue]
}

export default useSetting
