import { AvatarStack, Badge, Tooltip } from '@changex/design-system'
import dayjs from 'dayjs'
import { TAccountType, TPaymentWithRelationships } from 'features/funds/types'
import PaymentStatus from './payment-status'
import AllocationType from './allocation-type'
import { ideaUrl, locationUrl } from 'shared/utils/routing'

const User = ({ prefix, fullName, email, avatarUrl }) => {
  return (
    <Tooltip placement="top">
      <Tooltip.Trigger>
        <AvatarStack.Avatar src={avatarUrl} alt={`${prefix} ${fullName}`} />
      </Tooltip.Trigger>
      <Tooltip.Content>
        {prefix} {fullName} ({email})
      </Tooltip.Content>
    </Tooltip>
  )
}

const Payment = ({
  createdAt,
  status,
  reference,
  accountType,
  allocationType,
  currency,
  amount,
  user,
  approvedBy,
  solution,
  location,
}: TPaymentWithRelationships) => {
  return (
    <tr data-testid="payment">
      <td className="w-28 px-3 py-5 pl-4 text-base">
        {dayjs(createdAt).format('YYYY-MM-DD')}
      </td>
      <td className="px-3 py-5 text-base">
        <PaymentStatus status={status} />
      </td>
      <td className="px-3 py-5 text-base">{reference}</td>
      <td className="max-w-sm px-3 py-5 text-base">
        <a
          href={locationUrl(solution.slug, location.slug)}
          rel="noreferrer"
          target="_blank"
        >
          {location.name}
        </a>
      </td>
      <td className="px-3 py-5 text-base">
        {solution.id === '99' ? (
          'Open grant'
        ) : (
          <a href={ideaUrl(solution.slug)} rel="noreferrer" target="_blank">
            {solution.name}
          </a>
        )}
      </td>
      <td className="px-3 py-5 text-base">
        <AvatarStack>
          {approvedBy && <User prefix="Approved by" {...approvedBy} />}
          <User prefix="Paid by" {...user} />
        </AvatarStack>
      </td>
      <td className="px-3 py-5 text-base">
        {accountType === TAccountType.manual && (
          <Badge variant="basic" className="text-base">
            Manual
          </Badge>
        )}
      </td>
      <td className="px-3 py-5 text-base">
        <AllocationType type={allocationType} />
      </td>
      <td className="px-3 py-5 pr-4 text-right text-base font-bold">
        {new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency,
          currencyDisplay: 'narrowSymbol',
        }).format(amount)}
      </td>
    </tr>
  )
}

export default Payment
