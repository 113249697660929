import { CurrencyIso, TimeZoneKey } from '@changex/design-system'

import { TPortfolio } from './portfolio.type'
import { FundType } from '@features/funds/types'

export enum TVariant {
  HIGH_VALUE = 'high_value',
  STANDARD = 'standard',
}

export type TFundOptions = {
  type: FundType
  amount: number
  amountEur?: number
  amountUsd?: number
  currency: CurrencyIso
  endDate: string
  startDate: string
  notificationDate?: string
  locationMatch: any // Polygon
  paymentSystem: string
  paymentPlatformAccountId?: number
  preAllocations: boolean
  portfolio?: TPortfolio[]
  openGrants?: {
    type: string
    budget: {
      custom: boolean
      max: number
      min: number
      options: number[]
    }
    solutionId: string
    maxReplications: number
    socialCategoriesOptions: number[]
  }
  timeZone: TimeZoneKey
  variant?: TVariant
}
