import React from 'react'
import classNames from 'classnames'

type Status = 'failure' | 'notConnected' | 'success'

const statusTwClassMapping: Record<Status, string> = {
  failure: 'bg-red-400',
  notConnected: 'bg-zinc-300',
  success: 'bg-green-400',
}

type Props = {
  status: 'failure' | 'notConnected' | 'success'
}

export function PaymentsStatusDot({ status }: Props) {
  return (
    <span
      className={classNames(
        'h-3 w-3 rounded-full',
        statusTwClassMapping[status]
      )}
    ></span>
  )
}
