import dayjs from 'dayjs'
import { When } from 'react-if'
import {
  Text,
  Avatar,
  Icon,
  ApplicationEntityType,
} from '@changex/design-system'
import { FundApplication } from '../../types'

type TProps = {
  data?: FundApplication | null
}

const now = dayjs()

const ApplicationsDetailsTitle: React.FC<TProps> = ({ data }) => (
  <div className="flex w-full">
    <div>
      <Avatar sourceUrl={data?.owner?.avatarUrl || ''} size="xlg" />
    </div>
    <div className="ml-6">
      <div className="flex gap-1.5">
        <Text size="2xl">{data?.owner?.fullName}</Text>
        {data?.entityType && (
          <ApplicationEntityType entityType={data.entityType} />
        )}
      </div>
      <div className="mt-2 flex">
        <When condition={data?.owner?.email}>
          <Text className="flex items-center" size="sm" variant="light">
            <Icon className="mr-1.5" icon="Mail" size="sm" />
            {data?.owner?.email}
          </Text>
        </When>
        <When condition={data?.owner?.phone}>
          <Text className="ml-8 flex items-center" size="sm" variant="light">
            <Icon className="mr-1.5" icon="Phone" size="sm" />
            {data?.owner?.phone}
          </Text>
        </When>
      </div>
    </div>
    <div className="ml-auto">
      <Text variant="light">
        Submitted {now.diff(data?.createdAt, 'd')} days ago
      </Text>
    </div>
  </div>
)

export default ApplicationsDetailsTitle
