import SideNavigation from 'features/funds/components/settings/side-navigation'
import Layout from 'app/funds/[id]/layout'
import FundEditor from 'app/funds/[id]/settings/general/_fund-editor/fund-editor'
import { useCurrentUser, useFund, SettingsProvider } from 'shared/providers'
import { ErrorPage } from '@features/not-found/pages/error.page'
import { useSettingQuery } from 'shared/hooks/use-setting-query'
import { Setting } from '@types'
import { LoadingIcon } from '@changex/design-system'

const GeneralSettings = () => {
  const user = useCurrentUser()
  const fund = useFund()
  const settingQuery = useSettingQuery<Setting[]>(fund.id, 'fund', {
    key: 'impact_waiting_period',
  })

  if (user.userType !== 'cx_admin') {
    return <ErrorPage />
  }

  if (settingQuery.isLoading || !settingQuery.data) {
    return (
      <div className="grid h-screen place-items-center">
        <div className="flex items-center gap-x-2 px-12 py-8 opacity-80 shadow-md">
          <LoadingIcon />
        </div>
      </div>
    )
  }

  return (
    <div className="flex min-h-screen">
      <SideNavigation />
      <main className="flex flex-grow flex-col gap-6">
        <SettingsProvider settings={settingQuery.data.results}>
          <FundEditor id={fund.id} fund={fund} />
        </SettingsProvider>
      </main>
    </div>
  )
}

export default function GeneralPage() {
  return (
    <Layout>
      <GeneralSettings />
    </Layout>
  )
}
