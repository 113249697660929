import Layout from 'app/funds/[id]/layout'
import { usePhotosQuery } from 'features/funds/hooks/use-photos-query'
import {
  ImagesSlideGallery,
  Pagination,
  Skeleton,
  Text,
  ViewSwitcher,
} from '@changex/design-system'
import { Image, PhotosData } from 'features/funds/types'
import PhotoCard from 'features/funds/components/photo-card'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { utils } from 'features/funds/utils'
import { useQueryParams, StringParam } from 'use-query-params'
import { Nullable, Solution } from '@types'
import { PhotoFilters } from 'features/funds/components/photo-filters'
import { useSolutionsQuery } from 'features/funds/hooks/use-solutions-query'

const PhotosContent = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [gridViewActive, setGridViewActive] = useState(true)
  const [queryFilters, setQueryFilters] = useQueryParams({
    'filter[state]': StringParam,
    'filter[solution_id]': StringParam,
    'filter[is_favourite]': StringParam,
    'page[number]': StringParam,
  })
  const [filters, setFilters] = useState<Record<string, Nullable<string>>>(
    queryFilters as Record<string, string>
  )

  const photosQuery: any = usePhotosQuery(filters)
  const photosData: PhotosData[] = photosQuery.data?.results
  const totalPhotosCount = photosQuery.data?.meta.stats.total.count

  const solutionsQuery: any = useSolutionsQuery()
  const solutions: Solution[] = solutionsQuery.data?.results.solutions

  const images: Image[] = photosData?.map((photoData) => {
    return {
      src: utils.addSizeToFirestackUrl(photoData.photoUrl, 800, 800),
      alt: '',
    }
  })

  const handleImageClick = (index: number) => {
    setActiveImageIndex(index)
    setIsOpen(true)
  }

  useEffect(() => {
    setQueryFilters(
      Object.values(filters).filter(Boolean).length > 0
        ? Object.entries(filters).reduce(
            (f, [key, value]) => ({ ...f, [key]: value || null }),
            {}
          )
        : {
            'filter[state]': null,
            'filter[solution_id]': null,
            'filter[is_favourite]': null,
            'page[number]': null,
          }
    )
  }, [filters, setQueryFilters])

  const handleFilterChange = (name: string, value: string) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      [name]: value,
      'page[number]': name !== 'page[number]' ? null : filters['page[number]'],
    }))
  }

  const handlePagination = (pagination) => {
    window.scrollTo({ top: -10 })
    setFilters((oldFilters) => ({
      ...oldFilters,
      'page[number]': pagination.page.toString(),
    }))
  }

  const handleResetFilters = () => {
    setFilters({})
  }

  const pagination = {
    currentPage: filters['page[number]'] || '1',
    meta: photosQuery.data?.meta,
    onFilter: handlePagination,
  }

  return (
    <>
      <div className="bg-copper-50">
        <div className="max-w-changex mx-auto flex flex-col gap-y-4 py-2.5">
          <div className="z-20 flex items-center justify-between">
            <PhotoFilters
              filters={filters}
              onFilter={handleFilterChange}
              onReset={handleResetFilters}
              solutions={solutions ?? []}
            />
            <ViewSwitcher setGridView={setGridViewActive} />
          </div>
        </div>
      </div>
      <div className="max-w-changex relative mx-auto mt-6">
        {photosQuery.isFetching ? (
          <>
            <Skeleton h="24px" w="200px" />
            <div className="my-5 grid grid-cols-4 gap-[2px]">
              {[...Array(16)].map((e, i) => (
                <Skeleton h="250px" key={i} />
              ))}
            </div>
          </>
        ) : (
          <>
            <Text>{totalPhotosCount} photos</Text>
            <div className="my-5 grid grid-cols-4 gap-[2px]">
              {photosData?.map((photoData: PhotosData, index) => {
                return (
                  <PhotoCard
                    id={photoData.id}
                    key={photoData.id}
                    photoUrl={utils.addSizeToFirestackUrl(
                      photoData.photoUrl,
                      800,
                      800
                    )}
                    postUrl={photoData.postUrl}
                    locationName={photoData.locationName}
                    locationAddress={utils.truncateAddress(
                      photoData.locationAddress
                    )}
                    createdAt={dayjs(photoData.createdAt).format(
                      'MMM DD[,] YYYY'
                    )}
                    isFavourite={photoData.isFavourite}
                    solutionId={photoData.solutionId}
                    handleImageOnClick={() => handleImageClick(index)}
                    isGridView={gridViewActive}
                  />
                )
              })}
            </div>
          </>
        )}
        <div className="absolute z-20 h-20 w-full">
          <Pagination
            currentPage={parseInt(pagination.currentPage)}
            meta={pagination.meta}
            onFiltering={pagination.onFilter}
          />
        </div>
      </div>
      {images ? (
        <ImagesSlideGallery
          images={images}
          activeImageIndex={activeImageIndex}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : null}
    </>
  )
}

export default function PhotosPage() {
  return (
    <Layout>
      <PhotosContent />
    </Layout>
  )
}
