import { httpClient } from '@api'

type TInput = {
  id: string
}

export function useLikePhotoMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.post('/likes', {
        data: {
          attributes: {
            likeable_id: Number(input.id),
            type: 'assets',
          },
        },
      })
    },
  }
}
