import React from 'react'
import { Tabs } from '@changex/design-system'

import { ApplicationDetailsMoreInfoTab } from '@features/funds/components/application-details/more-info/application-details-more-info-tab'
import { FundApplication } from '@features/funds/types'
import ApplicationDetailsPaymentsTab from '@features/funds/components/application-details/payments/application-details-payments-tab'
import { ApplicationDetailsOverviewReplication } from '@features/funds/components/application-details/replication/application-details-overview-replication'
import { ApplicationDetailsOverviewOpenGrant } from '@features/funds/components/application-details/open-grant/application-details-overview-open-grant'
import { useCurrentUser } from '../../../../shared/providers'

type Props = {
  data?: FundApplication
}

export function ApplicationDetails({ data }: Props) {
  const user = useCurrentUser()

  const ITEMS = [
    {
      title: 'Overview',
      content: (() => {
        if (!data) {
          return null
        }
        switch (data.type) {
          case 'solution_applications':
            return <ApplicationDetailsOverviewReplication data={data} />
          case 'project_applications':
            return <ApplicationDetailsOverviewOpenGrant data={data} />
        }
      })(),
    },
    {
      title: 'Payments',
      content: <ApplicationDetailsPaymentsTab data={data} />,
    },
  ]

  if (user.userType === 'cx_admin') {
    ITEMS.push({
      title: 'More Info',
      content: <ApplicationDetailsMoreInfoTab data={data} />,
    })
  }

  return <Tabs tabs={ITEMS} />
}
