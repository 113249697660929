import { useEffect, ReactNode } from 'react'
import { LoadingIcon } from '@changex/design-system'
import { useParams } from 'react-router-dom'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Toaster } from 'react-hot-toast'
import { Menu } from 'shared/components'
import Header from 'shared/components/header'
import { useFundQuery } from 'shared/hooks/use-fund-query'
import { useFeedback } from 'shared/hooks/use-feedback'
import { useUserMeQuery } from 'shared/hooks/use-user-me-query'
import { CurrentUserProvider, FundProvider } from 'shared/providers'

type TProps = {
  children: ReactNode | ReactNode[]
}

export function AppData({ children }: TProps) {
  const { fundId } = useParams<{ fundId: string }>()
  const fundQuery = useFundQuery(fundId)
  const userQuery = useUserMeQuery()
  const { feedback } = useFeedback([fundQuery])
  const ldClient = useLDClient()

  useEffect(() => {
    if (ldClient && userQuery.data?.results) {
      const { id, email, fullName } = userQuery.data.results
      ldClient.identify({
        kind: 'user',
        key: `user-${id}`,
        name: fullName,
        email,
      })
    }
  }, [ldClient, userQuery.data?.results])

  if (!(fundQuery.data && userQuery.data)) {
    return (
      <div className="grid h-screen place-items-center">
        <div className="flex items-center gap-x-2 px-12 py-8 opacity-80 shadow-md">
          <LoadingIcon /> {feedback}
        </div>
      </div>
    )
  }

  return (
    <CurrentUserProvider user={userQuery.data.results}>
      <FundProvider fund={fundQuery.data.results}>{children}</FundProvider>
    </CurrentUserProvider>
  )
}

const Layout: React.FC<TProps> = ({ children }) => (
  <AppData>
    <Header />
    <Menu />
    {children}
    <Toaster />
  </AppData>
)

export default Layout
