import { TImpactAnalytics } from 'shared/types'
import { useJsonApiQuery } from 'shared/hooks/json-api/use-json-api-query'

export const useImpactAnalyticsQuery = (id: string) => {
  return useJsonApiQuery<TImpactAnalytics>([
    '/analytics/funds/impacts',
    {
      filter: {
        id,
      },
    },
  ])
}
