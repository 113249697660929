import { httpClient } from '@api'

type TInput = {
  id: string
  type: 'posts'
}

export function useUnlikePostMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.delete(`/likes/${input.id}?type=${input.type}`)
    },
  }
}
