import { useController, useFormContext } from 'react-hook-form'
import { Button, CardRadioGroup } from '@changex/design-system'
import { useState } from 'react'

import PolygonTypeEditor from './_location-editor/polygon-type-editor'
import KeywordTypeEditor from './_location-editor/keyword-type-editor'
import CircleTypeEditor from './_location-editor/circle-type-editor'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const MatchType = () => {
  const { control } = useFormContext()
  const {
    field: { value: typeValue, onChange: onTypeChange },
  } = useController({ name: 'options.locationMatch.type', control })
  const {
    field: { onChange: onOptionsChange },
  } = useController({ name: 'options.locationMatch.options', control })

  return (
    <CardRadioGroup
      label="How should we define the catchment area?"
      options={[
        {
          id: 'region',
          title: 'Keywords',
          description:
            "Specify certain words or letters to match against the application's address",
        },
        {
          id: 'circle',
          title: 'Circle',
          description:
            'Define a circular catchment area, with a centre point and radius',
        },
        {
          id: 'polygon',
          title: 'Polygon',
          description:
            'Draw the outline of the catchment area in any shape you like',
        },
      ]}
      value={typeValue}
      onChange={(newType) => {
        onTypeChange(newType)
        onOptionsChange({})
      }}
    />
  )
}

function LocationEditor() {
  const { watch, setValue } = useFormContext()
  const [geoJsonError, setGeoJsonError] = useState(false)

  const type = watch('options.locationMatch.type')
  const polygons = watch('options.locationMatch.options.polygons')

  const parseCoordinates = (geoJson) => {
    return geoJson.flat(1).map((coord) => coord.reverse())
  }

  const handlePolygonsChange = (e) => {
    let geoJsonData: any = {}
    const existingPolygons = polygons && polygons.length > 0

    try {
      geoJsonData = JSON.parse(e.target.value)
    } catch (e) {
      console.error(e)
      return
    }

    const isMultiPolygon = geoJsonData.type === 'MultiPolygon'

    let outputOfCoordinates = isMultiPolygon
      ? geoJsonData.coordinates.map(parseCoordinates)
      : [parseCoordinates(geoJsonData.coordinates)]
    const duplicateCheck = JSON.stringify(outputOfCoordinates[0]) // We only need to check one of the items of a multi polygon or the only polygon item of a singular polygon.

    setGeoJsonError(false)
    for (const item of polygons) {
      if (duplicateCheck === JSON.stringify(item)) {
        setGeoJsonError(true)
        return
      }
    }

    if (existingPolygons) {
      outputOfCoordinates = polygons.concat(outputOfCoordinates)
    }

    setValue('options.locationMatch.options.polygons', outputOfCoordinates)
  }

  const handlePolygonsRemoval = () => {
    setValue('options.locationMatch.options.polygons', [])
    setGeoJsonError(false)
  }

  if (type === 'region') {
    return (
      <div className="flex flex-col gap-4">
        <MatchType />
        <KeywordTypeEditor />
      </div>
    )
  }

  if (type === 'polygon') {
    return (
      <div className="flex flex-col gap-4">
        <MatchType />
        <p className="text-sm text-gray-500">
          Draw the outline of the fund's catchment area and mark the centre
          point.
        </p>
        <p className="text-sm text-gray-500">
          You can also paste in the GeoJSON here:
        </p>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <input
              type="text"
              className={`mr-1 block basis-4/5 rounded-md shadow-sm ${
                geoJsonError ? '!border-red-500' : 'border-gray-300'
              }`}
              onChange={handlePolygonsChange}
              value=""
            />
            <Button onClick={handlePolygonsRemoval} weight="secondary">
              <span className="text-xs">Reset polygon data</span>
            </Button>
          </div>
          <div>
            {geoJsonError && (
              <div className="text-red-600">
                <ExclamationCircleIcon className="mr-1 mb-0.5 inline-block h-4 w-4" />
                <span className="text-sm">
                  This polygon has already been added
                </span>
              </div>
            )}
          </div>
        </div>
        <p className="mb-2 text-sm text-gray-500">
          The outline is used to determine which applications should be
          accepted. The centre point is used to focus the map shown on the
          landing page.
        </p>
        <div className="h-[32rem] w-full">
          <PolygonTypeEditor />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <MatchType />
      <CircleTypeEditor />
    </div>
  )
}

export default LocationEditor
