import React, { FC, useMemo } from 'react'
import { Tab } from '@headlessui/react'

import ApplicationsList from '../applications-list'
import { useApplicationsQueryParams } from '@features/funds/hooks/use-applications-query-params'
import {
  APPLICATIONS_TAB_LIST,
  APPLICATIONS_TABS_TO_SHOW_COUNT,
} from '@features/funds/constants'
import { Badge, Dropdown } from '@changex/design-system'
import { utils } from '@features/funds/utils'
import ApplicationsFilters from '../applications-filters'
import { ApplicationsCount } from '../applications-count'
import { FundType } from '@features/funds/types'

const normalizeLabel = (label: string) => {
  if (label === 'paidSeed') return 'paid_seed'
  if (label === 'paidImpact') return 'paid_impact'
  return label
}

type ApplicationTabsProps = {
  aggregates: Record<string, number>
  fundType: FundType | undefined
}

export const ApplicationsTabs: FC<ApplicationTabsProps> = ({
  aggregates,
  fundType,
}) => {
  const [applicationQueryParams, setApplicationQueryParams] =
    useApplicationsQueryParams()

  const selectedIndex = useMemo(
    () => applicationQueryParams.tab ?? 0,
    [applicationQueryParams]
  )

  return (
    <Tab.Group
      onChange={(index) => {
        setApplicationQueryParams({
          tab: index,
          page: 1,
        })
      }}
      selectedIndex={selectedIndex}
    >
      <>
        <div className="sticky top-12 z-10 bg-white shadow-sm">
          <div className="max-w-changex mx-auto">
            <Tab.List className="-mb-px flex space-x-8">
              {APPLICATIONS_TAB_LIST(fundType)
                .slice(0, APPLICATIONS_TABS_TO_SHOW_COUNT)
                .map((tab) => (
                  <Tab
                    key={tab.key}
                    className="ui-selected:border-gray-400 ui-selected:text-inherit ui-not-selected:border-transparent ui-not-selected:hover:text-gray-800 flex whitespace-nowrap border-b-2 border-transparent py-3 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 focus:border-transparent focus:ring-0"
                  >
                    {tab.label}
                    {aggregates[tab.key] !== undefined && (
                      <Badge
                        variant={utils.getBadgeStatus(normalizeLabel(tab.key))}
                        className="ml-1.5"
                      >
                        {aggregates[tab.key]}
                      </Badge>
                    )}
                  </Tab>
                ))}
              <div className="font-sm ml-2 pt-1 font-medium text-slate-500">
                <Dropdown
                  title="More"
                  variant="minimal"
                  className="shadow:none border-transparent bg-transparent focus:border-transparent focus:ring-0"
                  items={APPLICATIONS_TAB_LIST(fundType)
                    .slice(APPLICATIONS_TABS_TO_SHOW_COUNT)
                    .map((tab, index) => ({
                      type: 'tab',
                      id: tab.key,
                      onClick: () => {
                        setApplicationQueryParams({
                          tab: APPLICATIONS_TABS_TO_SHOW_COUNT + index,
                          page: 1,
                        })
                      },
                      title: (
                        <>
                          {tab.label}
                          {aggregates[tab.key] && (
                            <Badge
                              variant={utils.getBadgeStatus(
                                normalizeLabel(tab.key)
                              )}
                              className="ml-1.5"
                            >
                              {aggregates[tab.key]}
                            </Badge>
                          )}
                        </>
                      ),
                    }))}
                />
              </div>
            </Tab.List>
          </div>
        </div>
        <div className="max-w-changex mx-auto bg-white">
          <ApplicationsFilters />
        </div>
      </>
      <Tab.Panels>
        {APPLICATIONS_TAB_LIST(fundType).map((tab) => (
          <Tab.Panel key={tab.key}>
            <div className="max-w-changex mx-auto">
              <ApplicationsCount />
              <ApplicationsList />
            </div>
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}

export default ApplicationsTabs
