import { FundApplication } from '@features/funds/types'
import { ApplicationApprovalStageInbox } from '@features/funds/components/applications-list/application/approval/application-approval-stage-inbox'
import { ApplicationApprovalStageAllocated } from '@features/funds/components/applications-list/application/approval/application-approval-stage-allocated'
import {
  ApplicationApprovalStage,
  ApplicationApprovalStageProps,
} from '@features/funds/components/applications-list/application/approval/application-approval.types'
import { ApplicationApprovalStageSucceeded } from '@features/funds/components/applications-list/application/approval/application-approval-stage-succeeded'
import { ApplicationApprovalStageApproved } from '@features/funds/components/applications-list/application/approval/application-approval-stage-approved'
import { ApplicationApprovalStagePaidSeed } from '@features/funds/components/applications-list/application/approval/application-approval-stage-paid-seed'
import { ApplicationApprovalStageImpact } from '@features/funds/components/applications-list/application/approval/application-approval-stage-impact'
import { ApplicationApprovalStagePaidImpact } from '@features/funds/components/applications-list/application/approval/application-approval-stage-paid-impact'

type Props = {
  application: FundApplication
  stage: ApplicationApprovalStage
}

const ComponentsForStages: Record<
  ApplicationApprovalStage,
  React.FunctionComponent<ApplicationApprovalStageProps>
> = {
  inbox: ApplicationApprovalStageInbox,
  allocated: ApplicationApprovalStageAllocated,
  succeeded: ApplicationApprovalStageSucceeded,
  approved: ApplicationApprovalStageApproved,
  paidSeed: ApplicationApprovalStagePaidSeed,
  impact: ApplicationApprovalStageImpact,
  paidImpact: ApplicationApprovalStagePaidImpact,
  failed: () => null,
  failedImpact: () => null,
  rejected: () => null,
  refunded: () => null,
}

export function ApplicationApproval({ application, stage }: Props) {
  const Cmp = ComponentsForStages[stage]
  return (
    <div className="flex justify-between">
      <Cmp application={application} />
    </div>
  )
}
