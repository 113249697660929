import { useApplicationRejectMutation } from '@features/funds/hooks/use-application-reject-mutation'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { APPLICATION_APPROVAL_TOAST_DURATION } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { FundApplication } from '@features/funds/types'

export function useRejectApplication(application: FundApplication) {
  const rejectApplicationMutation = useApplicationRejectMutation()

  const mutate = useCallback(() => {
    rejectApplicationMutation.mutate(
      {
        application_id: application.id,
        fund_id: application.fund.id!,
      },
      {
        onSuccess: () => {
          toast.success('Application rejected', {
            duration: APPLICATION_APPROVAL_TOAST_DURATION,
          })
        },
        onError: () => {
          toast.error('There was an error rejecting this application', {
            duration: APPLICATION_APPROVAL_TOAST_DURATION,
          })
        },
      }
    )
  }, [rejectApplicationMutation, application])

  return {
    mutate,
    isLoading: rejectApplicationMutation.isLoading,
  }
}
