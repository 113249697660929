import { ButtonWithDropdown, Icon, WithTooltip } from '@changex/design-system'
import { InlineTextLoadingIndicator } from '@components'
import { CurrencyFormatter } from 'shared/currency/components/currency-formatter'
import { ComponentProps } from 'react'
import { UserFundRole } from '@features/funds/types'
import { useFund } from 'shared/providers'

type Props = {
  onClick?: () => void
  disabled?: boolean
  isLoading?: boolean
  isLocked?: boolean
  acceptActionText: string
  amount?: number
  items: ComponentProps<typeof ButtonWithDropdown>['items']
  tooltip?: string
}

export function ApplicationApprovalAcceptButtonWithDropdown({
  onClick,
  isLoading,
  disabled,
  amount,
  acceptActionText,
  isLocked,
  items,
  tooltip,
}: Props) {
  const ButtonComp = () => (
    <ButtonWithDropdown
      items={items}
      onClick={isLocked ? undefined : onClick}
      disabled={disabled || isLoading}
    >
      <InlineTextLoadingIndicator isLoading={Boolean(isLoading)}>
        <span className="inline-flex items-center gap-x-2">
          {acceptActionText}{' '}
          {amount || amount === 0 ? (
            <>
              (<CurrencyFormatter value={amount} />)
            </>
          ) : null}
          {isLocked && <Icon icon="LockClosed" variant="solid" size="xs" />}
        </span>
      </InlineTextLoadingIndicator>
    </ButtonWithDropdown>
  )

  if (useFund().userRole !== UserFundRole.Admin) return null

  if (tooltip) {
    return (
      <WithTooltip tooltip={tooltip} placement="top">
        <span>
          <ButtonComp />
        </span>
      </WithTooltip>
    )
  }
  return <ButtonComp />
}
