import { FinancialAnalytics } from '../../../shared/types'
import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'

export function useFinancialAnalyticsQuery(id: string, currency: string) {
  return useJsonApiQuery<FinancialAnalytics>([
    'analytics/funds/financials',
    {
      filter: {
        id,
        currency,
      },
      options: ['amount'],
    },
  ])
}
