import { useJsonApiMutation } from '../../../shared/hooks/json-api/use-json-api-mutation'
import { HeardAboutUsOption } from '@features/funds/types'

export function useApplicationUpdateMutation() {
  return useJsonApiMutation<{
    changexNotes?: string
    heardAboutUs?: HeardAboutUsOption
    heardAboutUsDetail?: string
    receivedStarterCallAt?: string
  }>('applications')
}
