import ReactDOM from 'react-dom'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { init as initFullStory } from '@fullstory/browser'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

initFullStory({ orgId: 'NDFB' })
;(async () => {
  // Since we don't target anon users with FFs in the admin apps, we use a shared anonymous user context
  // to avoid creating unnecessary contexts when users haven't logged in yet. We get billed per context.
  // We later identify the user to LaunchDarkly after the auth endpoint response.
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env['REACT_APP_LAUNCH_DARKLY_CLIENT_ID'] as string,
    context: {
      kind: 'user',
      key: 'anonymous-id',
      anonymous: true,
    },
  })
  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root')
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
