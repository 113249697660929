import { Feed, FeedContentPost } from '@features/funds/types'
import { User } from '@types'
import { useState } from 'react'
import toast from 'react-hot-toast'

import FeedListItemApplication from './feed-list-item-application'
import FeedListItemEvent from './feed-list-item-event'
import FeedListItemPost from './feed-list-item-post'
import FeedListItemUnknown from './feed-list-item-unknown'
import { useLikePostMutation } from '@features/funds/hooks/use-like-post-mutation'
import { useUnlikePostMutation } from '@features/funds/hooks/use-unlike-post-mutation'
import { useCreatePostCommentMutation } from '@features/funds/hooks/use-create-post-comment-mutation'
import { useDeletePostCommentMutation } from '@features/funds/hooks/use-delete-post-comment-mutation'
import { useDeletePostMutation } from '@features/funds/hooks/use-delete-post-mutation'

type TProps = {
  currentUser: User
  idea: { name: string; slug: string }
  item: Feed
  onRemove: (postId: string) => void
}

const ItemsFactory: Record<Feed['entryType'], any> = {
  application: FeedListItemApplication,
  event: FeedListItemEvent,
  location_post: FeedListItemPost,
  solution_post: FeedListItemPost,
}

export default function FeedListItem({
  currentUser,
  idea,
  item,
  onRemove,
}: TProps) {
  const [post, setPost] = useState<Feed | null>(item)

  const createPostCommentMutation = useCreatePostCommentMutation()
  const deletePostCommentMutation = useDeletePostCommentMutation()
  const deletePostMutation = useDeletePostMutation()
  const likePostMutation = useLikePostMutation()
  const unlikePostMutation = useUnlikePostMutation()

  const Item = ItemsFactory[item.entryType] || FeedListItemUnknown

  const handleCreateComment = async (content: string) => {
    try {
      if (post && post.content && 'comments' in post.content) {
        const userLastName = currentUser.fullName.split(' ').at(-1)

        const resp = await createPostCommentMutation.mutate({
          body: content,
          commentable_id: item.id,
          type: 'post',
        })

        setPost(
          (oldPost) =>
            ({
              ...oldPost,
              content: {
                ...oldPost?.content,
                comments: [
                  {
                    body: content,
                    createdAt: new Date().toISOString(),
                    id: resp.data.data.id.toString(),
                    userFirstName: currentUser.firstName,
                    userId: currentUser.id,
                    userLastName:
                      userLastName === currentUser.firstName
                        ? ''
                        : userLastName,
                  },
                  ...(oldPost?.content as FeedContentPost).comments,
                ],
              },
            } as any)
        )
      }
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  const handleLike = async () => {
    const myUserId = parseInt(currentUser.id)

    try {
      if (post && post.content && 'likes' in post.content) {
        if (post.content.likes.includes(myUserId)) {
          await unlikePostMutation.mutate({
            id: post.id,
            type: 'posts',
          })
          setPost(
            (oldPost) =>
              ({
                ...oldPost,
                content: {
                  ...oldPost?.content,
                  likes: (oldPost?.content as FeedContentPost).likes.filter(
                    (userId) => userId !== myUserId
                  ),
                },
              } as any)
          )
        } else {
          await likePostMutation.mutate({
            likeable_id: post.id,
            type: 'posts',
          })
          setPost(
            (oldPost) =>
              ({
                ...oldPost,
                content: {
                  ...oldPost?.content,
                  likes: [
                    ...(oldPost?.content as FeedContentPost).likes,
                    myUserId,
                  ],
                },
              } as any)
          )
        }
      }
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  const handleRemoveComment = async (commentId: string) => {
    try {
      await deletePostCommentMutation.mutate({
        id: commentId,
      })

      setPost(
        (oldPost) =>
          ({
            ...oldPost,
            content: {
              ...oldPost?.content,
              comments: (oldPost?.content as FeedContentPost).comments.filter(
                (comment) => comment.id !== commentId
              ),
            },
          } as any)
      )
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  const handleRemovePost = async () => {
    try {
      await deletePostMutation.mutate({ id: item.id })
      onRemove(item.id)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  return (
    <Item
      currentUser={currentUser}
      idea={idea}
      item={post}
      onComment={handleCreateComment}
      onDelete={handleRemovePost}
      onDeleteComment={handleRemoveComment}
      onLike={handleLike}
    />
  )
}
