import { useJsonApiMutation } from '../../../shared/hooks/json-api/use-json-api-mutation'

export function useSettingMutation() {
  return useJsonApiMutation<{
    key: string
    value: any
    settingableType: string
    settingableId: string
    settingType: string
  }>('settings')
}
