const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

/**
 * @returns env-aware URL string
 * @example apiBaseUrl('/applications')
 */
export const apiBaseUrl = (path: string) => {
  return `${process.env.REACT_APP_API_ADDRESS}${path}`
}

export const fundUrl = (slug: string) => {
  return `${CHANGEX_ADDRESS}/funds/${slug}`
}

export const ideaUrl = (slug: string) => {
  return `${CHANGEX_ADDRESS}/${slug}`
}

export const locationUrl = (ideaSlug: string, locationSlug: string) => {
  return `${CHANGEX_ADDRESS}/${ideaSlug}/${locationSlug}`
}

export const eventUrl = (
  ideaSlug: string,
  locationSlug: string,
  eventSlug: string
) => {
  return `${CHANGEX_ADDRESS}/${ideaSlug}/${locationSlug}/events/${eventSlug}`
}
