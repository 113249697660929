import { formatDateObject } from '@changex/design-system'
import {
  DateRangeFilters,
  FundApplication,
  FundApplicationOpenGrant,
  FundApplicationReplication,
  LocationPointsForCluster,
  TStep,
  SolutionPaymentData,
} from '@features/funds/types'
import { Solution } from '@types'
import { ClusterCircleMarkerSize } from './components/map-markers/map-markers'
import { fundUrl, ideaUrl, locationUrl, eventUrl } from 'shared/utils/routing'

// TODO: move functions out of object and into files in "shared/utils" directory
// They should be individual functions exported separately. Keeping them in an object has no benefit
// and means they can't be referenced within other util functions in the object.
const utils = {
  getDaysLeftStatus: (daysRemaining: number) => {
    if (daysRemaining === 0) {
      return 'light'
    }
    if (daysRemaining <= 7) {
      return 'error'
    }
    if (daysRemaining <= 14) {
      return 'warning'
    }
    return 'success-dark'
  },
  getBadgeStatus: (status: string) => {
    const errorStatus: string[] = ['rejected', 'failed']
    const infoStatus: string[] = ['allocated', 'pre_allocated']
    const successStatus: string[] = ['inbox', 'approved', 'succeeded']
    const inboxStatus: string[] = ['openGrantInbox', 'replicationInbox']

    if (errorStatus.includes(status)) {
      return 'error'
    }

    if (infoStatus.includes(status)) {
      return 'info'
    }

    if (successStatus.includes(status)) {
      return 'success'
    }

    if (inboxStatus.includes(status)) {
      return 'basic'
    }

    return status
  },
  createLinkToFund: (data) => {
    return data?.fund && fundUrl(data.fund.slug)
  },
  createLinkToIdea: (data) => {
    return data?.solution && ideaUrl(data.solution.slug)
  },
  createLinkToPage: (data) => {
    if (!data.solution) {
      return data?.location && locationUrl('project', data.location.slug)
    }
    return locationUrl(data.solution.slug, data.location.slug)
  },
  createLinkToEvent: (data) => {
    if (!data.solution) {
      return (
        data?.location &&
        data?.event &&
        eventUrl('project', data.location.slug, data.event.slug)
      )
    }
    return eventUrl(data.solution.slug, data.location.slug, data.event.slug)
  },
  getStatus: (label: string) => {
    switch (label) {
      case 'pre allocated':
        return 'pre_allocated'
      case 'allocated':
        return 'allocated'
      case 'succeeded':
        return 'succeeded'
      case 'approved':
        return 'approved'
      case 'paid seed':
        return 'paid_seed'
      case 'impact':
        return 'impact'
      case 'paid impact':
        return 'paid_impact'
      case 'failed':
        return 'failed'
      case 'failed impact':
        return 'failed_impact'
      case 'refunded':
        return 'refunded'
      case 'rejected':
        return 'rejected'
      case 'not funded':
        return 'unfunded'
      default:
        return label || '_'
    }
  },
  getStatusLabel: (status: string) => {
    switch (status) {
      case 'pre_allocated':
        return 'Preallocated'
      case 'allocated':
        return 'Allocated'
      case 'succeeded':
        return 'Succeeded'
      case 'approved':
        return 'Approved'
      case 'paid_seed':
        return 'Paid seed'
      case 'impact':
        return 'Impact'
      case 'paid_impact':
        return 'Paid impact'
      case 'failed':
        return 'Failed'
      case 'failed_impact':
        return 'Failed impact'
      case 'refunded':
        return 'Refunded'
      case 'rejected':
        return 'Rejected'
      case 'unfunded':
        return 'Not funded'
      default:
        return status || '--'
    }
  },

  isChallengeStepsCompleted: (steps: TStep[]) =>
    steps?.filter((step) => step.level === 'challenge' && !step.completed)
      .length === 0,

  normalizeFiltersToJsonApi: (filters, stateFilters) => {
    const { q, status, step, sort, fund, funded, in_challenge } = filters

    const resultObj = { ...stateFilters }
    if (q) {
      resultObj['filter']['keywords[eq]'] = q
    } else {
      if (resultObj['filter'].hasOwnProperty('keywords[eq]')) {
        delete resultObj['filter']['keywords[eq]']
      }
    }
    if (status) {
      resultObj['filter']['status[eq]'] = status
    } else {
      if (resultObj['filter'].hasOwnProperty('status[eq]')) {
        delete resultObj['filter']['status[eq]']
      }
    }
    if (step) {
      resultObj['filter']['incomplete_challenge_onboarding_step'] = step
    } else {
      if (
        resultObj['filter'].hasOwnProperty(
          'incomplete_challenge_onboarding_step'
        )
      ) {
        delete resultObj['filter']['incomplete_challenge_onboarding_step']
      }
    }
    if (fund && fund !== 'fund') {
      resultObj['filter']['fund_id'] = fund
    } else {
      if (resultObj['filter'].hasOwnProperty('fund_id')) {
        delete resultObj['filter']['fund_id']
      }
    }
    if (funded) {
      resultObj['filter']['funded'] = funded
    } else {
      if (resultObj['filter'].hasOwnProperty('funded')) {
        delete resultObj['filter']['funded']
      }
    }
    if (in_challenge) {
      resultObj['filter']['in_challenge'] = in_challenge
    } else {
      if (resultObj['filter'].hasOwnProperty('in_challenge')) {
        delete resultObj['filter']['in_challenge']
      }
    }
    if (sort) {
      resultObj['sort'] = sort
    }
    return resultObj
  },
  // Note: at some point it would be nice to not need these by relying on the type returned in the payload
  // Currently `jsonapi-react` strips this out however
  // Instead we rely on the fact that a replication application will always have a solution field
  isReplicationApplication: (
    application: FundApplication
  ): application is FundApplicationReplication => Boolean(application.solution),
  isOpenGrantApplication: (
    application: FundApplication
  ): application is FundApplicationOpenGrant => !application.solution,

  getSolutionListByFiltering: (solutions: Solution[]) => {
    return solutions.map((item) => ({
      ...item,
      name: item.name === 'Project' ? 'Open grant project' : item.name,
    }))
  },

  getDateRangeFilters: (dateRangeSelected: string): DateRangeFilters => {
    let dateRangeFilters = {} as DateRangeFilters
    const formattedTodaysDate = formatDateObject(new Date())
    dateRangeFilters.end_date = formattedTodaysDate
    switch (dateRangeSelected) {
      case 'today':
        dateRangeFilters.start_date = formattedTodaysDate
        break
      case 'past_seven_days':
        dateRangeFilters.start_date = formatDateObject(
          new Date(new Date().setDate(new Date().getDate() - 7))
        )
        break
      case 'past_thirty_days':
        dateRangeFilters.start_date = formatDateObject(
          new Date(new Date().setDate(new Date().getDate() - 30))
        )
        break
      case 'past_ninety_days':
        dateRangeFilters.start_date = formatDateObject(
          new Date(new Date().setDate(new Date().getDate() - 90))
        )
        break
      case 'past_twelve_months':
        dateRangeFilters.start_date = formatDateObject(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        )
        break
    }
    return dateRangeFilters
  },

  getClusterMarkerSize: (pointCount: number) => {
    let size = '' as ClusterCircleMarkerSize
    if (pointCount >= 2 && pointCount <= 10) {
      size = 'small'
    } else if (pointCount > 10 && pointCount <= 50) {
      size = 'medium'
    } else if (pointCount > 50) {
      size = 'large'
    }
    return size
  },

  arrangeClosePointsInCircle(points: LocationPointsForCluster[]) {
    const RADIUS = 0.0001

    const getKey = (coords: { longitude: number; latitude: number }) =>
      `${coords.latitude}:${coords.longitude}`

    let lookupMap = {}

    // Find out how many points are in the same location.
    points?.forEach((item) => {
      let key = getKey({
        longitude: item.geometry.coordinates[0],
        latitude: item.geometry.coordinates[1],
      })
      if (lookupMap.hasOwnProperty(key)) {
        lookupMap[key]++
      } else {
        lookupMap[key] = 1
      }
    })

    // Now put each point which is at the same location around a circle.
    points?.forEach((item) => {
      const key = getKey({
        longitude: item.geometry.coordinates[0],
        latitude: item.geometry.coordinates[1],
      })

      if (lookupMap[key] > 1) {
        let count = 0
        points.forEach((item2, index2) => {
          const key2 = getKey({
            longitude: item2.geometry.coordinates[0],
            latitude: item2.geometry.coordinates[1],
          })

          if (key2 === key) {
            const angle = (count++ / (lookupMap[key] / 2)) * Math.PI
            const newlat =
              item2.geometry.coordinates[1] + RADIUS * Math.cos(angle)
            const newlng =
              item2.geometry.coordinates[0] + RADIUS * Math.sin(angle)
            points[index2].geometry.coordinates = [newlng, newlat]
          }
        })
      }
    })

    return points
  },

  removeDuplicateCoordinatePoints(points: LocationPointsForCluster[]) {
    return points?.reduce((accumulator: any, current: any) => {
      if (
        !accumulator.find((point) => {
          return (
            point.geometry.coordinates[0] === current.geometry.coordinates[0] &&
            point.geometry.coordinates[1] === current.geometry.coordinates[1]
          )
        })
      ) {
        accumulator.push(current)
      }
      return accumulator
    }, [])
  },

  // Converts number to scale of 0-1
  convertToUnityScale(num: number, max: number, min: number) {
    return (num - min) / (max - min) || 0
  },

  truncateAddress(address: string): string {
    const items = address.split(',').map((s) => s.trim())
    return items.slice(-2).join(', ')
  },

  addSizeToFirestackUrl(
    photoUrl: string,
    width: number | string = 800,
    height: number | string = 800
  ) {
    const urlComponents = photoUrl.split('/')
    const handle = urlComponents[urlComponents.length - 1]
    return `${urlComponents
      .slice(0, -1)
      .join('/')}/resize=w:${width},h:${height},fit:crop/${handle}`
  },

  titleize(str: string) {
    if (typeof str !== 'string') return ''

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  },

  sumValues<T extends object>(obj: T): number {
    return Object.values(obj).reduce((acc, val) => {
      if (typeof val === 'object') {
        return acc + utils.sumValues(val)
      } else if (typeof val === 'number') {
        return acc + val
      } else {
        return acc
      }
    }, 0)
  },

  calculatePercentage: (total: number, partial: number) =>
    (100 * partial) / total,

  solutionAllocationPaid(paymentData?: SolutionPaymentData): boolean {
    if (paymentData && paymentData?.budgets?.paid?.balance > 0) {
      return true
    }

    return false
  },

  firstBudgetAmount(budgets?): number {
    if (budgets) {
      const firstAmount = Object.keys(budgets).find(
        (key) => budgets[key].balance > 0
      )

      return firstAmount ? budgets[firstAmount].balance : 0
    }

    return 0
  },
}

export { utils }
