import { PropsWithChildren, ReactNode } from 'react'
import { Text } from '@changex/design-system'

function Label({ children }: PropsWithChildren<{}>) {
  return (
    <Text
      className="mr-3 text-xs font-normal uppercase"
      size="xs"
      letterSpacing="widest"
      upper
      variant="light"
      role="term"
    >
      {children}
    </Text>
  )
}

type DefinitionProps = {
  term: string
  definition: ReactNode
  testId?: string
  link?: string
}

function Definition({ term, definition, testId, link }: DefinitionProps) {
  return (
    <div className="flex" data-testid={testId}>
      {/* TODO remove hardcoded width */}
      <div className="w-[90px] flex-shrink-0 leading-5">
        <Label>{term}</Label>
      </div>
      <Text size="sm" role="definition">
        {link ? (
          <a
            href={link}
            rel="noreferrer"
            target="_blank"
            className="hover:underline"
          >
            {definition}
          </a>
        ) : (
          definition
        )}
      </Text>
    </div>
  )
}

type DefinitionListProps = {
  definitions: DefinitionProps[]
  testId?: string
}

export function ApplicationInfoDefinitionList({
  definitions,
}: DefinitionListProps) {
  return (
    <div className="flex flex-1 flex-col gap-3">
      {definitions.map((definition) => (
        <Definition
          key={definition.term}
          term={definition.term}
          definition={definition.definition}
          link={definition.link}
          testId={definition.testId}
        />
      ))}
    </div>
  )
}
