import { Fragment, useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from '@changex/design-system'
import { SearchIcon } from '@heroicons/react/solid'
import {
  SolutionAttributes,
  SolutionDefaultAllocations,
} from 'shared/types/fund-editor.type'
import { useJsonApiQuery } from 'shared/hooks/json-api/use-json-api-query'

type SearchResultItemProps = {
  solution: SolutionAttributes
  onClick: () => void
}

type SelectionModalProps = {
  isOpen: boolean
  onClose: () => void
  onSelect: (
    id: string,
    solutionDefaultAllocations?: SolutionDefaultAllocations
  ) => void
}

type SolutionSelectorProps = {
  onChange: (
    id: string,
    solutionDefaultAllocations?: SolutionDefaultAllocations
  ) => void
}

const useSolutionsQuery = () => {
  return useJsonApiQuery<SolutionAttributes[]>([
    'solutions',
    {
      page: { size: 300 },
      extra_fields: { solutions: 'default_allocations' },
    },
  ])
}

const SkeletonItem = () => {
  return (
    <div className="flex animate-pulse items-center gap-4 p-2">
      <div className="h-full w-1/6">
        <div className="h-14 w-14 rounded bg-gray-300" />
      </div>
      <div className="w-full py-2">
        <div className="my-4 h-2 w-1/2 rounded bg-gray-300"></div>
        <div className="my-4 h-2 w-3/4 rounded bg-gray-300"></div>
      </div>
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </>
  )
}

const SearchResultItem = ({ solution, onClick }: SearchResultItemProps) => {
  return (
    <div onClick={onClick} className="flex items-center gap-4 p-2">
      <div className="w-1/6">
        <img src={solution.logoUrl} className="w-full" alt="" />
      </div>
      <div className="w-5/6">
        <p>{solution.name}</p>
        <p className="text-sm text-gray-500">{solution.shortDescription}</p>
      </div>
    </div>
  )
}

const SelectionModal = ({ isOpen, onClose, onSelect }: SelectionModalProps) => {
  const { isLoading, data, error } = useSolutionsQuery()
  const [filterText, setFilterText] = useState('')

  const handleClose = useCallback(() => {
    setFilterText('')
    onClose()
  }, [onClose])

  if (error || (!isLoading && !data)) {
    return null
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="fixed inset-0 z-50 flex items-start justify-center pt-16">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-60 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-md transition-all">
              <div className="relative flex items-center pr-4 shadow-sm">
                <input
                  defaultValue={filterText}
                  onChange={(event) => setFilterText(event.target.value)}
                  placeholder="Search for an idea..."
                  className="-mr-9 flex-auto appearance-none bg-transparent py-4 pl-4 pr-12 text-base text-gray-600 placeholder-gray-500 focus:outline-none"
                  data-testid="solution-search-input"
                />
                <SearchIcon className="pointer-events-none h-5 w-5 flex-none text-gray-400" />
              </div>
              <div className="max-h-[18.375rem] divide-y divide-gray-200 overflow-y-auto rounded-b-lg border-t border-gray-200 text-sm">
                {isLoading ? (
                  <LoadingSkeleton />
                ) : (
                  data?.results
                    .filter((solution) =>
                      solution.name
                        ?.toLowerCase()
                        ?.includes(filterText.toLowerCase())
                    )
                    .map((solution) => (
                      <SearchResultItem
                        key={`${solution.id}-${solution.slug}`}
                        onClick={() => {
                          onSelect(
                            `${solution.id}-${solution.slug}`,
                            solution.defaultAllocations
                          )
                          handleClose()
                        }}
                        solution={solution}
                      />
                    ))
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

const SolutionSelector = ({ onChange }: SolutionSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <Button
        weight="tertiary"
        icon="plus"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        Add idea
      </Button>
      <SelectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSelect={onChange}
      />
    </div>
  )
}

export default SolutionSelector
