import { Post } from '@changex/design-system'
import { FeedContentPost } from '@features/funds/types'
import { useUserQuery } from '@features/funds/hooks/use-user-query'
import { utils } from '@features/funds/utils'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useState } from 'react'
import { addFilestackTasks } from 'shared/utils/add-filestack-tasks'

import { TFeedListItemProps } from './types'
import { httpClient } from '@api'

dayjs.extend(RelativeTime)

type TProps = TFeedListItemProps<FeedContentPost>

const IMAGE_TASKS = ['auto_image']

export default function FeedListItemPost({
  currentUser,
  idea,
  item,
  onComment,
  onDelete,
  onDeleteComment,
  onLike,
}: TProps) {
  const userQuery = useUserQuery(item.content.userId)
  const [userPictures, setUserPictures] = useState<Record<string, string>>({})
  const badge = utils.getBadgeStatus(utils.getStatus(item.state.toLowerCase()))
  const isOpenProject = String(item.solutionId) === '99'

  useEffect(() => {
    item.content.comments
      .filter((comment) => Object.values(comment).filter(Boolean).length)
      .forEach((comment) => {
        if (comment.userId) {
          httpClient.get(`/users/${comment.userId}`).then((resp) => {
            if (resp.data) {
              setUserPictures((oldValue) => ({
                ...oldValue,
                [comment.userId]: resp.data.data.attributes.avatarUrl,
              }))
            }
          })
        }
      })
  }, [item.content.comments])

  return (
    <Post
      comments={item.content.comments
        .filter((comment) => Object.values(comment).filter(Boolean).length)
        .map((comment) => ({
          message: comment.body,
          name: `${comment.userFirstName} ${comment.userLastName}`,
          onDelete: () => onDeleteComment(comment.id),
          picture: userPictures[comment.userId] || '',
          relativeFrom: dayjs(comment.createdAt).fromNow(),
        }))}
      post={{
        attachments: item.content.assets
          .filter((asset) => !asset.type.startsWith('image'))
          .map((asset) => ({ link: asset.url, name: asset.title })),
        images: item.content.assets
          .filter((asset) => asset.type.startsWith('image'))
          .map((asset) => addFilestackTasks(asset.url, IMAGE_TASKS)),
        content: item.content.body,
        country: item.content.countryName,
        date: dayjs(item.createdAt).format('MMM DD[,] YYYY [at] h:mm A'),
        event: 'posted on',
        liked: item.content.likes.includes(parseInt(currentUser.id)),
        likes: item.content.likes.length,
        location: {
          link: utils.createLinkToPage({
            location: { slug: item.content.locationSlug },
            solution: { slug: idea.slug },
          }),
          name: item.content.locationName || 'n/a',
        },
        onComment,
        onDelete,
        onLike,
        status: {
          name: item.state || 'n/a',
          variant: badge,
        },
        tag: {
          prefix: isOpenProject ? 'Open grant project' : 'Idea',
          link: isOpenProject
            ? undefined
            : utils.createLinkToIdea({ solution: { slug: idea.slug } }),
          name: isOpenProject ? undefined : idea.name,
        },
        user: {
          name: `${item.content.userFirstName} ${item.content.userLastName}`,
          picture: userQuery.data?.results.avatarUrl || '',
        },
      }}
      type="content"
    />
  )
}
